import React, {useEffect} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider, useLayout} from './core'
import {
  DrawerMessenger,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
import {TenantRegistration} from '../../_custom/layout/notifications/TenantRegistration'
import {useDispatch} from 'react-redux'
import {fetchNotificationsForList, notificationsListActions, useNotificationsForList} from '../../api/notifications'
import {useAuthState} from '../../api/auth'
import {RELATION_EQ, STRING_CONTAINS} from '../../_custom/partials/components/Metadata'




const MasterLayout: React.FC = ({children}) => {
  const {config/*, classes*/} = useLayout()
  // const {aside} = config
  const dispatch = useDispatch();
  const {user} = useAuthState();
  const {metadata} = useNotificationsForList();
  const {pagination, filter} = metadata;
  const {setFilter} = notificationsListActions

  useEffect(()=>{
    dispatch(fetchNotificationsForList(metadata));
  }, [pagination.perPage, filter.filters])

  useEffect(()=>{
    if (user) {
      dispatch(
        setFilter({
          ...metadata.filter,
          filters: [
            {
              property: 'user',
              operator: RELATION_EQ,
              value: user.id
            }
          ]
        })
      )
    }
  }, [user])

  return (
    <PageDataProvider>
      <TenantRegistration />

      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid'>
          {
            config.aside.display &&
            <AsideDefault />
          }

          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>{children}</Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/*<ExploreMain />*/}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
