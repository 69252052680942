import React, {useCallback, useMemo, useState} from 'react'
import {Editable, withReact, useSlate, Slate, ReactEditor} from 'slate-react'
import {Editor, Transforms, createEditor, Node, Text, BaseEditor, Descendant} from 'slate'
import {HistoryEditor, withHistory} from 'slate-history'
// import escapeHtml from 'escape-html'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5';
import {Element, Leaf} from './SlateEditor';
import {useIntl} from 'react-intl';
import {Icon} from '../../components/Icon'
import {TFormikControl} from '../FormikBootstrapInput'
import {AEButton} from '../../components/buttons'
import clsx from 'clsx'

type CustomElement = { type: 'paragraph'; children: CustomText[] }
type CustomText = { text: string; bold?: true }
type TEditor = BaseEditor & ReactEditor & HistoryEditor

declare module 'slate' {
  interface CustomTypes {
    Editor: TEditor
    Element: CustomElement
    Text: CustomText
  }
}

/*********/

const HOTKEYS:Record<string, string> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

type TNode = Node & {type: string}
type TFunc = (editor:TEditor, format:any) => boolean | void


const toggleBlock:TFunc = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n:any) => LIST_TYPES.includes(n.type),
    split: true,
  })


  Transforms.setNodes(editor, {
    // @ts-ignore
    type: isActive ? 'paragraph' : (isList ? 'list-item' : format),
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark:TFunc = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}


const isBlockActive:TFunc = (editor, format) => {
  const [match]:any = Editor.nodes(editor, {
    match: (n:any) => n.type === format,
  })

  return !!match
}

const isMarkActive:TFunc = (editor:any, format:any) => {
  const marks:any = Editor.marks(editor)
  return marks ? marks[format] === true : false
}


const Button:React.FC<any> = ({ format, icon, block, useFontAwesome = false, title }) => {
  const editor:any = useSlate();
  let active:any = block ? isBlockActive(editor, format) : isMarkActive(editor, format);
  let IconComponent = (
    <Icon
      path={icon}
      variant={active ? 'primary' :''}
      size={'1'}
    />
  )
  if(useFontAwesome){
    IconComponent = <li className={` ${icon} ${active ? 'text-primary' :'text-muted'}`} />
  }

  return (
    <OverlayTrigger
      overlay={<Tooltip id='tooltip'>{title}</Tooltip>}
    >
      <AEButton
        icon
        variant='outline'
        size='sm'
        className={
          clsx(
            'mx-1 outline btn-outline-dashed',
            active && 'btn-outline-primary btn-active-light-primary'
          )
        }
        onMouseDown={(event:MouseEvent) => {
          event.preventDefault()
          block ? toggleBlock(editor, format) : toggleMark(editor, format)
        }}
      >
        {IconComponent}
      </AEButton>

      {/*<ButtonRef*/}
      {/*  active={active}*/}

      {/*  className={'pr-5'}*/}
      {/*>*/}
      {/*  {IconComponent}*/}
      {/*</ButtonRef>*/}
    </OverlayTrigger>
  )
}
type TButtonRef = {
  className?: string
  active?: boolean
  reversed?: boolean
}

export const ButtonRef = React.forwardRef((
  { className, active, reversed, ...props }: TButtonRef,
  ref:any
) => {
  return (
    <div
      {...props}
      ref={ref}
      className={className}
    />
  )
})

// export const toHtml = (nodes: Array<Node>) => {
//   return nodes.map((node:any)=>{
//     if (Text.isText(node)) {
//       return escapeHtml(node.text)
//     }
//     const children = node.children.map((n:any) => Node.string(n)).join('')
//     //todo
//     // return Element({children, element:node})
//     switch (node.type) {
//       case 'quote':
//         return `<blockquote><p>${children}</p></blockquote>`
//       case 'paragraph':
//         return `<p>${children}</p>`
//       case 'link':
//         return `<a href='${escapeHtml(node.url)}'>${children}</a>`
//       default:
//         return children
//     }
//   }).join('')
//
// }

const toPlaintext = (nodes:Array<TNode>) => {
  return nodes.map(n => Node.string(n)).join('\n')
}

export const isEmpty = (nodes:Array<TNode>) => toPlaintext(nodes).length === 0

export const emptyParagraph = {
  type: 'paragraph',
  children: [
    {
      text: ''
    }
  ]
}
export const defaultSlateValue = [
  emptyParagraph
]


/* TODO
*  Validation
* */
export type TFormikSlateEditor = React.FC<TFormikControl & {
  label?: string
  validation?: boolean
}>
export const FormikSlateEditor:TFormikSlateEditor = ({field, form, label, validation = true}) => {
  const {name, value} = field
  const {touched, errors, setFieldTouched, setFieldValue} = form
  const {formatMessage} = useIntl()
  label = formatMessage({id:label})

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const nodes = value || defaultSlateValue;
  const editor = useMemo(() => withHistory(withReact<any>(createEditor())), [])

  // useEffect(()=>{
  //   if(isEmpty(nodes)) setFieldError(name, 'Required')
  // }, [nodes])

  // console.log(errors[name], touched[name])
  return (
    <>
      <Slate
        editor={editor}
        value={nodes}
        onChange={value => {
          // console.log(errors[name])
          if(!touched[name]) {
            setFieldTouched(name, true);
          }
          // setFieldError(name,'aaa')
          setFieldValue(name, value)
        }}
      >
        <div className='card'>
          <div className='card-header'>
            <div className='card-toolbar'>
              <Button format='bold' icon='/Text/Bold.svg' title={'Bold'}/>
              <Button format='italic' icon='/Text/Itallic.svg'  title={'Italic'}/>
              <Button format='underline' icon='/Text/Underline.svg'  title={'Underline'}/>
              <Button format='code' icon='/Text/Code.svg' title={'Code'}/>
              <Button format='mark' icon='/Text/Edit-text.svg' title={'Mark'}/>
              <Button format='del' icon='/Text/Strikethrough.svg' title={'Del'}/>
              <Button format='small' icon='/Design/Anchor-left-down.svg' title={'Small'}/>
              <Button format='ae-separator' icon='/Navigation/Minus.svg' block title={'Separator'}/>
              <Button format='h1' icon='/Text/H1.svg' block title={'Header 1'}/>
              <Button format='h2' icon='/Text/H2.svg' block title={'Header 2'}/>
              <Button format='ae-blockquote' icon='/Text/Quote2.svg' block title={'Quote'}/>
              <Button format='numbered-list' icon='fas fa-list-ol' block useFontAwesome title={'Numbered List'}/>
              <Button format='bulleted-list' icon='fas fa-list-ul' block useFontAwesome title={'Bulleted List'}/>

            </div>
          </div>
          <div className='card-body'>
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              autoFocus
            />
          </div>
        </div>
      </Slate>
    </>
  );
}


export const SlatePreview = ({value}:any) => {
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact<any>(createEditor())), [])

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={value => {
        // setValue(value)
      }}>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
      />
    </Slate>
  )
}
