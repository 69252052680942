import {getSlice} from "../../helpers";
import * as API from "../api";
import {ROUTES, TAppRoute} from '../../../app/routing/Routes'
import {MODULES} from '../../../_custom/helpers/RoutingHelpers'
import {shallowEqual, useSelector} from 'react-redux'
import {TMetadata} from '../../../_custom/partials/components/Metadata'
import {TRoute} from '../../../app/types/TRoute'
import {Dispatch} from 'redux'

const {actions, name, reducer} = getSlice({
  name: `${MODULES.ROUTES}.FOR.APP`,
  data: []
})

const {startCall, endCall, fetched, catchError, reset} = actions;


const fetchDispatcher = () => (dispatch: Dispatch) => {
  dispatch(startCall());

  return API
    .getAuthUserRoutes()
    .then(response => {
      const entities:Array<TRoute> = response.data.entities
      response.data.entities = entities
        .filter((dbRoute)=>ROUTES.findIndex(appRoute=>appRoute.routeKey === dbRoute.routeKey) !== -1)
        .map((dbRoute)=>{
          const appRoute = ROUTES.find(appRoute=>appRoute.routeKey === dbRoute.routeKey);

          return {
            ...dbRoute,
            ...appRoute,
            path: appRoute? appRoute.path.toLowerCase() : '/'
          }
        })

      // console.log(response.data.entities)
      dispatch(fetched(response));

      return response;
    })
    .catch(response => {
      dispatch(catchError(response));

      return response;
    }).then(response=>{ dispatch(endCall(response)) })
};

type TState = {
  data: Array<TRoute & TAppRoute>
  initialData: any
  error: any
  isLoading: boolean
  metadata: TMetadata
}

const _useSelector = (): TState => {
  return useSelector((selector:any)=>selector[name], shallowEqual)
}

const _reducer = {
  [name]: reducer
}

export {
  _reducer,
  fetchDispatcher,
  _useSelector
}


