import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {ICONS} from '../../../../_custom/partials/components/Icon'
import {Avatar} from '../../../../_custom/partials/components/Avatar'
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import {Moment} from '../../../../_custom/partials/components/Moment'
import {getFullName} from '../../../../_custom/partials/components/FullName'
import {DetailLine} from '../../../../_custom/partials/components/DetailLine'
import clsx from 'clsx'
import {TCustomer} from '../../../types/TCustomer'
import {APP_LOGO_WITHOUT_TEXT, toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Status} from './Status'

type Props = {
  entity: TCustomer
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:Props) => {

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'CUSTOMERS.DELETE', params: {id: entity.id}})
    }
    if (editAction) {
      actions.push({id: 'CUSTOMERS.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, editAction, deleteAction])

  return (
    <div className={clsx(
      'card border border-2 border-gray-300 border-hover',
      className
    )}>
      <div className='card-header flex-nowrap border-0'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light me-3'>
            <img
              src={
                entity.fileName ?
                  toAbsoluteUploadUrl(`/customers/${entity.fileName}`) :
                  toAbsoluteUrl(`/media/avatars/blank.png`)
              }
              alt={` `}
            />
          </div>

          <div>
            <div className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
              {getFullName(entity)}
            </div>
            <div>
              <Status statusKey={entity.status} />
            </div>
          </div>
        </div>

        <div className='card-toolbar m-0'>
          <AERouteActions actions={actions} />
        </div>
      </div>

      <div className='card-body d-flex flex-column pt-0'>
        <div>
          {
            entity.email &&
            <DetailLine
              title='EMAIL'
              icon={ICONS.EMAIL}
            >
              {entity.email}
            </DetailLine>
          }

          {
            entity.birthDate &&
            <DetailLine
              title='BIRTH_DATE'
              icon={ICONS.CALENDAR}
            >
              <Moment date={entity.birthDate} format='LL' />
            </DetailLine>
          }

          <DetailLine
            title='CREATION_DATE'
            icon={ICONS.CALENDAR}
          >
            <Moment date={entity.createdAt} format='LL' />
          </DetailLine>
        </div>
      </div>
    </div>
  )
}