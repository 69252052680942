import React, {forwardRef, InputHTMLAttributes, LegacyRef} from 'react'
import {FieldFeedbackLabel} from "./label/FieldFeedbackLabel";
import {useIntl} from "react-intl";
import get from 'lodash/get';
import {FieldFeedbackIcon} from "./label/FieldFeedbackIcon";
import clsx from "clsx";
import moment from 'moment'
import {DateTimeFormat} from '../../helpers/AssetHelpers'


const Input = ({type, ...props}:any) => {
  switch (type) {
    case 'textarea':
      return <textarea {...props} />
    default:
      return <input type={type} {...props}/>
  }
}


export type TBootstrapInput = {
  label?: string
  validation?: boolean
  className?: string
  placeholder?: string
  placeholderLabel?: boolean
  size?: 'sm' | 'md' | 'lg'
  variant?: 'flush' | 'solid' | 'white'
  feedbackError?: boolean
  feedbackTouched?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'label'>

export const BootstrapInput = forwardRef((
  {
    label, validation = true, className = '', placeholder, placeholderLabel,
    size = 'md', variant = 'solid', feedbackError, feedbackTouched, type, ...props
  }: TBootstrapInput,
  ref:LegacyRef<HTMLDivElement>
) => {
  const {formatMessage} = useIntl()
  label = label ? formatMessage({id:label}) : ''

  return (
    <div className="" ref={ref}>
      {
        label && !placeholderLabel &&
        <label className="form-label fs-6 fw-bolder text-dark d-flex">
          <span className={'pe-1'}>{label}</span>
          {validation && (
            <FieldFeedbackIcon
              error={feedbackError}
              touched={feedbackTouched}
              tooltipContent={(
                <FieldFeedbackLabel
                  error={feedbackError}
                  touched={feedbackTouched}
                  label={label}
                  type={type}
                />
              )}
            />
          )}
        </label>
      }

      <div className={'input-group'}>
        <Input
          type={type}
          className={
            clsx(
              'form-control form-control-solid',
              `form-control-${size}`,
              className,
            )
          }
          placeholder={placeholderLabel ? label : placeholder}
          {...props}
        />
        {/*{*/}
        {/*  type === 'password' &&*/}
        {/*    <div className='input-group-append'>*/}
        {/*      <PasswordToggler  />*/}
        {/*    </div>*/}
        {/*}*/}

      </div>
    </div>
  )
})

export type TFormikControl = {
  field: any
  form: any
}

export type TFormikBootstrapInput = TFormikControl & TBootstrapInput & {}

export const FormikBootstrapInput = ({field, form, ...props}:TFormikBootstrapInput) => {
  const { name, value } = field;
  const { touched, errors, setFieldValue, setFieldTouched} = form;
  const {type} = props;
  let _value = ''

  switch (type) {
    case 'datetime-local':
      const datetime = moment(value);
      _value = (value && datetime.isValid()) ? datetime.format('YYYY-MM-DDTHH:mm') : ''
      // console.log(name, value, datetime.isValid())
      break;
    default:
      _value = value
  }


  return (
    <BootstrapInput
      value={_value}
      onBlur={() => {setFieldTouched(name, true)}}
      onChange={(e) => {
        let v:any = e.target.value;

        switch (type) {
          case 'number':
            v = v ? parseFloat(v) : ''
            break;
          // case 'date':
          case 'datetime-local':
            const datetime = moment(v)
            v = v && datetime.isValid() ? moment(v).format(DateTimeFormat) : ''
          // case 'time':
          //   v = v ? v : null
        }

        setFieldValue(name, v)
      }}
      feedbackError={get(errors, name)}
      feedbackTouched={get(touched, name)}
      {...props}
    />
  )
}
