import React, {LegacyRef} from 'react'
import {FormikProps} from 'formik/dist/types'
import {FileField, NameField, PriceField, ProductCategoryField} from '../../../../controls'

type TProps = {
  formik: FormikProps<any>
  btnRef: LegacyRef<HTMLDivElement>
}

export const Form = ({formik, btnRef}:TProps) => {
  const {handleSubmit} = formik

  return (
    <div className='form form-label-right'>
      <div className='card mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='mb-3 col-md-6'>
              <FileField
                label='ICON'
                preview
                uploadPath='/products'
              />
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md'>
              <NameField />
            </div>
            <div className='mb-3 col-md'>
              <ProductCategoryField />
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md'>
              <PriceField />
            </div>
          </div>
        </div>
      </div>

      <div
        className='d-none'
        ref={btnRef}
        onClick={()=>{
          handleSubmit()
        }}
      />
    </div>
  );
}

