import React from 'react'
import {Search} from './Search'
import {Sort} from './Sort'
import {Filter} from './Filter'
import {PageToolbar} from '../../../../../../_metronic/layout/core'
import {AERouteAction} from '../../../../../../_custom/partials/components/AERouteActions'
import {TProperty} from '../../../../../../_custom/partials/components/Metadata'
import {AUTOCOMPLETE, DATETIME, NUMBER, STRING} from '../../../../../../_custom/partials/components/Filter/helpers'
import {TAd} from '../../../../../types/TAd'
import {TenantField} from '../../../../../controls'

type TAdProperty = {
  property: keyof TAd
} & Omit<TProperty, 'property'>

type TAdProperties = Array<TAdProperty>

export const PROPERTIES: TAdProperties = [
  {
    property: 'id',
    type: NUMBER,
    label: 'ID',
    filterable: false,
    sortable: false,
  },
  {
    property: 'title',
    type: STRING,
    label: 'NAME',
    filterable: true,
    sortable: true,
  },
  {
    property: 'priority',
    type: NUMBER,
    label: 'PRIORITY',
    filterable: true,
    sortable: true,
  },
  {
    property: 'tenant',
    type: AUTOCOMPLETE,
    label: 'TENANT',
    filterable: true,
    sortable: false,
    Field: (props) => {
      return (
        <TenantField
          useId
          {...props}
        />
      )
    }
  }
]

export const Toolbar = () => {

  return (
    <PageToolbar>
      <div className='ms-4'>
        <Search />
      </div>
      <div className='ms-4'>
        <Sort />
      </div>
      <div className='ms-4'>
        <Filter />
      </div>
      <div className='ms-4'>
        <AERouteAction
          routeKey='ADS.NEW'
          className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm btn-icon-primary fw-boldest text-truncate'
        />
      </div>
    </PageToolbar>
  )
}
