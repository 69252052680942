import React from 'react'

type Props = {
  value: number
  currency?: 'MAD'
}

export const Currency:React.FC<Props> = ({value, currency = 'MAD'}) => {

  return (
    <>
      {value}
      <sup className='ps-1'>
        {currency}
      </sup>
    </>
  )
}