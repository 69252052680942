import React from 'react'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const FirstNameField = ({...props})=>(
  <Field
    component={FormikBootstrapInput}
    name='firstName'
    label='FIRSTNAME'
    {...props}
  />
)