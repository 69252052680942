import axios from "axios";
import {getFormData} from '../../_custom/helpers/AxiosHelpers'
import {toAbsoluteApiUrl} from '../../_custom/helpers/AssetHelpers'
import {TMetadata} from '../../_custom/partials/components/Metadata'
import {TEditTenantPost, TNewTenantPost} from '../../app/types/TTenantPost'
import {MODULES} from '../../_custom/helpers/RoutingHelpers'

export const API_URI = toAbsoluteApiUrl(`/${MODULES.TENANT_POSTS.toLowerCase()}`);

/**
 * Filter all tenantPosts
 * */
export const all = (metadata:TMetadata) => {
  const {filter, join, sorts, pagination: {page, perPage}, search} = metadata
  const params = {filter, join, sorts, pagination: {page, perPage}, search}

  return axios.get(API_URI,{params});
}

/**
 * Find tenantPost by Id
 * */
export const find = (id: number) => axios.get(`${API_URI}/${id}`);

/**
 * Delete tenantPost by Id
 * */
export const destroy = (id: number) => axios.delete(`${API_URI}/${id}`);

/**
 * create tenantPost
 * */
export const create = ({tenant, ...entity}:TNewTenantPost, files:any) => {
  let object:any = entity

  if (object.tenant) {
    object.tenant = {id: object.tenant.id}
  }

  return axios.post(API_URI, getFormData(object, files));
}

/**
 * Update tenantPost
 * */
export const update = ({tenant, ...entity}:TEditTenantPost, files:any) => {
  let object:any = entity

  if (object.tenant) {
    object.tenant = {id: object.tenant.id}
  }

  return axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(entity, files));
}


