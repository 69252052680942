import { _reducer as birthdayGiftsReducer} from './slices/list'
import { _reducer as birthdayGiftsDeleteReducer} from './slices/delete'
import { _reducer as birthdayGiftsCreateReducer} from './slices/create'
import { _reducer as birthdayGiftsEditReducer} from './slices/edit'
import { _reducer as birthdayGiftsForAutocompleteReducer} from './slices/autocomplete'

export const birthdayGiftsReducers = {
  ...birthdayGiftsReducer,
  ...birthdayGiftsDeleteReducer,
  ...birthdayGiftsEditReducer,
  ...birthdayGiftsCreateReducer,
  ...birthdayGiftsForAutocompleteReducer
}

export {
  fetchDispatcher as fetchBirthdayGiftsForList,
  _useSelector as useBirthdayGiftsForList,
  actions as birthdayGiftsListActions
} from './slices/list'

export {
  fetchDispatcher as fetchBirthdayGiftsForAutocomplete,
  _useSelector as useBirthdayGiftsForAutocomplete,
  actions as birthdayGiftsForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useBirthdayGiftForEdit,
  fetchDispatcher as fetchBirthdayGiftForEdit,
  updateDispatcher as updateBirthdayGift
} from './slices/edit'

export {
  useSelector as useBirthdayGiftsForCreate,
  createDispatcher as createBirthdayGift,
  resetDispatcher as resetBirthdayGiftForCreate
} from './slices/create'

export {
  useSelector as useBirthdayGiftForDelete,
  fetchDispatcher as fetchBirthdayGiftForDelete,
  deleteDispatcher as deleteBirthdayGift
} from './slices/delete'



