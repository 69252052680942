import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Dropdown} from "react-bootstrap-v5";
import clsx from 'clsx'
import {PaginationType} from '../Metadata'

export type TPager = {
  perPage: number
  total: number
  setPerPage: (perPage:number)=>void
  counts?: Array<number>
  pager?: Boolean
}

export function Pager( {perPage, total, setPerPage, counts = [12, 24, 48, 96]}: TPager) {

  return (
    <div className="d-flex align-items-center">
      <Dropdown className="dropdown-inline" drop="up" alignRight >
        <Dropdown.Toggle
          variant="transparent"
          className="btn btn-clean btn-sm fw-bolder dropdown-toggle">
          {perPage}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu min-w-10px">
          {
            counts.map(o=>(
              <Dropdown.Item
                key={o}
                onClick={() => setPerPage(o)}
                className={clsx(perPage === o && 'active')}>
                {o}
              </Dropdown.Item>
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
      <span className="text-muted ps-3">
        <FormattedMessage
          id={"METADATA.PAGINATION.DISPLAY_OF"}
          values={{display: Math.min(perPage, total), total}}
        />
      </span>
    </div>
  );
}
