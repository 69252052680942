import axios from "axios";
import {getFormData} from '../../_custom/helpers/AxiosHelpers'
import {toAbsoluteApiUrl} from '../../_custom/helpers/AssetHelpers'
import {TMetadata} from '../../_custom/partials/components/Metadata'
import {TEditRoute, TNewRoute} from '../../app/types/TRoute'
import {MODULES} from '../../_custom/helpers/RoutingHelpers'

export const API_URI = toAbsoluteApiUrl(`/${MODULES.ROUTES.toLowerCase()}`);

/**
 * Filter all routes
 * */
export const all = (metadata:TMetadata) => {
  const {filter, join, sorts, pagination: {page, perPage}, search} = metadata
  const params = {filter, join, sorts, pagination: {page, perPage}, search}

  return axios.get(API_URI,{params});
}

/**
 * Find route by Id
 * */
export const find = (id: number) => axios.get(`${API_URI}/${id}`);

/**
 * Delete route by Id
 * */
export const destroy = (id: number) => axios.delete(`${API_URI}/${id}`);

/**
 * create route
 * */
export const create = (entity:TNewRoute, files:any) => {
  let object:any = entity

  if (object.route) {
    object.route = {id: object.route.id}
  }

  return axios.post(API_URI, getFormData(object, files));
}

/**
 * Update route
 * */
export const update = (entity:TEditRoute, files:any) => {
  let object:any = entity

  if (object.route) {
    object.route = {id: object.route.id}
  }

  return axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(entity, files));
}


