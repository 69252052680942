import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Formik} from 'formik';
import {createRole, resetRoleForCreate, useRolesForCreate} from '../../../../../api/roles'
import {Card} from 'react-bootstrap-v5'
import {AEButton} from '../../../../../_custom/partials/components/buttons'
import {PageLoading, PageToolbar} from '../../../../../_metronic/layout/core'
import {RouteTitle} from '../../../../../_custom/partials/components/RouteTitle'
import {RouteComponentProps} from 'react-router'
import {createValidationSchema} from '../../../../types/TRole'
import {Form} from './Form'

type TProps = RouteComponentProps

export const Create = ({ history }:TProps) => {
  const dispatch = useDispatch();
  const { isLoading, data, error } = useRolesForCreate();

  const saveBtnRef = useRef<HTMLDivElement | null>(null)
  const saveBtnRefClick = () => {
    if (saveBtnRef && saveBtnRef.current) {
      const {current} = saveBtnRef;
      current.click()
    }
  };

  useEffect(()=>{
    dispatch(resetRoleForCreate())
  }, [])

  return (
    <>
      <PageLoading loading={isLoading} />
      <RouteTitle id={'ROLES.EDIT'}/>
      <PageToolbar>
        <div className={'ps-3'}>
          <AEButton
            size={'sm'}
            variant={'light'}
            onClick={history.goBack}
          >
            <i className='fa fa-arrow-left'/>
            <FormattedMessage id={'BACK'} />
          </AEButton>
        </div>
        <div className={'ps-3'}>
          <AEButton
            size={'sm'}
            variant={'outline'}
            fontWeight={'bolder'}
            className='btn-outline-dashed btn-outline-primary btn-active-light-primary btn-icon-primary'
            onClick={saveBtnRefClick}
          >
            <FormattedMessage id={'SAVE'} />
          </AEButton>
        </div>
      </PageToolbar>
      <Card>
        <Card.Body>
          <Formik
            enableReinitialize
            initialErrors={error}
            initialValues={data}
            validationSchema={createValidationSchema}
            onSubmit={values=>{
              dispatch(createRole(values))
            }}
            render={formik=>(
              <Form
                formik={formik}
                btnRef={saveBtnRef}
              />)}
          />
        </Card.Body>
      </Card>
    </>
  );
}

