import * as Yup from 'yup'
import {Entity, TTimestampCreateEntity} from './TEntity'
import {defaultUser, TUser} from './TUser'

type TTypeKey = 'ROUTE'
type TStatus = 'READ' | 'UNREAD'

/**
 * Types
 * */
export type TNotification = Entity & TTimestampCreateEntity & {
  typeKey: TTypeKey,
  status: TStatus,
  pusherChannel: string,
  pusherEvent: string,
  data: any,
  user: TUser,
}
export type TNewNotification = Omit<TNotification, 'id'|'status'|'pusherChannel'|'pusherEvent'|'data'|'user'|'createdAt'>
export type TEditNotification = {
  typeKey?: TTypeKey,
  status?: TStatus,
} & Omit<TNotification, 'typeKey'|'status'|'pusherChannel'|'pusherEvent'|'data'|'user'|'createdAt'>
export type TDeleteNotification = TEditNotification

/**
 * Default Values
 */
export const defaultNotification:TNotification = {
  id: 0,
  typeKey: 'ROUTE',
  status: 'UNREAD',
  pusherChannel: 'DEFAULT',
  pusherEvent: 'DEFAULT',
  data: [],
  user: defaultUser
}
export const defaultNewNotification:TNewNotification = {
  typeKey: 'ROUTE'
}
export const defaultEditNotification:TEditNotification = {
  ...defaultNewNotification,
  id: 0,
  status: 'UNREAD',
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewNotification, any> = {
  typeKey: Yup.string().required()
}
export const adCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const adEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

