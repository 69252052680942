import React from 'react'
import {useDispatch} from 'react-redux'
import {birthdayGiftsListActions, useBirthdayGiftsForList} from '../../../../../../api/birthdayGifts'
import {AESearch} from '../../../../../../_custom/partials/components/Search/AESearch'

export const Search = () => {
  const dispatch = useDispatch();
  const {metadata} = useBirthdayGiftsForList();
  const {search} = metadata
  const {setSearch} = birthdayGiftsListActions;

  return (
    <AESearch
      value={search}
      setSearch={(value?:string)=>{
        dispatch(setSearch(value))
      }}
    />
  )
}