import React, {InputHTMLAttributes} from 'react'
import {useIntl} from "react-intl";
import clsx from "clsx";
import {FieldFeedbackIcon} from "./label/FieldFeedbackIcon";
import {FieldFeedbackLabel} from "./label/FieldFeedbackLabel";
import get from "lodash/get";
import {FieldAttributes} from 'formik/dist/Field'

type TBootstrapCheckbox = {
  label?: string
  validation?: boolean
  size?: 'sm' | 'md' | 'lg'
  feedbackError?: boolean
  feedbackTouched?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>

export function BootstrapCheckbox({label, validation = true, size = 'md', feedbackError, feedbackTouched, ...props}: TBootstrapCheckbox) {

  return (
    <>
      <label className="form-label font-weight-bold">
        <span className={'pe-1'}>{label}</span>
        {validation && (
          <FieldFeedbackIcon
            error={feedbackError}
            touched={feedbackTouched}
            tooltipContent={(
              <FieldFeedbackLabel
                error={feedbackError}
                touched={feedbackTouched}
                label={label}
              />
            )}
          />
        )}
      </label>

      <label className={clsx('form-check form-check-custom form-check-solid', `form-check-${size}`)}>
        <input
          type='checkbox'
          className='form-check-input'
          {...props}
        />
        <span className={'form-check-label'}/>
          {label}
        </label>
    </>
  )
}


type TProps = FieldAttributes<any> & {
  label?: string
  validation?: boolean
  size?: 'sm' | 'md' | 'lg'
  feedbackError?: boolean
  feedbackTouched?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>

export function FormikBootstrapCheckbox({field, form, label, ...props}:TProps) {
  const {name, value} = field
  const {touched, errors, setFieldValue} = form
  const {formatMessage} = useIntl()
  label = label ? formatMessage({id:label}) : '';

  return (
    <BootstrapCheckbox
      label={label}
      checked={value}
      onChange={()=>{
        setFieldValue(name, !value);
      }}
      feedbackError={get(errors, name)}
      feedbackTouched={get(touched, name)}
      {...props}
    />
  );
}
