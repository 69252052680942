import React, {LegacyRef} from 'react'
import {FormikProps} from 'formik/dist/types'
import {PointsField, ProductField, TitleField} from '../../../../controls'

type TProps = {
    formik: FormikProps<any>
    btnRef: LegacyRef<HTMLDivElement>
}

export const Form = ({formik, btnRef}: TProps) => {
    const {handleSubmit} = formik

    return (
        <div className='form form-label-right'>
            <div className='card mb-5'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='mb-3 col-md'>
                            <TitleField/>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='mb-3 col-md'>
                            <PointsField/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='mb-3 col-md'>
                            <ProductField/>
                        </div>
                    </div>

                    {/*    TODO fields : product, points amount */}
                </div>
            </div>

            <div
                className='d-none'
                ref={btnRef}
                onClick={() => {
                    handleSubmit()
                }}
            />
        </div>
    );
}

