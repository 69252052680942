import React from "react";
import {FormattedMessage, useIntl} from 'react-intl'


type TProps = {
  label?: string
  touched?: boolean
  error: any
  type?: string
}


export function FieldFeedbackLabel({label, touched, error, type = 'text'}: TProps) {
  //todo depth

  const {formatMessage} = useIntl();

  if (touched) {
    if (error) {
      const {descriptor, props} = error;

      return (
        <div className="invalid-feedback d-block">
          {
            descriptor && descriptor.id ?
              formatMessage(descriptor, {...props, path: label}) :
              formatMessage({id:"VALIDATION.MIXED.INVALID"},{path:label})
          }
        </div>);
    } else {
      if (label) {
        return (
          <div className="valid-feedback d-block">
            { formatMessage( {id:"VALIDATION.MIXED.VALID"}, {path: label} ) }
          </div>);
      }
    }
  }





  return (
    <div className="feedback d-block">
      <FormattedMessage id='VALIDATION.MIXED.UNTOUCHED' values={{type, path: label}}/>
    </div>
  );
  // switch (type) {
  //   return inputLabel({ label, touched, error, customFeedbackLabel });
    // case "text":
    //   return inputLabel({ label, touched, error, customFeedbackLabel });
    // case "email":
    //   return inputLabel({ label, touched, error, customFeedbackLabel });
    // case "password":
    //   return inputLabel({ label, touched, error, customFeedbackLabel });
    // default:
    //   return selectLabel({ label, touched, error, customFeedbackLabel });
}
