import React from 'react'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const PriceField:React.FC<any> = ({...props})=>(
  <Field
    component={FormikBootstrapInput}
    name='price'
    label='PRICE'
    type='number'
    {...props}
  />
)