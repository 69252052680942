import * as Yup from 'yup'
import {Entity} from './TEntity'

export type TRoute = Entity & {
  id: number
  priority: number
  routeKey: string
}

export type TNewRoute = Omit<TRoute, 'id'>
export type TEditRoute = TRoute
export type TDeleteRoute = TEditRoute

export const defaultRoute:TRoute = {
  id: 0,
  priority: 0,
  routeKey: '',
}
export const defaultNewRoute:TNewRoute = {
  priority: 0,
  routeKey: ''
}
export const defaultEditRoute:TEditRoute = {
  id: 0,
  priority: 0,
  routeKey: ''
}

export const editValidationSchema = Yup.object().shape({
  routeKey: Yup.string().required(),
  priority: Yup.number()
})
export const createValidationSchema = editValidationSchema
