import * as Yup from 'yup'
import {Entity, TFileEntity, TTenantEntity} from './TEntity'
import {TProductCategory} from './TProductCategory'

/**
 * Types
 * */
export type TProduct = Entity & TTenantEntity & TFileEntity & {
  name: string,
  price: number,
  qrCodeFileName: string,
  category?: TProductCategory | null,
}
export type TNewProduct = Omit<TProduct, 'id' | 'qrCodeFileName'>
export type TEditProduct = Omit<TProduct, 'qrCodeFileName'>
export type TDeleteProduct = TEditProduct

/**
 * Default Values
 */
export const defaultProduct:TProduct = {
  id: 0,
  name: '',
  price: 0,
  qrCodeFileName: '',
  fileName: '',
  tenant: null,
  category: null,
}
export const defaultNewProduct:TNewProduct = {
  name: '',
  price: 0,
  fileName: '',
  category: null,
}
export const defaultEditProduct:TEditProduct = {
  ...defaultNewProduct,
  id: 0
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewProduct, any> = {
  name: Yup.string().required(),
  price: Yup.number().required(),
  fileName: Yup.string(),
  tenant: Yup.object().nullable(),
  category: Yup.object().nullable(),
}
export const productCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const productEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

