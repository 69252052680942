/* eslint-disable react/jsx-no-target-blank */
import React, {useMemo} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useRoutesForAppState} from '../../../../api/auth'
import {TAppRoute} from '../../../../app/routing/Routes'
import {MODULES, TModuleKey, VIEWS} from '../../../../_custom/helpers/RoutingHelpers'
import {Icon, ICONS} from '../../../../_custom/partials/components/Icon'
import {MenuItem} from '../header/MenuItem'

// const groups = {
//
// }

export function AsideMenuMain() {
  const intl = useIntl()
  const {data: routes} = useRoutesForAppState();

  const menuRoutes = useMemo(()=>{

    return routes.filter((route: TAppRoute)=>route.views.includes(VIEWS.MENU))
  }, [routes])


  return (
    <>
    {/*<div className='menu-item'>*/}
    {/*  <div className='menu-content pt-8 pb-2'>*/}
    {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
    {/*  </div>*/}
    {/*</div>*/}
      {
        (Object.keys(MODULES) as TModuleKey[])
          .filter((key)=> {
            return menuRoutes.findIndex((route:TAppRoute)=>route.context === MODULES[key]) !== -1
          })
          .map((key)=>(
            <AsideMenuItemWithSub
              key={key}
              title={intl.formatMessage({id: key})}
              to={`/${key.toLowerCase()}`}
              icon={`/media/icons/duotone/${ICONS[key]}`}
            >

              {
                menuRoutes
                  .filter((route: TAppRoute)=>route.context === MODULES[key])
                  .map((route: TAppRoute, index:number)=>{
                    return (
                      <AsideMenuItem
                        key={route.routeKey}
                        title={intl.formatMessage({id: route.routeKey})}
                        to={route.path}
                        icon={`/media/icons/duotone/${route.icon}`}
                      />

                    )
                  })
              }

            </AsideMenuItemWithSub>
          ))
      }
      {/*<AsideMenuItem*/}
      {/*  to='/dashboard'*/}
      {/*  icon='/media/icons/duotone/Design/PenAndRuller.svg'*/}
      {/*  title={intl.formatMessage({id: 'MENU.DASHBOARD'})}*/}
      {/*  fontIcon='bi-app-indicator'*/}
      {/*/>*/}
      {/*<AsideMenuItem*/}
      {/*  to='/builder'*/}
      {/*  icon='/media/icons/duotone/Interface/Settings-02.svg'*/}
      {/*  title='Layout Builder'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*/>*/}
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/crafted/pages'*/}
      {/*  title='Pages'*/}
      {/*  fontIcon='bi-archive'*/}
      {/*  icon='/media/icons/duotone/Code/Compiling.svg'*/}
      {/*>*/}
      {/*  <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>*/}
      {/*    <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />*/}
      {/*    <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />*/}
      {/*    <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />*/}
      {/*    <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />*/}
      {/*    <AsideMenuItem*/}
      {/*      to='/crafted/pages/profile/connections'*/}
      {/*      title='Connections'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*  </AsideMenuItemWithSub>*/}

      {/*  <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>*/}
      {/*    <AsideMenuItem*/}
      {/*      to='/crafted/pages/wizards/horizontal'*/}
      {/*      title='Horizontal'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*    <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />*/}
      {/*  </AsideMenuItemWithSub>*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/crafted/accounts'*/}
      {/*  title='Accounts'*/}
      {/*  icon='/media/icons/duotone/General/User.svg'*/}
      {/*  fontIcon='bi-person'*/}
      {/*>*/}
      {/*  <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/error'*/}
      {/*  title='Errors'*/}
      {/*  fontIcon='bi-sticky'*/}
      {/*  icon='/media/icons/duotone/Code/Error-circle.svg'*/}
      {/*>*/}
      {/*  <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/crafted/widgets'*/}
      {/*  title='Widgets'*/}
      {/*  icon='/media/icons/duotone/Layout/Layout-4-blocks.svg'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*>*/}
      {/*  <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/apps/chat'*/}
      {/*  title='Chat'*/}
      {/*  fontIcon='bi-chat-left'*/}
      {/*  icon='/media/icons/duotone/Communication/Group-chat.svg'*/}
      {/*>*/}
      {/*  <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content'>*/}
      {/*    <div className='separator mx-1 my-4'></div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className='menu-item'>*/}
      {/*  <a*/}
      {/*    target='_blank'*/}
      {/*    className='menu-link'*/}
      {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
      {/*  >*/}
      {/*    <span className='menu-icon'>*/}
      {/*      <KTSVG path='/media/icons/duotone/Files/File.svg' className='svg-icon-2' />*/}
      {/*    </span>*/}
      {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </>
  )

  return (
    <>

      {
        (Object.keys(MODULES) as TModuleKey[])
          .filter((key)=> {
            return menuRoutes.findIndex((route:TAppRoute)=>route.context === MODULES[key]) !== -1
          })
          .map((key)=>(
            <div key={key}>
              <div  className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <Icon
                    path={ICONS[key]}
                    size='2'
                    className='pe-3'
                    variant='primary'
                  />
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                    <FormattedMessage id={key}/>
                  </span>
                </div>
              </div>
              {
                menuRoutes
                  .filter((route: TAppRoute)=>route.context === MODULES[key])
                  .map((route: TAppRoute, index:number)=>{
                    return (
                      <AsideMenuItem
                        key={route.routeKey}
                        title={intl.formatMessage({id: route.routeKey})}
                        to={route.path}
                        icon={`/media/icons/duotone/${route.icon}`}
                      />
                    )})
              }

            </div>
          ))
      }
    </>
  )
}
