import React, {LegacyRef} from 'react'
import {FormikProps} from 'formik/dist/types'
import {
  UserNameField,
  LastNameField,
  FirstNameField,
  EmailField,
  PasswordField,
  PasswordConfirmField,
  RoleField, TenantField,
} from '../../../../controls'
import {Granted} from '../../../../../_custom/partials/components/Granted'

type TProps = {
  formik: FormikProps<any>
  btnRef: LegacyRef<HTMLDivElement>
}

export const Form = ({formik, btnRef}:TProps) => {
  const {handleSubmit} = formik

  return (
    <div className='form form-label-right'>
      <div className='row'>
        <div className={'mb-3 col-md'}>
          <FirstNameField />
        </div>
        <div className={'mb-3 col-md'}>
          <LastNameField />
        </div>
      </div>
      <div className='row'>
        <div className={'mb-3 col-md'}>
          <UserNameField />
        </div>
        <div className={'mb-3 col-md'}>
          <EmailField />
        </div>
      </div>
      <div className='row'>
        <div className={'mb-3 col-md'}>
          <PasswordConfirmField />
        </div>
        <div className={'mb-3 col-md'}>
          <PasswordField />
        </div>
      </div>
      <div className='row'>
        <div className={'mb-3 col-md'}>
          <RoleField />
        </div>
        <Granted roles={['ROLE_SUPER_ADMIN']}>
          <div className={'mb-3 col-md'}>
            <TenantField />
          </div>
        </Granted>

      </div>
      <div
        className={'d-none'}
        ref={btnRef}
        onClick={()=>{
          handleSubmit()
        }}
      />
    </div>
  );
}

