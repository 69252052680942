/* eslint-disable no-restricted-imports */
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {deleteBlogCategory, fetchBlogCategoryForDelete, useBlogCategoryForDelete} from '../../../../../api/blogCategories';
import {EntityCard} from '../../components/EntityCard';
import {AEButton} from '../../../../../_custom/partials/components/buttons'
import {RouteTitle} from '../../../../../_custom/partials/components/RouteTitle'
import {PageLoading, PageToolbar} from '../../../../../_metronic/layout/core'

type TProps = {
  history: any
  match: any
}

export function Delete({history, match}:TProps) {
  const dispatch = useDispatch();
  const { isLoading, data } = useBlogCategoryForDelete()

  const id = match.params.id;


  useEffect(() => {
    dispatch(fetchBlogCategoryForDelete(id));
  }, [id, dispatch]);

  const deleteEntity = () => {
    dispatch(deleteBlogCategory(id))
  };

  return (
    <>
      <PageLoading loading={isLoading} />
      <RouteTitle id='BLOG_CATEGORIES.DELETE' />
      <PageToolbar>
        <div className='ps-3'>
          <AEButton
            size='sm'
            variant='light'
            onClick={history.goBack}
            className='mr-3'
          >
            <FormattedMessage id='BACK' />
          </AEButton>
        </div>
        <div className='ps-3'>
          {
            <AEButton
              size='sm'
              variant='outline'
              fontWeight='bolder'
              className='btn-outline-dashed btn-outline-danger btn-active-light-danger btn-icon-danger'
              onClick={deleteEntity}
              data-kt-indicator={isLoading && 'on'}
              disabled={isLoading || !data.id}
            >
              <span className='indicator-label'>
                <FormattedMessage id='BLOG_CATEGORIES.DELETE' />
              </span>
              <span className='indicator-progress'>
                <FormattedMessage id='PLEASE_WAIT' />
                <span className='spinner-border spinner-border-sm align-middle ms-2'/>
              </span>
            </AEButton>
          }

        </div>
      </PageToolbar>
      {
        ! isLoading && !! data.id &&
        <EntityCard
          entity={data}
          className='card-border border-0'
        />
      }
    </>

  );
}

