import React from 'react'
import {Search} from './Search'
import {Sort} from './Sort'
import {Filter} from './Filter'
import {PageToolbar} from '../../../../../../_metronic/layout/core'
import {AERouteAction} from '../../../../../../_custom/partials/components/AERouteActions'
import {TProperty} from '../../../../../../_custom/partials/components/Metadata'
import {NUMBER, AUTOCOMPLETE, STRING} from '../../../../../../_custom/partials/components/Filter/helpers'
import {TTenant} from '../../../../../types/TTenant'
import {TenantStatusField} from '../../../../../controls'

type TTenantProperty = {
  property: keyof TTenant
} & Omit<TProperty, 'property'>

type TTenantProperties = Array<TTenantProperty>

export const PROPERTIES: TTenantProperties = [
  {
    property: 'id',
    type: NUMBER,
    label: 'ID',
    filterable: false,
    sortable: false,
  },
  {
    property: 'name',
    type: STRING,
    label: 'NAME',
    filterable: true,
    sortable: true,
  },
  {
    property: 'email',
    type: STRING,
    label: 'EMAIL',
    filterable: true,
    sortable: true,
  },
  {
    property: 'landline',
    type: STRING,
    label: 'LANDLINE',
    filterable: true,
    sortable: true,
  },
  {
    property: 'phone',
    type: STRING,
    label: 'PHONE',
    filterable: true,
    sortable: true,
  },
  {
    property: 'status',
    type: AUTOCOMPLETE,
    label: 'STATUS',
    filterable: true,
    sortable: false,
    Field: (props) => {
      return (
        <TenantStatusField
          size='sm'
          placeholderLabel
          validation={false}
          {...props}
        />
      )
    }
  }
]

export const Toolbar = () => {

  return (
    <PageToolbar>
      <div className='ms-4'>
        <Search />
      </div>
      <div className='ms-4'>
        <Sort />
      </div>
      <div className='ms-4'>
        <Filter />
      </div>
      <div className='ms-4'>
        <AERouteAction
          routeKey='TENANTS.NEW'
          className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm btn-icon-primary fw-boldest text-truncate'
        />
      </div>
    </PageToolbar>
  )
}
