import React from 'react'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const EmailField = ({...props}) => (
  <Field
    component={FormikBootstrapInput}
    name='email'
    label='EMAIL'
    type='email'
    {...props}
  />
)