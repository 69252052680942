import React, {useMemo, useCallback} from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'

type TElement = React.FC<{
  attributes: Array<any>
  element: Record<any, any>
}>
export const Element:TElement = ({ attributes, element, children }) => {
  switch (element.type) {
    case 'ae-separator':
      return (
        <React.Fragment>
          {children}
          <div {...attributes} className="separator separator-solid separator-border-2 mt-3 mb-3"/>
        </React.Fragment>
      )
    case 'ae-blockquote':
      return (
        <blockquote className="blockquote border-left border-left-light-primary border-5" {...attributes}>
          <p className="pl-3">{children}</p>
        </blockquote>)
    /**/
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'h1':
      return <h1 {...attributes}>{children}</h1>
    case 'h2':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <span {...attributes}>{children}</span>
  }
}
export const Leaf:React.FC<any> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }
  /* CUSTOM */
  if (leaf.mark) {
    children = <mark>{children}</mark>
  }
  if (leaf.del) {
    children = <del>{children}</del>
  }
  if (leaf.small) {
    children = <small>{children}</small>
  }

  return <span {...attributes}>{children}</span>
}

type TSlateEditorPreview = React.FC<{
  value: any
}>
export const SlateEditorPreview:TSlateEditorPreview = ({value}) => {
  // const [value, setValue] = useState<Node[]>(value)
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withReact<any>(createEditor()), [])
  return (
    <Slate
      editor={editor}
      value={value}
      onChange={value => {
        // setValue(value)
      }}>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
        />
    </Slate>
  )
}


