import { _reducer as blogCategoriesReducer} from './slices/list'
import { _reducer as blogCategoriesDeleteReducer} from './slices/delete'
import { _reducer as blogCategoriesCreateReducer} from './slices/create'
import { _reducer as blogCategoriesEditReducer} from './slices/edit'
import { _reducer as blogCategoriesForAutocompleteReducer} from './slices/autocomplete'

export const blogCategoriesReducers = {
  ...blogCategoriesReducer,
  ...blogCategoriesDeleteReducer,
  ...blogCategoriesEditReducer,
  ...blogCategoriesCreateReducer,
  ...blogCategoriesForAutocompleteReducer
}

export {
  fetchDispatcher as fetchBlogCategoriesForList,
  _useSelector as useBlogCategoriesForList,
  actions as blogCategoriesListActions
} from './slices/list'

export {
  fetchDispatcher as fetchBlogCategoriesForAutocomplete,
  _useSelector as useBlogCategoriesForAutocomplete,
  actions as blogCategoriesForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useBlogCategoryForEdit,
  fetchDispatcher as fetchBlogCategoryForEdit,
  updateDispatcher as updateBlogCategory
} from './slices/edit'

export {
  useSelector as useBlogCategoriesForCreate,
  createDispatcher as createBlogCategory,
  resetDispatcher as resetBlogCategoryForCreate
} from './slices/create'

export {
  useSelector as useBlogCategoryForDelete,
  fetchDispatcher as fetchBlogCategoryForDelete,
  deleteDispatcher as deleteBlogCategory
} from './slices/delete'



