/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {APP_LOGO} from '../../../_custom/helpers/AssetHelpers'
import {languages} from '../../../_metronic/partials/layout/header-menus/Languages'
import {setLanguage, useLang} from '../../../_metronic/i18n/Metronici18n'
import {TenantRegistration} from './components/tenantRegistration/TenantRegistration'

export function AuthPage() {
  const lang = useLang()
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/progress-hd.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={APP_LOGO} className='h-45px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/registration' component={Registration} />
            <Route path='/auth/tenant-registration' component={TenantRegistration} />
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          {languages.filter(l=>l.lang !== lang).map((l) => (
            <a
              key={l.lang}
              href='#'
              className='fs-8 rounded bg-light px-3 py-2 d-flex mx-2'
              onClick={(event)=>{
                event.preventDefault()
                setLanguage(l.lang)
              }}
            >
              {l?.name}{' '}
              <img
                className='w-15px h-15px rounded-1 ms-2'
                src={l?.flag}
                alt='metronic'
              />
            </a>
          ))}


          {/*<a href='#' className='text-muted text-hover-primary px-2'>*/}
          {/*  Contact*/}
          {/*</a>*/}

          {/*<a href='#' className='text-muted text-hover-primary px-2'>*/}
          {/*  Contact Us*/}
          {/*</a>*/}
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}
