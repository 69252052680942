/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";

export const DropdownCustomToggler = forwardRef(({className, onClick, ...props}, ref) => {


  return (
    <a
      ref={ref}
      className={`btn btn-light btn-sm font-weight-bold dropdown-toggle btn-block text-truncate ${className}`}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      {...props}
    />
  );
});
