import React, {useEffect} from 'react';
import ReactMoment from 'react-moment';
import {useIntl} from 'react-intl';
import moment from 'moment';


const MomentLocaleMap = {
  ar: 'ar-ma',
  en: 'en-gb',
  es: 'es',
  fr: 'fr',
};

const langLoader = (lang) => import(`moment/locale/${MomentLocaleMap[lang]}`);

export const Moment = (
  {
    date,
    format = 'LL',
    ...props
  }) =>{

  const {locale} = useIntl();
  useEffect(()=>{
    if(locale !== 'en') langLoader(locale);
  }, [locale])

  return (
    <React.Fragment>
      {
        date ?
          <ReactMoment format={format} locale={locale} {...props}>{date}</ReactMoment> :
          ''
      }

      {/*{moment(date).locale('es').format(format)}*/}
    </React.Fragment>
  )
}

//https://momentjs.com/docs/#/durations/
export const Duration = ({date, unit  = 'minutes', argWithSuffix = false }) =>{
  const {locale} = useIntl();
  useEffect(()=>{
    if(locale !== 'en') {
      langLoader(locale);
    }
  }, [locale])

  const diff = moment().diff(date, unit)

  return (
    <>
      {
        moment
          .duration(-diff, unit)
          .locale(locale)
          .humanize(argWithSuffix)
      }
    </>
  )
}