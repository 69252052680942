import { _reducer as usersReducer} from './slices/list'
import { _reducer as usersDeleteReducer} from './slices/delete'
import { _reducer as usersCreateReducer} from './slices/create'
import { _reducer as usersEditReducer} from './slices/edit'

export const usersReducers = {
  ...usersReducer,
  ...usersDeleteReducer,
  ...usersEditReducer,
  ...usersCreateReducer,
}

export {
  fetchDispatcher as fetchUsersForList,
  _useSelector as useUsersForList,
  actions as usersListActions
} from './slices/list'

export {
  useSelector as useUserForEdit,
  fetchDispatcher as fetchUserForEdit,
  updateDispatcher as updateUser
} from './slices/edit'

export {
  useSelector as useUsersForCreate,
  createDispatcher as createUser,
  resetDispatcher as resetUserForCreate
} from './slices/create'

export {
  useSelector as useUserForDelete,
  fetchDispatcher as fetchUserForDelete,
  deleteDispatcher as deleteUser
} from './slices/delete'



