import React from 'react'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const LandlineField = ()=>(
  <Field
    component={FormikBootstrapInput}
    name='landline'
    label='LANDLINE'
    type='tel'
  />
)