import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {FieldArray, Formik} from "formik";
import {getDefaultFilter} from "./helpers";
import {FilterForm} from "./FilterForm";
import {Field} from 'formik'
import {FilterWhereOperator} from './controls/FilterWhereOperator'
import {AEButton} from '../buttons'
import {Icon, ICONS} from '../Icon'
import {FormikProps} from 'formik/dist/types'
import {PropertyFilterType, TProperties} from '../Metadata'

type TProps = {
  setFilter: (values:any)=>void
  properties: TProperties
  formik: FormikProps<any>
}

export const Form = ({properties, setFilter, formik} :TProps) => {
  const {values, setFieldValue, handleReset} = formik

  useEffect(() => {
    setFilter(values)
  }, [values])

  return (
    <>
      <div className='d-flex justify-content-between px-4 py-4'>
        <div className='fs-4 text-dark fw-bolder d-flex align-self-center'>
          <FormattedMessage id={'FILTERS'} />
        </div>
        <div className='d-flex justify-content-end'>
          <AEButton
            className={'me-2'}
            variant={'light'}
            size={'sm'}
            onClick={handleReset}
          >
            <Icon path={ICONS.RESET} size={'lg'} />
            <FormattedMessage id={'RESET'}/>
          </AEButton>

          <AEButton
            variant={'light-primary'}
            size={'sm'}
            onClick={() => {
              if (properties.length > 0) {
                const {type, property} = properties[0]

                setFieldValue(
                  `filters.${values.filters.length}`,
                  {
                    ...getDefaultFilter(type),
                    property: property,
                  }
                )
              }
            }}
          >
            <Icon path={ICONS.PLUS} size={'lg'} />
            <FormattedMessage id={'ADD_FILTER'}/>
          </AEButton>


        </div>
      </div>

      <div className='separator border-gray-200'/>

      <FieldArray
        name={'filters'}
      >
        {(helpers) => {

          return values.filters.map((filter:PropertyFilterType, index:number) => {
            return (
              <div key={index} className={'row align-items-center w-100 my-3 mx-0'}>
                <div className={'d-flex justify-content-center align-items-center col-2 text-center'}>
                  {
                    index === 0 &&
                    <div className="text-dark-50 font-weight-bold">
                      <FormattedMessage id={'WHERE'} />
                    </div>
                  }
                  {
                    index === 1 &&
                    <Field
                      component={FilterWhereOperator}
                      name={`operator`}
                    />
                  }
                  {
                    index > 1 &&
                    <FormattedMessage id={values.operator} />
                  }
                </div>
                <div className={'col-10'}>
                  <Formik
                    initialValues={filter}
                    onSubmit={(values) => {
                      setFieldValue(`filters.${index}`, values)
                    }}
                  >
                    {
                      formik => (
                        <FilterForm
                          formik={formik}
                          properties={properties}
                          onRemove={()=>{
                            helpers.remove(index)
                          }}
                        />
                      )
                    }
                  </Formik>
                </div>

              </div>
            )
          })
        }}
      </FieldArray>

    </>
  )
}