import React from 'react'
import {TProduct} from '../../../types/TProduct'
import {APP_LOGO_WITHOUT_TEXT, toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'

type TProps = {
  entity: TProduct
}

export const EntityLabel:React.FC<TProps> = ({entity}) => {

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-25px w-25px h-25px bg-light me-2'>
        <img
          src={
            entity.fileName ?
              toAbsoluteUploadUrl(`/products/${entity.fileName}`) :
              APP_LOGO_WITHOUT_TEXT
          }
          alt={` `}
        />
      </div>
      <div className='fw-bold'>
        {entity.name}
      </div>
    </div>
  )
}