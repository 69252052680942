import React from 'react'
import {Search} from './Search'
import {Sort} from './Sort'
import {Filter} from './Filter'
import {PageToolbar} from '../../../../../../_metronic/layout/core'
import {AERouteAction} from '../../../../../../_custom/partials/components/AERouteActions'
import {TProperty} from '../../../../../../_custom/partials/components/Metadata'
import {DATETIME, NUMBER, AUTOCOMPLETE, STRING} from '../../../../../../_custom/partials/components/Filter/helpers'
import {RoleField, TenantField} from '../../../../../controls'
import {TUser} from '../../../../../types/TUser'

type TUserProperty = {
  property: keyof TUser
} & Omit<TProperty, 'property'>

type TUserProperties = Array<TUserProperty>

export const PROPERTIES: TUserProperties = [
  {
    property: 'id',
    type: NUMBER,
    label: 'ID',
    filterable: false,
    sortable: false,
  },
  {
    property: 'username',
    type: STRING,
    label: 'USERNAME',
    filterable: true,
    sortable: true,
  },
  {
    property: 'firstName',
    type: STRING,
    label: 'FIRSTNAME',
    filterable: true,
    sortable: true,
  },
  {
    property: 'lastName',
    type: STRING,
    label: 'LASTNAME',
    filterable: true,
    sortable: true,
  },
  {
    property: 'email',
    type: STRING,
    label: 'EMAIL',
    filterable: true,
    sortable: true,
  },
  {
    property: 'createdAt',
    type: DATETIME,
    label: 'CREATION_DATE',
    filterable: true,
    sortable: true,
  },
  {
    property: 'role',
    type: AUTOCOMPLETE,
    label: 'ROLE',
    filterable: true,
    sortable: false,
    Field: (props) => {
      return (
        <RoleField
          useId
          options={props}
          size={'sm'}
          placeholderLabel
          validation={false}
          {...props}
        />
      )
    }
  },
  {
    property: 'tenant',
    type: AUTOCOMPLETE,
    label: 'TENANT',
    filterable: true,
    sortable: false,
    Field: (props) => {
      return (
        <TenantField
          useId
          {...props}
        />
      )
    }
  }
]

export const Toolbar = () => {

  return (
    <PageToolbar>
      <div className='ms-4'>
        <Search />
      </div>
      <div className='ms-4'>
        <Sort />
      </div>
      <div className='ms-4'>
        <Filter />
      </div>
      <div className='ms-4'>
        <AERouteAction
          routeKey='USERS.NEW'
          className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm btn-icon-primary fw-boldest text-truncate'
        />
      </div>
    </PageToolbar>
  )
}
