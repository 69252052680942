/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {Formik, Field} from 'formik'
import * as auth from '../../../../api/auth/authRedux'
import {login} from '../../../../api/auth/api'
import {UsernameSchema} from '../../../../_custom/yup/schemas/UsernameSchema'
import {dev} from '../../../../_custom/helpers/env'
import {FormattedMessage} from 'react-intl'
import {PasswordSchema} from '../../../../_custom/yup/schemas/PasswordSchema'
import {FormikBootstrapInput} from '../../../../_custom/partials/controls/FormikBootstrapInput'
import {AEButton} from '../../../../_custom/partials/components/buttons'
import {APP_NAME} from '../../../../_custom/helpers/AssetHelpers'

const schema = Yup.object().shape({
  username: UsernameSchema(),
  password: PasswordSchema()
})

const initialValues = {
  username: dev(process.env.REACT_APP_TEST_LOGIN_USERNAME, ''),
  password: dev('azerty', ''),
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const loading = false; //todo redux
  const dispatch = useDispatch()

  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' values={{name: APP_NAME}}/>
        </h1>
      </div>
      {/* begin::Heading */}
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={({username, password}, {setFieldError})=>{
          login(username, password)
            .then(({data: {token}}) => {
              dispatch(auth.actions.login(token))
            })
            .catch(({response})=>{
              switch (response.status) {
                case 401:
                  setFieldError('password', 'AUTH.VALIDATION.INVALID_LOGIN');
              }
            })
        }}
      >
        {
          ({handleSubmit})=>{
            return (
              <div className='form w-100'>
                <div className={'fv-row mb-5'}>
                  <Field
                    component={FormikBootstrapInput}
                    name={'username'}
                    label={'USERNAME'}
                  />
                </div>
                <div className={'fv-row mb-5'}>
                  <Field
                    component={FormikBootstrapInput}
                    name={'password'}
                    label={'PASSWORD'}
                    type={'password'}
                  />
                </div>
                <div className='text-center'>
                  <AEButton
                    size={'lg'}
                    className={'w-100 mb-5'}
                    onClick={handleSubmit}
                  >
                    {
                      !loading &&
                      <span className='indicator-label'>
                        <FormattedMessage id='AUTH.LOGIN.BUTTON' />
                      </span>
                    }

                    {
                      loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <FormattedMessage id='PLEASE_WAIT' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                        </span>
                      )}
                  </AEButton>

                  {/* begin::Separator */}
                  {/*<div className='text-center text-muted text-uppercase fw-bolder mb-5'>*/}
                  {/*  <FormattedMessage id='OR' />*/}
                  {/*</div>*/}
                  {/*/!* end::Separator *!/*/}

                  {/*/!* begin::Google link *!/*/}
                  {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>*/}
                  {/*  <img*/}
                  {/*    alt='Logo'*/}
                  {/*    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}*/}
                  {/*    className='h-20px me-3'*/}
                  {/*  />*/}
                  {/*  <FormattedMessage id='AUTH.LOGIN.BUTTON.GOOGLE' />*/}
                  {/*</a>*/}
                  {/*/!* end::Google link *!/*/}

                  {/*/!* begin::Google link *!/*/}
                  {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>*/}
                  {/*  <img*/}
                  {/*    alt='Logo'*/}
                  {/*    src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}*/}
                  {/*    className='h-20px me-3'*/}
                  {/*  />*/}
                  {/*  <FormattedMessage id='AUTH.LOGIN.BUTTON.FACEBOOK' />*/}
                  {/*</a>*/}
                  {/*/!* end::Google link *!/*/}

                  {/*/!* begin::Google link *!/*/}
                  {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>*/}
                  {/*  <img*/}
                  {/*    alt='Logo'*/}
                  {/*    src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}*/}
                  {/*    className='h-20px me-3'*/}
                  {/*  />*/}
                  {/*  <FormattedMessage id='AUTH.LOGIN.BUTTON.APPLE' />*/}
                  {/*</a>*/}
                  {/*/!* end::Google link *!/*/}
                </div>
              </div>
            )
          }
        }
      </Formik>

      <div className='d-flex justify-content-between'>
        <div className=''>
          {/*<Link to='/auth/registration' className='fw-bolder'>*/}
          {/*  <FormattedMessage id='AUTH.REGISTER.TITLE' />*/}
          {/*</Link>*/}
        </div>
        <div className=''>
          <Link to='/auth/forgot-password' className='fw-bolder'>
            <FormattedMessage id='AUTH.FORGOT.TITLE' />
          </Link>
        </div>
      </div>
      <div>
        <div className='d-flex justify-content-between pt-4'>
          <Link to='/auth/tenant-registration' className='fw-bolder'>
            <FormattedMessage id='AUTH.TENANT.REGISTER.TITLE' />
          </Link>
        </div>
      </div>

    </>
  )
}
