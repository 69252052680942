import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {EntityCard} from '../../components/EntityCard';
import {fetchTenantGroupsForList, tenantGroupsListActions, useTenantGroupsForList} from '../../../../../api/tenantGroups';
import {Pagination} from '../../../../../_custom/partials/components/pagination/Pagination'
import {PageLoading} from '../../../../../_metronic/layout/core'
import {RouteTitle} from '../../../../../_custom/partials/components/RouteTitle'
import {Toolbar} from './toolbar/Toolbar'

export const List = () => {
  const dispatch = useDispatch();
  const {data, isLoading, metadata} = useTenantGroupsForList();
  const {pagination, filter, sorts, search} = metadata;
  const {setPage, setPerPage} = tenantGroupsListActions;

  useEffect(()=>{
    dispatch(fetchTenantGroupsForList(metadata));
  }, [
    search,
    pagination.page, pagination.perPage,
    sorts,
    // filter
    JSON.stringify(filter)
  ])

  return (
    <>
      <PageLoading loading={isLoading} />
      <RouteTitle id='TENANT_GROUPS.LIST'/>
      <Toolbar />
      {/*<Filter />*/}

      <div className='row'>
        {
          data.map((r)=>(
            <div key={r.id} className='-col-sm-6 col-md-6 col-lg-4 col-xxl-3 pb-6'>
              <EntityCard
                key={r.id}
                entity={r}
                className='h-100'
                editAction
                deleteAction
              />
            </div>
          ))
        }
      </div>


      <Pagination
        total={pagination.total}
        perPage={pagination.perPage}
        page={pagination.page}
        pages={pagination.pages}
        setPage={(page)=>{
          dispatch(setPage(page))
        }}
        setPerPage={(perPage)=>{
          dispatch(setPerPage(perPage))
        }}
      />
    </>
  );
}
