import React from "react";
import {FormattedMessage} from "react-intl";
import {DropdownCustomToggler} from '../../dropdowns'
import {Dropdown} from 'react-bootstrap-v5'
import {TFormikControl} from '../../../controls/FormikBootstrapInput'
import {TProperties} from '../../Metadata'


export const ASC = 'ASC';
export const DESC = 'DESC';

type TProps = TFormikControl & {
  className?: string
  properties: TProperties
}

export const SortDirection = ({field, form, className = ''}: TProps) => {
  const {name, value} = field
  const {setFieldValue} = form

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle as={DropdownCustomToggler} className={'w-100'}>
        <FormattedMessage id={value} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {[ASC, DESC].map(id => (
          <Dropdown.Item
            key={id}
            active={id === value}
            onClick={() => {
              setFieldValue(name, id);
            }}
          >
            <FormattedMessage id={id} />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}