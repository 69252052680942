import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {ICONS} from '../../../../_custom/partials/components/Icon'
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import {DetailLine} from '../../../../_custom/partials/components/DetailLine'
import clsx from 'clsx'
import {TRole} from '../../../types/TRole'
import {FormattedMessage, useIntl} from 'react-intl'

type Props = {
  entity: TRole
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:Props) => {

  const { user } = useAuthState()
  const { formatMessage } = useIntl()

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'ROLES.DELETE', params: {id: entity.id}})
    }
    if (editAction && user && user.role && user.role.priority < entity.priority) {
      actions.push({id: 'ROLES.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, user, editAction, deleteAction])

  const sliceIndex = 4;

  return (
    <div className={clsx(
      'card border border-2 border-gray-300 border-hover',
      className
    )}>
      <div className='card-header flex-nowrap border-0'>
        <div className='card-title m-0'>
          <div className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
            {entity.name}
          </div>
        </div>

        <div className='card-toolbar m-0'>
          <AERouteActions actions={actions} />
        </div>
      </div>

      <div className='card-body d-flex flex-column pt-0'>
        <div>
          <DetailLine
            title={'PRIORITY'}
            icon={ICONS.SORT}
          >
            {entity.priority}
          </DetailLine>

          {
            entity.routes.slice(0, sliceIndex).map(route=>(
              <DetailLine key={route.id}>
                <FormattedMessage id={route.routeKey} />
              </DetailLine>
            ))
          }

          {
            entity.routes.length > sliceIndex &&
            <div
              title={
                entity
                  .routes
                  .slice(sliceIndex)
                  .map(route=>formatMessage({id:route.routeKey}))
                  .join(' - ')
              }
            >
              <DetailLine>
                <em>
                  <FormattedMessage id='AND_MORE' values={{count: entity.routes.length-sliceIndex}} />
                </em>
              </DetailLine>
            </div>
          }
        </div>
      </div>
    </div>
  )
}