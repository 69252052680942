import React from 'react';
import {Field} from 'formik'
import {useDispatch} from 'react-redux'
import {fetchTenantsForAutocomplete, useTenantsForAutocomplete} from '../../api/tenants'
import {
  FormikBootstrapAutocomplete,
  TFormikBootstrapAutocomplete,
} from '../../_custom/partials/controls/FormikBootstrapAutocomplete'
import {TTenant} from '../types/TTenant'
import {STRING_CONTAINS} from '../../_custom/partials/components/Metadata'
import {TRoute} from '../types/TRoute'

type TProps = TFormikBootstrapAutocomplete & {
  useId: boolean
}

export const TenantField:React.FC<TProps> = ({useId, ...props}) => {
  const dispatch = useDispatch();
  const {metadata, data, isLoading} = useTenantsForAutocomplete()

  const HandleSearch = (search:string) => {
    dispatch(
      fetchTenantsForAutocomplete({
        ...metadata,
        filter: {
          ...metadata.filter,
          filters: [
            {
              property: 'name',
              operator: STRING_CONTAINS,
              value: search
            }
          ]
        }
      })
    )
  }

  if (useId) {
    props.options = data.map(tenant=>tenant.id);
    props.getOptionLabel = (id:number)=>{
      const _tenant = data.find(tenant=>tenant.id === id)
      if (_tenant) {
        return _tenant.name;
      }
      return 'NOT_FOUND'
    }
  } else {
    props.options = data;
    props.getOptionLabel = (tenant:TTenant)=>tenant.name;
    props.getOptionSelected = (option:TRoute, value:TRoute) => option.id === value.id
  }

  return (
    <Field
      component={FormikBootstrapAutocomplete}
      name='tenant'
      label='TENANT'
      onOpen={()=>{
        HandleSearch('')
      }}
      onInputChange={(event:any, search:string) => {
        HandleSearch(search)
      }}
      loading={isLoading}
      {...props}
    />
  )
}