import React from 'react';
import {Field} from 'formik'
import {useDispatch} from 'react-redux'
import {fetchBlogCategoriesForAutocomplete, useBlogCategoriesForAutocomplete} from '../../api/blogCategories'
import {
  FormikBootstrapAutocomplete,
  TFormikBootstrapAutocomplete,
} from '../../_custom/partials/controls/FormikBootstrapAutocomplete'
import {TBlogCategory} from '../types/TBlogCategory'
import {STRING_CONTAINS} from '../../_custom/partials/components/Metadata'
import {TRoute} from '../types/TRoute'

type TProps = TFormikBootstrapAutocomplete & {
  useId: boolean
}

export const BlogCategoryField:React.FC<TProps> = ({useId, ...props}) => {
  const dispatch = useDispatch();
  const {metadata, data, isLoading} = useBlogCategoriesForAutocomplete()

  const HandleSearch = (search:string) => {
    dispatch(
      fetchBlogCategoriesForAutocomplete({
        ...metadata,
        filter: {
          ...metadata.filter,
          filters: [
            {
              property: 'name',
              operator: STRING_CONTAINS,
              value: search
            }
          ]
        }
      })
    )
  }

  if (useId) {
    props.options = data.map(category=>category.id);
    props.getOptionLabel = (id:number)=>{
      const _category = data.find(category=>category.id === id)
      if (_category) {
        return _category.name;
      }
      return 'NOT_FOUND'
    }
  } else {
    props.options = data;
    props.getOptionLabel = (category:TBlogCategory)=>category.name;
    props.getOptionSelected = (option:TRoute, value:TRoute) => option.id === value.id
  }

  return (
    <Field
      component={FormikBootstrapAutocomplete}
      name='category'
      label='CATEGORY'
      onOpen={()=>{
        HandleSearch('')
      }}
      onInputChange={(event:any, search:string) => {
        HandleSearch(search)
      }}
      loading={isLoading}
      {...props}
    />
  )
}