import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import clsx from 'clsx'
import {TBlogPost} from '../../../types/TBlogPost'
import {DetailLine} from '../../../../_custom/partials/components/DetailLine'
import {Icon, ICONS} from '../../../../_custom/partials/components/Icon'
import {Moment} from '../../../../_custom/partials/components/Moment'
import {toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'

type TProps = {
  entity: TBlogPost
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:TProps) => {

  const { user } = useAuthState()

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'BLOG_POSTS.DELETE', params: {id: entity.id}})
    }
    if (editAction) {
      actions.push({id: 'BLOG_POSTS.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, user, editAction, deleteAction])

  return (
    <div className={clsx(
      'card border border-2 -border-gray-300 border-hover',
      className
    )}>
      <div className='card-body d-flex flex-column'>
        <div
          className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px bg-light mb-2"
          style={{
            backgroundImage: `url(${toAbsoluteUploadUrl(`/blog_posts/${entity.fileName}`)})`
          }}
        />
        <div className="pb-1 fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base">
          {entity.title}
        </div>
        <div className='d-flex justify-content-between'>
          <div className='badge border-dashed bg-light-danger border-light-danger fs-5 fw-bolder text-dark p-2 d-flex align-items-center'>
            <Icon
              variant='danger'
              path='/General/Heart.svg'
              size='4'
              className=''
            />
            <span className='px-2 text-danger'>
              {entity.reactions.length}
            </span>
          </div>
          <div className='pe-2'>
            <AERouteActions actions={actions} />
          </div>
        </div>
        <div className='separator pt-3'/>
        <div className=''>
          <DetailLine
            title='CREATION_DATE'
            icon={ICONS.CALENDAR}
          >
            <Moment date={entity.createdAt} format='LL'/>
          </DetailLine>
          {
            entity.updatedAt &&
            <DetailLine
              title='UPDATING_DATE'
              icon={ICONS.CALENDAR}
            >
              <Moment date={entity.updatedAt} format='LL'/>
            </DetailLine>
          }

          <div className='pt-2'>
            {
              entity.categories.length > 0 &&
              entity.categories.map(category=>(
                <span
                  key={category.id}
                  className='badge badge-light me-1 mb-1'
                >
                  {category.name}
                </span>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}