import * as Yup from 'yup'
import {Entity, TPostEntity, TTenantEntity, TTimestampEntity} from './TEntity'
import {TTenantPostFile} from './TTenantPostFile'

/**
 * Types
 * */
export type TTenantPost = Entity & TTenantEntity & TPostEntity & TTimestampEntity & {
  model: 'DEFAULT' | 'SALE'
  files: Array<TTenantPostFile>
  _reactionsCount: number
  _shareCount: number
}
export type TNewTenantPost = Omit<TTenantPost, 'id' | 'createdAt' | 'updatedAt' | '_reactionsCount' | '_shareCount'>
export type TEditTenantPost = Omit<TTenantPost, 'createdAt' | 'updatedAt' | '_reactionsCount' | '_shareCount'>
export type TDeleteTenantPost = TEditTenantPost

/**
 * Default Values
 */
export const defaultTenantPost:TTenantPost = {
  id: 0,
  title: '',
  content: null,
  model: 'DEFAULT',
  tenant: null,
  files: [],
  _reactionsCount: 0,
  _shareCount: 0
}
export const defaultNewTenantPost:TNewTenantPost = {
  title: '',
  content: null,
  model: 'DEFAULT',
  files: []
}
export const defaultEditTenantPost:TEditTenantPost = {
  ...defaultNewTenantPost,
  id: 0
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewTenantPost, any> = {
  title: Yup.string().required(),
  content: Yup.array().nullable(),
  files: Yup.array(),
  tenant: Yup.object().nullable(),
  model: Yup.string().required()
}
export const tenantPostCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const tenantPostEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

