import React from 'react'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const RateField:React.FC<any> = ({...props})=>(
  <Field
    component={FormikBootstrapInput}
    name='rate'
    label='RATE'
    type='number'
    {...props}
  />
)