import * as Yup from "yup";

export const PasswordSchema = ()=> Yup.string().min(6).max(50).required()
export const PasswordConfirmSchema = (ref = 'password')=> {
  return Yup.string().required()
    .when(ref, {
      is: (v: any) => v && v.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref(ref)],
        {
          descriptor: { id: 'VALIDATION.PASSWORD_CONFIRM_MATCH' }
        }
      ),
    })
}