import React from 'react'
import {Search} from './Search'
import {Sort} from './Sort'
import {Filter} from './Filter'
import {PageToolbar} from '../../../../../../_metronic/layout/core'
import {AERouteAction} from '../../../../../../_custom/partials/components/AERouteActions'
import {TProperty} from '../../../../../../_custom/partials/components/Metadata'
import {NUMBER, STRING} from '../../../../../../_custom/partials/components/Filter/helpers'
import {TRole} from '../../../../../types/TRole'

type TRoleProperty = {
  property: keyof TRole
} & Omit<TProperty, 'property'>

type TRoleProperties = Array<TRoleProperty>

export const PROPERTIES: TRoleProperties = [
  {
    property: 'id',
    type: NUMBER,
    label: 'ID',
    filterable: false,
    sortable: false,
  },
  {
    property: 'name',
    type: STRING,
    label: 'NAME',
    filterable: true,
    sortable: true,
  },
  {
    property: 'priority',
    type: NUMBER,
    label: 'PRIORITY',
    filterable: true,
    sortable: true,
  },
  {
    property: 'roleKey',
    type: STRING,
    label: 'ROLE_KEY',
    filterable: false,
    sortable: false,
  }
]

export const Toolbar = () => {


  return (
    <PageToolbar>
      <div className='ms-4'>
        <Search />
      </div>
      <div className='ms-4'>
        <Sort />
      </div>
      <div className='ms-4'>
        <Filter />
      </div>
      <div className='ms-4'>
        <AERouteAction
          routeKey='ROLES.NEW'
          className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm btn-icon-primary fw-boldest text-truncate'
        />
      </div>
    </PageToolbar>
  )
}
