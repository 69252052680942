import { _reducer as adsReducer} from './slices/list'
import { _reducer as adsDeleteReducer} from './slices/delete'
import { _reducer as adsCreateReducer} from './slices/create'
import { _reducer as adsEditReducer} from './slices/edit'
import { _reducer as adsForAutocompleteReducer} from './slices/autocomplete'

export const adsReducers = {
  ...adsReducer,
  ...adsDeleteReducer,
  ...adsEditReducer,
  ...adsCreateReducer,
  ...adsForAutocompleteReducer
}

export {
  fetchDispatcher as fetchAdsForList,
  _useSelector as useAdsForList,
  actions as adsListActions
} from './slices/list'

export {
  fetchDispatcher as fetchAdsForAutocomplete,
  _useSelector as useAdsForAutocomplete,
  actions as adsForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useAdForEdit,
  fetchDispatcher as fetchAdForEdit,
  updateDispatcher as updateAd
} from './slices/edit'

export {
  useSelector as useAdsForCreate,
  createDispatcher as createAd,
  resetDispatcher as resetAdForCreate
} from './slices/create'

export {
  useSelector as useAdForDelete,
  fetchDispatcher as fetchAdForDelete,
  deleteDispatcher as deleteAd
} from './slices/delete'



