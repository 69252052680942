import React from "react";
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import {Icon} from '../../components/Icon'

export function FieldFeedbackIcon({
  tooltipContent,
  touched,
  error
}) {

  let iconProps = {
    path: '/Code/Question-circle.svg',
    variant: 'warning'
  };


  if (touched) {
    if (error) {
      iconProps.variant = 'danger'
      iconProps.path = '/Code/Error-circle.svg'
    } else {
      iconProps.variant = 'success'
      iconProps.path = '/Code/Done-circle.svg'
    }
  }


  return (
    <OverlayTrigger
      overlay={(
        <Tooltip id='fw-bold'>
          {tooltipContent}
        </Tooltip>
      )}
    >
      <Icon
        size={'2'}
        {...iconProps}
      />
    </OverlayTrigger>
  )
}
