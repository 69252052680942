import axios from "axios";
import {getFormData} from '../../_custom/helpers/AxiosHelpers'
import {toAbsoluteApiUrl} from '../../_custom/helpers/AssetHelpers'
import {TMetadata} from '../../_custom/partials/components/Metadata'
import {TEditSale, TNewSale} from '../../app/types/TSale'
import {MODULES} from '../../_custom/helpers/RoutingHelpers'

export const API_URI = toAbsoluteApiUrl(`/${MODULES.SALES.toLowerCase()}`);

/**
 * Filter all sales
 * */
export const all = (metadata:TMetadata) => {
  const {filter, join, sorts, pagination: {page, perPage}, search} = metadata
  const params = {filter, join, sorts, pagination: {page, perPage}, search}

  return axios.get(API_URI,{params});
}

/**
 * Find sale by Id
 * */
export const find = (id: number) => axios.get(`${API_URI}/${id}`);

/**
 * Delete sale by Id
 * */
export const destroy = (id: number) => axios.delete(`${API_URI}/${id}`);

/**
 * create sale
 * */
export const create = (entity:TNewSale, files:any) => {
  let object:any = entity

  object.products = entity.products.map(product=>({id: product.id}))

  return axios.post(API_URI, getFormData(object, files));
}

/**
 * Update sale
 * */
export const update = ({tenant, ...entity}:TEditSale, files:any) => {
  let object:any = entity

  object.products = entity.products.map(product=>({id: product.id}))

  return axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(entity, files));
}


