import * as Yup from 'yup'
import {Entity, TFileEntity, TPriorityEntity, TTenantEntity} from './TEntity'

/**
 * Types
 * */
export type TAd = Entity & TTenantEntity & TFileEntity & TPriorityEntity & {
  title: string,
}
export type TNewAd = Omit<TAd, 'id'>
export type TEditAd = TAd
export type TDeleteAd = TEditAd

/**
 * Default Values
 */
export const defaultAd:TAd = {
  id: 0,
  title: '',
  priority: 0,
  fileName: '',
  tenant: null
}
export const defaultNewAd:TNewAd = {
  title: '',
  priority: 0,
  fileName: '',
  tenant: null
}
export const defaultEditAd:TEditAd = {
  ...defaultNewAd,
  id: 0
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewAd, any> = {
  title: Yup.string().required(),
  priority: Yup.number().required(),
  fileName: Yup.string(),
  tenant: Yup.object().nullable(),
}
export const adCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const adEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

