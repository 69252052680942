import React from 'react'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const LastNameField = ()=>(
  <Field
    component={FormikBootstrapInput}
    name='lastName'
    label='LASTNAME'
  />
)