import {getSlice, TBaseState, useCustomSelector} from '../../helpers'
import * as API from "../api";
import {MODULES} from '../../../_custom/helpers/RoutingHelpers'
import {Dispatch} from 'redux'
import {defaultNewBlogCategory, TEditBlogCategory} from '../../../app/types/TBlogCategory'

/**
 * Create slice
 */
const {actions, name, reducer} = getSlice({
  name: `${MODULES.BLOG_CATEGORIES}.EDIT`,
  data: {
    ...defaultNewBlogCategory,
    _confirm: '',
  }
})

const {startCall, endCall, fetched, catchError, reset} = actions;

/**
 * Fetch BlogCategory
 *
 * @param id
 */
const fetchDispatcher = (id: number) => (dispatch:Dispatch) => {
  dispatch(startCall());

  return API
    .find(id)
    .then(response => { dispatch(fetched(response)) })
    .catch(response => { dispatch(catchError(response)) })
    .then(response => { dispatch(endCall(response)) })
    ;
};

/**
 * Update BlogCategory
 *
 * @param entity
 * @param files
 */
const updateDispatcher = (entity:TEditBlogCategory, files?:any) => (dispatch:Dispatch) => {
  dispatch(startCall());

  return API.update(entity, files)
    .then(response => { dispatch(fetched(response)) })
    .catch(response => { dispatch(catchError(response)) })
    .then(response => { dispatch(endCall(response)) })
};

/**
 * Get state
 */
type TState = TBaseState & {
  data: TEditBlogCategory
}
const useSelector = ():TState => useCustomSelector(name)

const _reducer = {
  [name]: reducer
}

export {
  fetchDispatcher,
  updateDispatcher,
  useSelector,
  _reducer
}
