import * as Yup from 'yup'
import {Entity, TProductEntity, TTenantEntity} from "./TEntity";

/**
 * Types
 * */
export type TBirthdayGift = Entity & TTenantEntity & TProductEntity & {
    title: string,
    points: number
}
export type TNewBirthdayGift = Omit<TBirthdayGift, 'id'>
export type TEditBirthdayGift = TBirthdayGift
export type TDeleteBirthdayGift = TEditBirthdayGift


/**
 * Default Values
 * */
export const defaultBirthdayGift: TBirthdayGift = {
    id: 0,
    title: '',
    tenant: null,
    product: null,
    points: 0,
}
export const defaultNewBirthdayGift:TNewBirthdayGift = {
    points: 0,
    title: ''
    // tenant: null
}
export const defaultEditBirthdayGift:TEditBirthdayGift = {
    ...defaultNewBirthdayGift,
    id: 0
}
/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewBirthdayGift, any> = {
    points: undefined,
    title: Yup.string().required(),
    tenant: Yup.object().nullable(),
    product: Yup.object().required(),
}
export const birthdayGiftCreateSchema = Yup.object().shape({
    ...defaultSchemaShape
})
export const birthdayGiftEditSchema = Yup.object().shape({
    ...defaultSchemaShape,
    id: Yup.number(),
})
