import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import clsx from 'clsx'
import {TTenantGroup} from '../../../types/TTenantGroup'
import {DetailLine} from '../../../../_custom/partials/components/DetailLine'
import {ICONS} from '../../../../_custom/partials/components/Icon'
import {toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getFullName} from '../../../../_custom/partials/components/FullName'

type TProps = {
  entity: TTenantGroup
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:TProps) => {

  const { user } = useAuthState()

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'TENANT_GROUPS.DELETE', params: {id: entity.id}})
    }
    if (editAction) {
      actions.push({id: 'TENANT_GROUPS.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, user, editAction, deleteAction])

  return (
    <div className={clsx(
      'card border border-2 -border-gray-300 border-hover',
      className
    )}>
      <div className='card-header flex-nowrap border-0'>
        <div className='card-title m-0'>
          <div className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
            {entity.name}
          </div>
        </div>

        <div className='card-toolbar m-0'>
          <AERouteActions actions={actions} />
        </div>
      </div>

      <div className='card-body d-flex flex-column pt-0'>
        <div className=''>
          <DetailLine
            title='RATE'
            icon={ICONS.DISCOUNT_RATE}
          >
            {entity.discountRate}
            <sup className='ps-1'>%</sup>
          </DetailLine>

          <div className='symbol-group symbol-hover'>
            {
              entity.customers.map(customer=>(
                <div
                  key={customer.id}
                  className='symbol symbol-circle symbol-35px'
                  title={getFullName(customer)}
                >
                  <img
                    src={
                      customer.fileName ?
                        toAbsoluteUploadUrl(`/customers/${customer.fileName}`) :
                        toAbsoluteUrl(`/media/avatars/blank.png`)
                    }
                    alt={` `}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}