import React from 'react'
import {FormikBootstrapInput, TFormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const UserNameField:React.FC<TFormikBootstrapInput | any>  = ({...props})=>(
  <Field
    component={FormikBootstrapInput}
    name='username'
    label='USERNAME'
    {...props}
  />
)