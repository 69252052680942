import React from 'react'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const PriorityField:React.FC<any> = ({...props})=>(
  <Field
    component={FormikBootstrapInput}
    name='priority'
    label='PRIORITY'
    type='number'
    {...props}
  />
)