import React, {LegacyRef} from 'react'
import {FormikProps} from 'formik/dist/types'
import {ContentField, FileField, TenantPostModelField, TitleField} from '../../../../controls'

type TProps = {
  formik: FormikProps<any>
  btnRef: LegacyRef<HTMLDivElement>
}

export const Form = ({formik, btnRef}:TProps) => {
  const {handleSubmit} = formik

  return (
    <div className='form form-label-right'>
      <div className='card mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='mb-3 col-md'>
              <FileField
                multiple
                name='files'
                label='FILES'
                preview
                uploadPath='/tenant_post_files'
                previewStyle={{
                  width: 200
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md'>
              <TitleField />
            </div>
            <div className='mb-3 col-md'>
              <TenantPostModelField />
            </div>
          </div>
        </div>
      </div>
      <ContentField />
      <div
        className='d-none'
        ref={btnRef}
        onClick={()=>{
          handleSubmit()
        }}
      />
    </div>
  );
}

