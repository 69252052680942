import { _reducer as tenantsReducer} from './slices/list'
import { _reducer as tenantsDeleteReducer} from './slices/delete'
import { _reducer as tenantsCreateReducer} from './slices/create'
import { _reducer as tenantsRegisterReducer} from './slices/register'
import { _reducer as tenantsEditReducer} from './slices/edit'
import { _reducer as tenantsForAutocompleteReducer} from './slices/autocomplete'

export const tenantsReducers = {
  ...tenantsReducer,
  ...tenantsDeleteReducer,
  ...tenantsEditReducer,
  ...tenantsCreateReducer,
  ...tenantsRegisterReducer,
  ...tenantsForAutocompleteReducer
}

export {
  fetchDispatcher as fetchTenantsForList,
  _useSelector as useTenantsForList,
  actions as tenantsListActions
} from './slices/list'

export {
  fetchDispatcher as fetchTenantsForAutocomplete,
  _useSelector as useTenantsForAutocomplete,
  actions as tenantsForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useTenantForEdit,
  fetchDispatcher as fetchTenantForEdit,
  updateDispatcher as updateTenant
} from './slices/edit'

export {
  useSelector as useTenantsForCreate,
  createDispatcher as createTenant,
  resetDispatcher as resetTenantForCreate
} from './slices/create'

export {
  useSelector as useTenantsForRegister,
  registerDispatcher as registerTenant,
  resetDispatcher as resetTenantForRegister
} from './slices/register'

export {
  useSelector as useTenantForDelete,
  fetchDispatcher as fetchTenantForDelete,
  deleteDispatcher as deleteTenant
} from './slices/delete'



