import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import clsx from 'clsx'
import {TProductCategory} from '../../../types/TProductCategory'
import {APP_LOGO_WITHOUT_TEXT, toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'

type TProps = {
  entity: TProductCategory
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:TProps) => {

  const { user } = useAuthState()

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'PRODUCT_CATEGORIES.DELETE', params: {id: entity.id}})
    }
    if (editAction) {
      actions.push({id: 'PRODUCT_CATEGORIES.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, user, editAction, deleteAction])


  return (
    <div className={clsx(
      'card border border-2 border-gray-300 border-hover',
      className
    )}>
      <div className='card-header flex-nowrap border-0'>
        <div className='card-title m-0'>
          <div className='symbol symbol-45px w-45px bg-light'>
            <img
              src={
                entity.fileName ?
                  toAbsoluteUploadUrl(`/product_categories/${entity.fileName}`) :
                  APP_LOGO_WITHOUT_TEXT
              }
              alt={` `}
            />
          </div>
        </div>
        <div className="card-toolbar">
          <AERouteActions actions={actions} />
        </div>
      </div>
      <div className='card-body d-flex flex-column py-0'>
        <div className='fs-5 fw-bolder text-dark mt-1 mb-7'>
          {entity.name}
        </div>
      </div>
    </div>
  )
}