import clsx from 'clsx'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useLayout, usePageData} from '../../../core'

const DefaultTitle: FC = () => {
  const {pageLoading, pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, attributes, classes} = useLayout()
  return (
    <div
      {...attributes.pageTitle}
      className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    >
      {/* begin::Title */}
      {pageTitle && (
        <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
          {pageTitle}
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3 mx-2'/>
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )}
        </h1>
      )}
      {/* end::Title */}

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            {config.pageTitle.direction === 'row' && (
              <span className='h-20px border-gray-200 border-start mx-4'/>
            )}
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                    <Link className='text-muted text-hover-primary' to={item.path}>
                      {item.title}
                    </Link>
                  ) : (
                    <span className='bullet bg-gray-200 w-5px h-2px'/>
                  )}
                </li>
              ))}
              <li className='breadcrumb-item text-dark'>{pageTitle}</li>
            </ul>
          </>
        )}


      {
        pageLoading &&
          <>
            {/*<div className='progress h-20px w-50px bg-light-primary ms-2'>*/}
            {/*  <div*/}
            {/*    className='progress-bar progress-bar-striped progress-bar-animated bg-warning w-100'*/}
            {/*    role='progressbar'*/}
            {/*    aria-valuenow={50}*/}
            {/*    aria-valuemin={0}*/}
            {/*    aria-valuemax={100}*/}
            {/*  />*/}
            {/*</div>*/}
            <span className='spinner-border spinner-border-sm text-primary align-middle ms-2'/>
          </>
      }



    </div>
  )
}

export {DefaultTitle}
