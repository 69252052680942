import React from "react";
import {Pager, TPager} from './Pager'
import {Paginator, TPaginator} from './Paginator'

type TPagination = TPager & TPaginator

export function Pagination({ pages, page, total, perPage, setPage, setPerPage, counts, pager = true}: TPagination) {

  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      {
        pager &&
        <Pager
          counts={counts}
          setPerPage={setPerPage}
          total={total}
          perPage={perPage}
        />
      }
      <Paginator
        pages={pages}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}
