import { _reducer as notificationsReducer} from './slices/list'
import { _reducer as notificationsDeleteReducer} from './slices/delete'
import { _reducer as notificationsCreateReducer} from './slices/create'
import { _reducer as notificationsEditReducer} from './slices/edit'
import { _reducer as notificationsForAutocompleteReducer} from './slices/autocomplete'

export const notificationsReducers = {
  ...notificationsReducer,
  ...notificationsDeleteReducer,
  ...notificationsEditReducer,
  ...notificationsCreateReducer,
  ...notificationsForAutocompleteReducer
}

export {
  fetchDispatcher as fetchNotificationsForList,
  _useSelector as useNotificationsForList,
  actions as notificationsListActions
} from './slices/list'

export {
  fetchDispatcher as fetchNotificationsForAutocomplete,
  _useSelector as useNotificationsForAutocomplete,
  actions as notificationsForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useNotificationForEdit,
  fetchDispatcher as fetchNotificationForEdit,
  updateDispatcher as updateNotification
} from './slices/edit'

export {
  useSelector as useNotificationsForCreate,
  createDispatcher as createNotification,
  resetDispatcher as resetNotificationForCreate
} from './slices/create'

export {
  useSelector as useNotificationForDelete,
  fetchDispatcher as fetchNotificationForDelete,
  deleteDispatcher as deleteNotification
} from './slices/delete'



