import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Formik} from 'formik';
import {fetchProductCategoryForEdit, updateProductCategory, useProductCategoryForEdit} from '../../../../../api/productCategories';
import {Form} from './Form';
import {AEButton} from '../../../../../_custom/partials/components/buttons'
import {PageLoading, PageToolbar} from '../../../../../_metronic/layout/core'
import {RouteTitle} from '../../../../../_custom/partials/components/RouteTitle'
import {RouteComponentProps} from 'react-router'
import {productCategoryEditSchema} from '../../../../types/TProductCategory'

type TParams = {
  id: string
}
type TProps = RouteComponentProps<TParams>

export const Edit = ({ history, match: { params: { id }, }}:TProps) => {
  const dispatch = useDispatch();
  const { isLoading, data, error } = useProductCategoryForEdit();

  useEffect(() => {
    dispatch(fetchProductCategoryForEdit(parseInt(id)));
  }, [id, dispatch]);

  const saveBtnRef = useRef<HTMLDivElement | null>(null)
  const saveBtnRefClick = () => {
    if (saveBtnRef && saveBtnRef.current) {
      const {current} = saveBtnRef;
      current.click()
    }
  };

  return (
    <>
      <PageLoading loading={isLoading} />
      <RouteTitle id={'PRODUCT_CATEGORIES.EDIT'}/>
      <PageToolbar>
        <div className={'ps-3'}>
          <AEButton
            size={'sm'}
            variant={'light'}
            onClick={history.goBack}
          >
            <i className='fa fa-arrow-left'/>
            <FormattedMessage id={'BACK'} />
          </AEButton>
        </div>
        <div className={'ps-3'}>
          <AEButton
            size={'sm'}
            variant={'outline'}
            fontWeight={'bolder'}
            className='btn-outline-dashed btn-outline-primary btn-active-light-primary btn-icon-primary'
            onClick={saveBtnRefClick}
          >
            <FormattedMessage id={'SAVE'} />
          </AEButton>
        </div>
      </PageToolbar>
      <Formik
        enableReinitialize
        initialErrors={error}
        initialValues={data}
        validationSchema={productCategoryEditSchema}
        onSubmit={({fileName, ...values})=>{
          dispatch(updateProductCategory(values, {fileName}))
        }}
        render={formik=>(
          <Form
            formik={formik}
            btnRef={saveBtnRef}
          />)}
      />
    </>
  );
}

