import React, {useEffect, useMemo} from "react";
import {getDefaultFilter} from "./helpers";
import {FilterProperty} from './controls/FilterProperty'
import {FilterValue} from './controls/FilterValue'
import {Field} from 'formik'
import {FilterOperator} from './controls/FilterOperator'
import {Icon, ICONS} from '../Icon'
import {FormikProps} from 'formik/dist/types'
import {TProperties} from '../Metadata'

type TProps = {
  onRemove: ()=>void
  properties: TProperties
  formik: FormikProps<any>
}

export const FilterForm = ({onRemove, properties, formik}:TProps) => {
  const {values, setValues, handleSubmit} = formik

  const property = useMemo(()=>{
    // console.log('FilterForm.properties', properties)
    const i = properties.findIndex(({property})=>property === values.property)
    if (i !== -1) {
      return properties[i]
    }

    return null
  }, [properties, values.property])


  useEffect(()=>{
    // console.log(
    //   values.property,
    //   typeId,
    //   getDefaultFilter(typeId)
    // )
    setValues({
      ...values,
      ...getDefaultFilter(property?.type) //warning
    })
  }, [values.property, property?.type])

  useEffect(()=>{
    handleSubmit(values)
  }, [values])

  return (
    <div className={'row' }>
      <div className={'col-3'}>
        <Field
          component={FilterProperty}
          name={`property`}
          properties={properties}
        />
      </div>
      <div className={'col-3'}>
        <Field
          component={FilterOperator}
          name={`operator`}
          properties={properties}
        />
      </div>
      <div className={'col-6 d-flex align-items-center'}>
        <div className='flex-grow-1'>
          <Field
            component={FilterValue}
            name={`value`}
            label={'VALUE'}
            FilterField={property?.Field}
            filterType={property?.type}
          />
        </div>
        <div className={'ps-3'}>
          <Icon
            path={ICONS.DELETE}
            size={'2'}
            className='cursor-pointer'
            variant={'danger'}
            onClick={onRemove}
          />
        </div>
      </div>
      {/*<div className='col-1 d-flex align-items-center'>*/}
      {/*  */}
      {/*</div>*/}
    </div>
  )
}