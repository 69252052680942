import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import clsx from 'clsx'
import {TSale} from '../../../types/TSale'
import {DetailLine} from '../../../../_custom/partials/components/DetailLine'
import {ICONS} from '../../../../_custom/partials/components/Icon'
import {FormattedMessage} from 'react-intl'
import {Moment} from '../../../../_custom/partials/components/Moment'
import {ProductEntityLabel} from '../../products'

type TProps = {
  entity: TSale
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:TProps) => {

  const { user } = useAuthState()

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'SALES.DELETE', params: {id: entity.id}})
    }
    if (editAction) {
      actions.push({id: 'SALES.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, user, editAction, deleteAction])

  const sliceIndex = 3;


  return (
    <div className={clsx(
      'card border border-2 -border-gray-300 border-hover',
      className
    )}>
      <div className='card-header flex-nowrap border-0'>
        <div className='card-title m-0'>
          <div className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
            {entity.name}
          </div>
        </div>

        <div className='card-toolbar m-0'>
          <AERouteActions actions={actions} />
        </div>
      </div>

      <div className='card-body d-flex flex-column pt-0'>
        <div className=''>
          <DetailLine
            title='RATE'
            icon={ICONS.DISCOUNT_RATE}
          >
            {entity.discountRate}
            <sup className='ps-1'>%</sup>

          </DetailLine>
          <DetailLine
            title='START_AT'
            icon={ICONS.CALENDAR}
            iconVariant={entity._active ? 'success' : 'danger'}
          >
            <Moment date={entity.startAt} format='LLLL'/>
          </DetailLine>

          {
            entity.endAt &&
            <DetailLine
              title='END_AT'
              icon={ICONS.CALENDAR}
              iconVariant={entity._active ? 'success' : 'danger'}
            >
              <Moment date={entity.endAt} format='LLLL'/>
            </DetailLine>
          }

          {
            entity.products.slice(0, sliceIndex).map(product=>(
              <DetailLine
                key={product.id}
              >
                <ProductEntityLabel
                  entity={product}
                />
              </DetailLine>
            ))
          }

          {
            entity.products.length > sliceIndex &&
            <div
              title={
                entity
                  .products
                  .slice(sliceIndex)
                  .map(product=>product.name)
                  .join(' - ')
              }
            >
              <DetailLine>
                <em>
                  <FormattedMessage id='AND_MORE' values={{count: entity.products.length-sliceIndex}} />
                </em>
              </DetailLine>
            </div>
          }
        </div>
      </div>
    </div>
  )
}