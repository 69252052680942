import axios from "axios";
import {getFormData} from '../../_custom/helpers/AxiosHelpers'
import {toAbsoluteApiUrl} from '../../_custom/helpers/AssetHelpers'
import {TMetadata} from '../../_custom/partials/components/Metadata'
import {TEditTenantGroup, TNewTenantGroup} from '../../app/types/TTenantGroup'
import {MODULES} from '../../_custom/helpers/RoutingHelpers'

export const API_URI = toAbsoluteApiUrl(`/${MODULES.TENANT_GROUPS.toLowerCase()}`);

/**
 * Filter all tenantGroups
 * */
export const all = (metadata:TMetadata) => {
  const {filter, join, sorts, pagination: {page, perPage}, search} = metadata
  const params = {filter, join, sorts, pagination: {page, perPage}, search}

  return axios.get(API_URI,{params});
}

/**
 * Find tenantGroup by Id
 * */
export const find = (id: number) => axios.get(`${API_URI}/${id}`);

/**
 * Delete tenantGroup by Id
 * */
export const destroy = (id: number) => axios.delete(`${API_URI}/${id}`);

/**
 * create tenantGroup
 * */
export const create = (entity:TNewTenantGroup, files:any) => {
  let object:any = entity
  object.customers = entity.customers.map(customer=>({id: customer.id}))
  if (object.tenant) {
    object.tenant = {id: object.tenant.id}
  }

  return axios.post(API_URI, getFormData(object, files));
}

/**
 * Update tenantGroup
 * */
export const update = (entity:TEditTenantGroup, files:any) => {
  let object:any = entity
  object.customers = entity.customers.map(customer=>({id: customer.id}))
  if (object.tenant) {
    object.tenant = {id: object.tenant.id}
  }

  return axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(entity, files));
}


