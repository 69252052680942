/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";

export const DropdownFilterToggler = forwardRef(({onClick, className, isActive, ...props}, ref) => {
  const variant = isActive ? 'primary' : 'light'
  return (
    <a
      ref={ref}
      className={`btn btn-${variant} btn-sm fw-bold dropdown-toggle btn-block text-truncate ${className}`}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      {...props}
    />
  );
});
