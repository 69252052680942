import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import clsx from 'clsx'
import {TAd} from '../../../types/TAd'
import {DetailLine} from '../../../../_custom/partials/components/DetailLine'
import {ICONS} from '../../../../_custom/partials/components/Icon'
import {APP_LOGO_WITHOUT_TEXT, toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'
import {Granted} from '../../../../_custom/partials/components/Granted'

type TProps = {
  entity: TAd
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:TProps) => {

  const { user } = useAuthState()

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'ADS.DELETE', params: {id: entity.id}})
    }
    if (editAction) {
      actions.push({id: 'ADS.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, user, editAction, deleteAction])


  return (
    <div className={clsx(
      'card border border-2 -border-gray-300 border-hover',
      className
    )}>

      <div className='card-body'>
        <div
          className="bgi-no-repeat bgi-size-cover rounded min-h-150px mb-5"
          style={{backgroundImage: `url(${
              entity.fileName ?
                toAbsoluteUploadUrl(`/ads/${entity.fileName}`) :
                APP_LOGO_WITHOUT_TEXT
            })`}}
        />
        {/*<div className='symbol symbol-70px symbol-2by3 h-70px bg-light me-4'>*/}
        {/*  <img*/}
        {/*    src={*/}
        {/*      entity.fileName ?*/}
        {/*        toAbsoluteUploadUrl(`/ads/${entity.fileName}`) :*/}
        {/*        APP_LOGO_WITHOUT_TEXT*/}
        {/*    }*/}
        {/*    alt={` `}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className='flex-fill'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='fs-5 fw-bolder text-dark'>
              {entity.title}
            </div>
            <AERouteActions actions={actions} />
          </div>
          <DetailLine
            title={'PRIORITY'}
            icon={ICONS.SORT}
          >
            {entity.priority}
          </DetailLine>

          {
            entity.tenant &&
            <Granted roles={['ROLE_SUPER_ADMIN']}>
              <DetailLine
                title='TENANT'
                icon={ICONS.TENANTS}
              >
                {entity.tenant.name}
              </DetailLine>
            </Granted>
          }
        </div>
      </div>
    </div>
  )
}