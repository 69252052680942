import React from 'react'
import {Field} from 'formik'
import {FormikSlateEditor, TFormikSlateEditor} from '../../_custom/partials/controls/SlateEditor/FormikSlateEditor'

export const ContentField:React.FC<TFormikSlateEditor | any> = ({...props})=>(
  <Field
    component={FormikSlateEditor}
    name='content'
    label='CONTENT'
    {...props}
  />
)