import axios from "axios";
import {getFormData} from '../../_custom/helpers/AxiosHelpers'
import {toAbsoluteApiUrl} from '../../_custom/helpers/AssetHelpers'
import {TMetadata} from '../../_custom/partials/components/Metadata'
import {TEditProduct, TNewProduct} from '../../app/types/TProduct'
import {MODULES} from '../../_custom/helpers/RoutingHelpers'

export const API_URI = toAbsoluteApiUrl(`/${MODULES.PRODUCTS.toLowerCase()}`);

/**
 * Filter all products
 * */
export const all = (metadata:TMetadata) => {
  const {filter, join, sorts, pagination: {page, perPage}, search} = metadata
  const params = {filter, join, sorts, pagination: {page, perPage}, search}

  return axios.get(API_URI,{params});
}

/**
 * Find product by Id
 * */
export const find = (id: number) => axios.get(`${API_URI}/${id}`);

/**
 * Delete product by Id
 * */
export const destroy = (id: number) => axios.delete(`${API_URI}/${id}`);

/**
 * create product
 * */
export const create = (entity:TNewProduct, files:any) => {
  let object:any = entity

  if (object.category) {
    object.category = {id: object.category.id}
  }

  return axios.post(API_URI, getFormData(object, files));
}

/**
 * Update product
 * */
export const update = ({tenant, ...entity}:TEditProduct, files:any) => {
  let object:any = entity

  if (object.category) {
    object.category = {id: object.category.id}
  }

  return axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(entity, files));
}


