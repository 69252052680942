import { _reducer as tenantGroupsReducer} from './slices/list'
import { _reducer as tenantGroupsDeleteReducer} from './slices/delete'
import { _reducer as tenantGroupsCreateReducer} from './slices/create'
import { _reducer as tenantGroupsEditReducer} from './slices/edit'
import { _reducer as tenantGroupsForAutocompleteReducer} from './slices/autocomplete'

export const tenantGroupsReducers = {
  ...tenantGroupsReducer,
  ...tenantGroupsDeleteReducer,
  ...tenantGroupsEditReducer,
  ...tenantGroupsCreateReducer,
  ...tenantGroupsForAutocompleteReducer
}

export {
  fetchDispatcher as fetchTenantGroupsForList,
  _useSelector as useTenantGroupsForList,
  actions as tenantGroupsListActions
} from './slices/list'

export {
  fetchDispatcher as fetchTenantGroupsForAutocomplete,
  _useSelector as useTenantGroupsForAutocomplete,
  actions as tenantGroupsForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useTenantGroupForEdit,
  fetchDispatcher as fetchTenantGroupForEdit,
  updateDispatcher as updateTenantGroup
} from './slices/edit'

export {
  useSelector as useTenantGroupsForCreate,
  createDispatcher as createTenantGroup,
  resetDispatcher as resetTenantGroupForCreate
} from './slices/create'

export {
  useSelector as useTenantGroupForDelete,
  fetchDispatcher as fetchTenantGroupForDelete,
  deleteDispatcher as deleteTenantGroup
} from './slices/delete'



