import React from 'react'
import {Field} from 'formik'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'

export const EndAtField:React.FC<any> = ({...props}) => (
  <Field
    component={FormikBootstrapInput}
    name='endAt'
    label='END_AT'
    type='datetime-local'
    {...props}
  />
)