import React, {ReactNode} from 'react'
import {Field} from 'formik'
import {
  BOOL_EQ,
  DATETIME_EQ,
  DATETIME_GT,
  DATETIME_GTE,
  DATETIME_LT,
  DATETIME_LTE,
  DATETIME_NEQ,
  NUMBER_EQ,
  NUMBER_GT,
  NUMBER_GTE,
  NUMBER_LT,
  NUMBER_LTE,
  NUMBER_NEQ,
  RELATION_EQ,
  STRING_CONTAINS,
  STRING_DOES_NOT_CONTAIN,
  STRING_DOES_NOT_END_WITH,
  STRING_DOES_NOT_START_WITH,
  STRING_ENDS_WITH,
  STRING_EQ,
  STRING_NEQ,
  STRING_STARTS_WITH,
  EMPTY_OPERATORS, TPropertyType,
} from '../../Metadata'
import {FormikBootstrapInput} from '../../../controls/FormikBootstrapInput'
import {FormikBootstrapCheckbox} from '../../../controls/FormikBootstrapCheckbox'

type TProps = Record<any, any> & {
  field: any
  form: any
  FilterField?: React.ComponentClass<any, any>
  filterType?: TPropertyType
}

export const FilterValue = ({FilterField, filterType, form, ...props}:TProps) => {
  const {values} = form;
  const {operator} = values;

  if (EMPTY_OPERATORS.includes(operator)) {
    return null
  }

  if (FilterField) {
    return (
      <FilterField
        size='sm'
        placeholderLabel
        validation={false}
        {...props}
      />
    )
  }

  switch (operator) {
    case STRING_EQ:
    case STRING_NEQ:
    case STRING_CONTAINS:
    case STRING_DOES_NOT_CONTAIN:
    case STRING_STARTS_WITH:
    case STRING_ENDS_WITH:
    case STRING_DOES_NOT_START_WITH:
    case STRING_DOES_NOT_END_WITH:
      return (
        <Field
          component={FormikBootstrapInput}
          size='sm'
          placeholderLabel
          validation={false}
          {...props}
        />
      )
    case NUMBER_EQ:
    case NUMBER_NEQ:
    case NUMBER_GT:
    case NUMBER_GTE:
    case NUMBER_LT:
    case NUMBER_LTE:
      return (
        <Field
          component={FormikBootstrapInput}
          type={'number'}
          size={'sm'}
          placeholderLabel
          validation={false}
          {...props}
        />
      )
    case DATETIME_EQ:
    case DATETIME_NEQ:
    case DATETIME_GT:
    case DATETIME_GTE:
    case DATETIME_LT:
    case DATETIME_LTE:
      return (
        <Field
          component={FormikBootstrapInput}
          type={filterType === 'DATETIME' ? 'datetime-local' : 'date'}
          size='sm'
          placeholderLabel
          validation={false}
          {...props}
        />
      )
    case BOOL_EQ:
      return (
        <Field
          component={FormikBootstrapCheckbox}
          {...props}
        />
      )
    // case RELATION_EQ:
    // case RELATION_NEQ:
    //   //warning Field can be null
    //   if (FilterField) {
    //     return (
    //       <FilterField
    //         {...props}
    //       />
    //     )
    //   }

  }

  return null;
}