/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";

export const DropdownCustomMenu = forwardRef(
  ({ children, style, className, 'aria-labelledby w-100': labeledBy }, ref) => {


    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {children}
      </div>
    );
  },
);
