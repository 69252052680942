import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {toast, ToastContainer} from 'react-toastify'
import {useIntl} from 'react-intl'
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  basename: string
}

export const AEAlert = ()=>{
  // const {rtl} = getConfig();

  return (
    <ToastContainer
      hideProgressBar
      toastClassName={`shadow`}
      // bodyClassName={"bg-dark"}
      // progressClassName={"bg-info"}
      // rtl={rtl}
      // className={"bg-success"}
      autoClose={2000}
    />
  )
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <AEAlert />
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
