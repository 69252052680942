import React from "react";
import {useAuthState} from '../../../api/auth'
import {TRoleKey} from '../../../app/types/TRole'
import {TUser} from '../../../app/types/TUser'

export const isGranted = (user:TUser, roles:Array<TRoleKey>) => {
  return user.role && roles.includes(user.role.roleKey)
}


type TRoleChecker = {
  roles: Array<TRoleKey>
  children: any
}
export const Granted:React.FC<TRoleChecker> = ({roles, children}) =>{
  const {user} = useAuthState();
  if (user && isGranted(user, roles)) {
    return children
  }


  return <></>
}