import React from 'react';
import {Field} from 'formik'
import {useDispatch} from 'react-redux'
import {fetchCustomersForAutocomplete, useCustomersForAutocomplete} from '../../api/customers'
import {
  FormikBootstrapAutocomplete,
  TFormikBootstrapAutocomplete,
} from '../../_custom/partials/controls/FormikBootstrapAutocomplete'
import {TCustomer} from '../types/TCustomer'
import {STRING_CONTAINS} from '../../_custom/partials/components/Metadata'
import {CustomerEntityLabel} from '../modules/customers'
import {getFullName} from '../../_custom/partials/components/FullName'
import {TProductCategory} from '../types/TProductCategory'

type TProps = TFormikBootstrapAutocomplete & {
  useId: boolean
}

export const CustomerField:React.FC<TProps> = ({useId, ...props}) => {
  const dispatch = useDispatch();
  const {metadata, data, isLoading} = useCustomersForAutocomplete()

  const HandleSearch = (search:string) => {
    dispatch(
      fetchCustomersForAutocomplete({
        ...metadata,
        filter: {
          ...metadata.filter,
          filters: [
            {
              property: 'firstName',
              operator: STRING_CONTAINS,
              value: search
            }
          ]
        }
      })
    )
  }

  if (useId) {
    props.options = data.map(customer=>customer.id);
    props.getOptionLabel = (id:number)=>{
      const _customer = data.find(customer=>customer.id === id)
      if (_customer) {
        return getFullName(_customer);
      }
      return 'NOT_FOUND'
    }
  } else {
    props.options = data;
    props.getOptionLabel = (option:TCustomer)=>option.firstName;
    props.getOptionSelected = (option:TCustomer, value:TCustomer) => option.id === value.id
  }

  return (
    <Field
      component={FormikBootstrapAutocomplete}
      name='customer'
      label='CUSTOMER'
      onOpen={()=>{
        HandleSearch('')
      }}
      onInputChange={(event:any, search:string) => {
        HandleSearch(search)
      }}
      loading={isLoading}
      renderOption={(option:TCustomer) => <CustomerEntityLabel entity={option} />}
      {...props}
    />
  )
}