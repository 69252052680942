import React from 'react'
import {FormikBootstrapInput, TFormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const TitleField:React.FC<TFormikBootstrapInput | any> = ({...props})=>(
  <Field
    component={FormikBootstrapInput}
    name='title'
    label='TITLE'
    {...props}
  />
)