import React, {useEffect} from "react";
import {Dropdown} from "react-bootstrap-v5";
import {FormattedMessage} from "react-intl";
import {getPropertyTypeOperators} from "./FilterProperty";
import {DropdownCustomToggler} from '../../dropdowns'
import {TFormikControl} from '../../../controls/FormikBootstrapInput'
import {TProperties} from '../../Metadata'

type TProps = TFormikControl & {
  className?: string
  properties: TProperties
}

export const FilterOperator = ({field, form, className = '', properties}:TProps) => {
  const {name, value} = field
  const {values, setFieldValue} = form
  const typeIndex = properties.findIndex(property=>property.property === values.property)
  const typeId = properties[typeIndex]?.type
  const operators = getPropertyTypeOperators(typeId);


  return (
    <Dropdown className={className}>
      <Dropdown.Toggle
        as={DropdownCustomToggler}
        className={'w-100'}
      >
        <FormattedMessage id={value} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {operators?.map((operator, oIndex) => (
          <Dropdown.Item
            key={oIndex}
            active={operator === value}
            onClick={() => {
              setFieldValue(name, operator);
            }}
          >
            <FormattedMessage id={operator} />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}