import React from 'react'
import {TCustomer} from '../../../types/TCustomer'
import {APP_LOGO_WITHOUT_TEXT, toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'
import {getFullName} from '../../../../_custom/partials/components/FullName'

type TProps = {
  entity: TCustomer
}

export const EntityLabel:React.FC<TProps> = ({entity}) => {

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-25px w-25px h-25px bg-light me-2'>
        <img
          src={
            entity.fileName ?
              toAbsoluteUploadUrl(`/customers/${entity.fileName}`) :
              APP_LOGO_WITHOUT_TEXT
          }
          alt={` `}
        />
      </div>
      <div className='fw-bold'>
        {getFullName(entity)}
      </div>
    </div>
  )
}