import { _reducer as tenantPostsReducer} from './slices/list'
import { _reducer as tenantPostsDeleteReducer} from './slices/delete'
import { _reducer as tenantPostsCreateReducer} from './slices/create'
import { _reducer as tenantPostsEditReducer} from './slices/edit'
import { _reducer as tenantPostsForAutocompleteReducer} from './slices/autocomplete'

export const tenantPostsReducers = {
  ...tenantPostsReducer,
  ...tenantPostsDeleteReducer,
  ...tenantPostsEditReducer,
  ...tenantPostsCreateReducer,
  ...tenantPostsForAutocompleteReducer
}

export {
  fetchDispatcher as fetchTenantPostsForList,
  _useSelector as useTenantPostsForList,
  actions as tenantPostsListActions
} from './slices/list'

export {
  fetchDispatcher as fetchTenantPostsForAutocomplete,
  _useSelector as useTenantPostsForAutocomplete,
  actions as tenantPostsForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useTenantPostForEdit,
  fetchDispatcher as fetchTenantPostForEdit,
  updateDispatcher as updateTenantPost
} from './slices/edit'

export {
  useSelector as useTenantPostsForCreate,
  createDispatcher as createTenantPost,
  resetDispatcher as resetTenantPostForCreate
} from './slices/create'

export {
  useSelector as useTenantPostForDelete,
  fetchDispatcher as fetchTenantPostForDelete,
  deleteDispatcher as deleteTenantPost
} from './slices/delete'



