import { _reducer as salesReducer} from './slices/list'
import { _reducer as salesDeleteReducer} from './slices/delete'
import { _reducer as salesCreateReducer} from './slices/create'
import { _reducer as salesEditReducer} from './slices/edit'
import { _reducer as salesForAutocompleteReducer} from './slices/autocomplete'

export const salesReducers = {
  ...salesReducer,
  ...salesDeleteReducer,
  ...salesEditReducer,
  ...salesCreateReducer,
  ...salesForAutocompleteReducer
}

export {
  fetchDispatcher as fetchSalesForList,
  _useSelector as useSalesForList,
  actions as salesListActions
} from './slices/list'

export {
  fetchDispatcher as fetchSalesForAutocomplete,
  _useSelector as useSalesForAutocomplete,
  actions as salesForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useSaleForEdit,
  fetchDispatcher as fetchSaleForEdit,
  updateDispatcher as updateSale
} from './slices/edit'

export {
  useSelector as useSalesForCreate,
  createDispatcher as createSale,
  resetDispatcher as resetSaleForCreate
} from './slices/create'

export {
  useSelector as useSaleForDelete,
  fetchDispatcher as fetchSaleForDelete,
  deleteDispatcher as deleteSale
} from './slices/delete'



