import * as Yup from 'yup'
import {Entity, TFileEntity, TTimestampCreateEntity, TUserEntity} from './TEntity'
import {UsernameSchema} from '../../_custom/yup/schemas/UsernameSchema'
import {PasswordConfirmSchema, PasswordSchema} from '../../_custom/yup/schemas/PasswordSchema'

/**
 * Types
 * */
export type TCustomer = Entity & TUserEntity & TFileEntity & TTimestampCreateEntity & {
  status: 'PENDING' | 'APPROVED'
  birthDate?: string
}
export type TNewCustomer = Omit<TCustomer, 'id' | 'createdAt' | 'fileName'>
export type TEditCustomer = Omit<TCustomer, 'createdAt'>
export type TDeleteCustomer = TEditCustomer

/**
 * Default Values
 */
export const defaultCustomer:TCustomer = {
  id: 0,
  fileName: '',
  status: 'PENDING',
  birthDate: '',
  email: '',
  firstName: '',
  lastName: '',
  username: '',
  password: ''
}
export const defaultNewCustomer:TNewCustomer = {
  status: 'PENDING',
  birthDate: '',
  email: '',
  firstName: '',
  lastName: '',
  username: '',
  password: ''
}
export const defaultEditCustomer:TEditCustomer = {
  ...defaultNewCustomer,
  id: 0
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewCustomer & {_confirm: any}, any> = {
  status: Yup.string().required(),
  birthDate: Yup.string(),
  firstName: Yup.string().required(),
  lastName: Yup.string(),
  email: Yup.string().email(),
  username: UsernameSchema(),
  password: PasswordSchema(),
  _confirm: PasswordConfirmSchema(),
}
export const customerCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const customerEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

