import React from 'react'
import {useDispatch} from 'react-redux'
import {tenantPostsListActions, useTenantPostsForList} from '../../../../../../api/tenantPosts'
import {AESearch} from '../../../../../../_custom/partials/components/Search/AESearch'

export const Search = () => {
  const dispatch = useDispatch();
  const {metadata} = useTenantPostsForList();
  const {search} = metadata
  const {setSearch} = tenantPostsListActions;

  return (
    <AESearch
      value={search}
      setSearch={(value?:string)=>{
        dispatch(setSearch(value))
      }}
    />
  )
}