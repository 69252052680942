import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import clsx from 'clsx'
import {TProduct} from '../../../types/TProduct'
import {APP_LOGO_WITHOUT_TEXT, toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'
import {DetailLine} from '../../../../_custom/partials/components/DetailLine'
import {ICONS} from '../../../../_custom/partials/components/Icon'
import {Currency} from '../../../../_custom/partials/components/Currency'

type TProps = {
  entity: TProduct
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:TProps) => {

  const { user } = useAuthState()

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'PRODUCTS.DELETE', params: {id: entity.id}})
    }
    if (editAction) {
      actions.push({id: 'PRODUCTS.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, user, editAction, deleteAction])


  return (
    <div className={clsx(
      'card border border-2 border-gray-300 border-hover',
      className
    )}>
      <div className='card-body'>
        <div className='d-flex'>
          <div className='symbol symbol-125px w-125px h-125px bg-light me-4'>
            <img
              src={
                entity.fileName ?
                  toAbsoluteUploadUrl(`/products/${entity.fileName}`) :
                  APP_LOGO_WITHOUT_TEXT
              }
              alt={` `}
            />
          </div>
          <div className='flex-fill'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='fs-5 fw-bolder text-dark'>
                {entity.name}
              </div>
              <AERouteActions actions={actions} />
            </div>
            <DetailLine
              title='PRICE'
              icon={ICONS.PRICE}
            >
              <Currency value={entity.price}/>
            </DetailLine>
            {
              entity.category &&
              <DetailLine
                title='CATEGORY'
                icon={ICONS.PRODUCT_CATEGORIES}
              >
                {entity.category.name}
              </DetailLine>
            }
          </div>
        </div>
        {/*<div className='symbol symbol-45px w-45px bg-light'>*/}
        {/*  <img*/}
        {/*    src={toAbsoluteUploadUrl(`/products/${entity.qrCodeFileName}`)}*/}
        {/*    alt={` `}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </div>
  )
}