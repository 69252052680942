import {getSlice, TBaseState, useCustomSelector} from '../../helpers'
import * as API from "../api";
import {MODULES} from '../../../_custom/helpers/RoutingHelpers'
import {Dispatch} from 'redux'
import {defaultNotification, TDeleteNotification} from '../../../app/types/TNotification'

/**
 * Create slice
 */
const {actions, name, reducer} = getSlice({
  name: `${MODULES.USER_NOTIFICATIONS}.DELETE`,
  data: defaultNotification
})

const {startCall, endCall, fetched, catchError} = actions;

/**
 * Fetch notification
 *
 * @param id
 */
const fetchDispatcher = (id: number) => (dispatch:Dispatch) => {
  dispatch(startCall());

  return API
    .find(id)
    .then(response => { dispatch(fetched(response)) })
    .catch(response => { dispatch(catchError(response)) })
    .then(response => { dispatch(endCall(response)) })
    ;
};

/**
 * Delete notification
 *
 * @param id
 */
const deleteDispatcher = (id:number) => (dispatch:Dispatch) => {
  dispatch(startCall());

  return API
    .destroy(id)
    .then(response => { dispatch(fetched(response)) })
    .catch(response => { dispatch(catchError(response)) })
    .then(response => { dispatch(endCall(response)) })
};

/**
 * Get state
 */
type TState = TBaseState & {
  data: TDeleteNotification
}
const useSelector = ():TState => useCustomSelector(name)

const _reducer = {
  [name]: reducer
}

export {
  fetchDispatcher,
  deleteDispatcher,
  useSelector,
  _reducer
}
