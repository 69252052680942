import * as Yup from 'yup'
import {Entity, TCoordinatesEntity, TCoverFileEntity, TFileEntity} from './TEntity'
import {
  tenantAttributeCreateSchema,
  defaultNewTenantAttribute,
  TEditTenantAttribute,
  TNewTenantAttribute,
  TTenantAttribute,
} from './TTenantAttribute'
import {userCreateSchema, defaultNewUser, TNewUser} from './TUser'

export type TTenant = Entity & TFileEntity & TCoverFileEntity & TCoordinatesEntity & {
  name: string
  status: 'PENDING' | 'APPROVED'
  email?: string
  phone?: string
  landline?: string
  attributes?: Array<TTenantAttribute>
}
export type TNewTenant = {
  attributes?: Array<TNewTenantAttribute>
} & Omit<TTenant, 'id' | 'fileName' | 'coverFileName' | 'attributes'>
export type TRegisterTenant = {
  // attributes?: Array<TNewTenantAttribute>,
  // users: Array<TNewUser>
} & Omit<TNewTenant, 'lng' | 'lat' | 'status' | 'landline'>
export type TEditTenant =
  {
    attributes?: Array<TNewTenantAttribute | TEditTenantAttribute>
  } & Omit<TTenant, 'fileName' | 'coverFileName' | 'attributes'>
export type TDeleteTenant = TEditTenant

export const defaultTenant:TTenant = {
  id: 0, //todo
  fileName: '',
  coverFileName: '',
  status: 'PENDING',
  name: '',
  email: '',
  landline: '',
  phone: '',
  lng: 0,
  lat: 0,
  attributes: []
}
export const defaultNewTenant:TNewTenant = {
  status: 'PENDING',
  name: '',
  email: '',
  landline: '',
  phone: '',
  lng: 0,
  lat: 0,
  attributes: [
    defaultNewTenantAttribute
  ]
}
export const defaultRegisterTenant:TRegisterTenant = {
  name: '',
  email: '',
  phone: '',
  attributes: [
    defaultNewTenantAttribute
  ],
  // users: [
  //   defaultNewUser
  // ]
}
export const defaultEditTenant:TEditTenant = {
  ...defaultNewTenant,
  id: 0
}


const defaultSchemaShape = {
  status: Yup.string().required(),
  name: Yup.string().required(),
  email: Yup.string().email(),
  landline: Yup.string(),
  phone: Yup.string(),
  lng: Yup.number(),
  lat: Yup.number(),
  attributes: Yup
    .array()
    .of(tenantAttributeCreateSchema),
}
export const tenantCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const tenantRegisterSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email(),
  phone: Yup.string(),
  attributes: Yup
    .array()
    .of(tenantAttributeCreateSchema),
  // users: Yup
  //   .array()
  //   .of(userCreateSchema),
})
export const tenantEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

