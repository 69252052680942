import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Formik} from 'formik';
import {createSale, resetSaleForCreate, useSalesForCreate} from '../../../../../api/sales'
import {AEButton} from '../../../../../_custom/partials/components/buttons'
import {PageLoading, PageToolbar} from '../../../../../_metronic/layout/core'
import {RouteTitle} from '../../../../../_custom/partials/components/RouteTitle'
import {RouteComponentProps} from 'react-router'
import {saleCreateSchema} from '../../../../types/TSale'
import {Form} from './Form'

type TProps = RouteComponentProps

export const Create = ({ history }:TProps) => {
  const dispatch = useDispatch();
  const { isLoading, data, error } = useSalesForCreate();

  const saveBtnRef = useRef<HTMLDivElement | null>(null)
  const saveBtnRefClick = () => {
    if (saveBtnRef && saveBtnRef.current) {
      const {current} = saveBtnRef;
      current.click()
    }
  };

  useEffect(()=>{
    dispatch(resetSaleForCreate())
  }, [])

  return (
    <>
      <PageLoading loading={isLoading} />
      <RouteTitle id='SALES.EDIT'/>
      <PageToolbar>
        <div className='ps-3'>
          <AEButton
            size='sm'
            variant='light'
            onClick={history.goBack}
          >
            <i className='fa fa-arrow-left'/>
            <FormattedMessage id='BACK' />
          </AEButton>
        </div>
        <div className='ps-3'>
          <AEButton
            size='sm'
            variant='outline'
            fontWeight='bolder'
            className='btn-outline-dashed btn-outline-primary btn-active-light-primary btn-icon-primary'
            onClick={saveBtnRefClick}
            data-kt-indicator={isLoading && 'on'}
          >
            <span className='indicator-label'>
              <FormattedMessage id='SAVE' />
            </span>
            <span className='indicator-progress'>
              <FormattedMessage id='PLEASE_WAIT' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'/>
            </span>
          </AEButton>
        </div>
      </PageToolbar>
      <Formik
        enableReinitialize
        initialErrors={error}
        initialValues={data}
        validationSchema={saleCreateSchema}
        onSubmit={({...values})=>{
          dispatch(createSale(values))
        }}
        render={formik=>(
          <Form
            formik={formik}
            btnRef={saveBtnRef}
          />)}
      />
    </>
  );
}

