import React from 'react'
import {Field} from 'formik'
import {
  FormikBootstrapAutocomplete,
  TFormikBootstrapAutocomplete,
} from '../../_custom/partials/controls/FormikBootstrapAutocomplete'
import {FormattedMessage, useIntl} from 'react-intl'
import {ATT_KEYS, Attribute} from '../modules/tenants/components/Attribute'
import {TAttKey} from '../types/TTenantAttribute'

type TProps = TFormikBootstrapAutocomplete & {}

export const AttKeyField:React.FC<TProps> = ({...props}) => {
  const {formatMessage} = useIntl()

  return (
    <Field
      component={FormikBootstrapAutocomplete}
      options={ATT_KEYS}
      getOptionLabel={(attKey:TAttKey)=>{
        if (! attKey) {
          return ''
        }

        return formatMessage({id: attKey})
      }}
      name='attKey'
      label='TYPE'
      renderOption={(attKey:TAttKey)=>(
        <>
          <Attribute attKey={attKey} attValue={'#'} />
          <span className='fw-bolder ps-2 text-dark-50'>
            <FormattedMessage id={attKey} />
          </span>
        </>
      )}
      {...props}
    />
  )
}