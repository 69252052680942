import {TRole} from './TRole'
import * as Yup from 'yup'
import {UsernameSchema} from '../../_custom/yup/schemas/UsernameSchema'
import {PasswordConfirmSchema, PasswordSchema} from '../../_custom/yup/schemas/PasswordSchema'
import {Entity, TFileEntity, TTenantEntity, TTimestampCreateEntity, TUserEntity} from './TEntity'

export type TUser = Entity & TUserEntity & TTimestampCreateEntity & TFileEntity & TTenantEntity & {
  role?: TRole | null
}
export type TNewUser = Omit<TUser, 'id' | 'createdAt'>
export type TEditUser = Omit<TUser, 'createdAt'>
export type TDeleteUser = TEditUser

export const defaultUser:TUser = {
  id: 0, //todo
  fileName: '',
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  createdAt: '',
  password: '',
  role: null,
  tenant: null
}
export const defaultNewUser:TNewUser = {
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  role: null,
  tenant: null
}
export const defaultEditUser:TNewUser = {
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  tenant: null
}

export const userEditSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string(),
  email: Yup.string().email(),
  username: UsernameSchema(),
  role: Yup.object().nullable(),
  tenant: Yup.object().nullable()
})
export const userCreateSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  firstName: Yup.string().required(),
  lastName: Yup.string(),
  email: Yup.string().email(),
  username: UsernameSchema(),
  password: PasswordSchema(),
  _confirm: PasswordConfirmSchema(),
  role: Yup.object().nullable(),
  tenant: Yup.object().nullable(),
})
