import React from 'react'
import {FormattedMessage} from 'react-intl'
import clsx from 'clsx'
import {TAttKey} from '../../../types/TTenantAttribute'

type TBadge = {
  variant: string
  icon: string
}
type TProps = {
  attKey: TAttKey
  attValue: string
}

const ATTRIBUTES:Record<TAttKey, TBadge> = {
  FACEBOOK: {
    variant: 'light-facebook',
    icon: 'fab fa-facebook'
  },
  INSTAGRAM: {
    variant: 'light-instagram',
    icon: 'fab fa-instagram'
  },
  WHATSAPP: {
    variant: 'light-whatsapp',
    icon: 'fab fa-whatsapp'
  },
  TWITTER: {
    variant: 'light-twitter',
    icon: 'fab fa-twitter'
  },
  YOUTUBE: {
    variant: 'light-youtube',
    icon: 'fab fa-youtube'
  },
  LINKEDIN: {
    variant: 'light-linkedin',
    icon: 'fab fa-linkedin'
  },
  TIKTOK: {
    variant: 'light-tiktok',
    icon: 'fab fa-tiktok'
  },
  TELEGRAM: {
    variant: 'light-telegram',
    icon: 'fab fa-telegram'
  },
  SIGNAL: {
    variant: 'light-signal',
    icon: 'fab fa-signal'
  },
}
export const ATT_KEYS = Object.keys(ATTRIBUTES)
export const getAttrVariant = (attKey:TAttKey) => ATTRIBUTES[attKey].variant
export const getAttrIcon = (attKey:TAttKey) => ATTRIBUTES[attKey].icon

export const Attribute:React.FC<TProps> = ({attKey, attValue}) => {
  return (
    <a
      href={attValue}
      target='_blank'
      className={
        clsx(
          'btn btn-icon btn-sm',
          `btn-${getAttrVariant(attKey)}`
        )
      }
    >
      <i className={
        clsx(
          'fs-4',
          getAttrIcon(attKey)
        )
      }/>
    </a>
  )
}