import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import clsx from 'clsx'
import {TTenantPost} from '../../../types/TTenantPost'
import {DetailLine} from '../../../../_custom/partials/components/DetailLine'
import {Icon, ICONS} from '../../../../_custom/partials/components/Icon'
import {Moment} from '../../../../_custom/partials/components/Moment'
import {PostFile} from './PostFile'
import {FormattedMessage} from 'react-intl'

type TProps = {
  entity: TTenantPost
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:TProps) => {

  const { user } = useAuthState()

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'TENANT_POSTS.DELETE', params: {id: entity.id}})
    }
    if (editAction) {
      actions.push({id: 'TENANT_POSTS.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, user, editAction, deleteAction])


  return (
    <div className={clsx(
      'card border border-2 -border-gray-300 border-hover',
      className
    )}>
      <div className='card-body d-flex flex-column'>
        {/*<div*/}
        {/*  className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px bg-light mb-2"*/}
        {/*  style={{*/}
        {/*    backgroundImage: `url(${toAbsoluteUploadUrl(`/tenant_posts/${entity.fileName}`)})`*/}
        {/*  }}*/}
        {/*/>*/}
        <div className="pb-1 fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base">
          {entity.title}
        </div>
        <div className='d-flex justify-content-between'>
          <div className='d-flex'>
            <div className='badge border-dashed bg-light-danger fs-5 fw-bolder text-dark d-flex align-items-center p-2 me-2'>
              <Icon
                variant='danger'
                path='/General/Heart.svg'
                size='4'
              />
              <span className='px-2 text-danger'>
                {entity._reactionsCount}
              </span>
            </div>
            <div className='badge border-dashed bg-light-primary fs-5 fw-bolder text-dark d-flex align-items-center p-2 me-2'>
              <Icon
                variant='primary'
                path='/Communication/Share.svg'
                size='4'
              />
              <span className='px-2 text-primary'>
                {entity._shareCount}
              </span>
            </div>

            {
              entity.model !== 'DEFAULT' &&
              <div className='badge border-dashed bg-light-info fs-5 fw-bolder text-dark d-flex align-items-center p-2 me-2'>
                <Icon
                  variant='info'
                  path='/Shopping/Sale1.svg'
                  size='4'
                />
                <span className='px-2 text-info'>
                  <FormattedMessage id={entity.model}/>
                </span>
              </div>
            }


          </div>
          <div className='pe-2'>
            <AERouteActions actions={actions} />
          </div>
        </div>
        <div className='separator pt-3'/>
        <div className=''>
          <DetailLine
            title='CREATION_DATE'
            icon={ICONS.CALENDAR}
          >
            <Moment date={entity.createdAt} format='LL'/>
          </DetailLine>
          {
            entity.updatedAt &&
            <DetailLine
              title='UPDATING_DATE'
              icon={ICONS.CALENDAR}
            >
              <Moment date={entity.updatedAt} format='LL'/>
            </DetailLine>
          }
        </div>
        {/*<div className='separator pt-3'/>*/}
        <div className='d-flex flex-wrap'>
          {
            entity.files.map(file=>
              file.fileName ?
                <div key={file.id} className='p-1'>
                  <PostFile fileName={file.fileName}/>
                </div> : ''
            )
          }
        </div>
      </div>
    </div>
  )
}