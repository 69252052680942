import axios from 'axios'
import {
  ProfilePasswordEdit,
  RegisterUser,
  UserModel,
} from '../../app/modules/auth/models/UserModel'
import {toAbsoluteApiUrl} from '../../_custom/helpers/AssetHelpers'
import {MODULES} from '../../_custom/helpers/RoutingHelpers'
import {AuthModel} from '../../app/modules/auth/models/AuthModel'
import {axiosFormDataConfigs, getFormData} from '../../_custom/helpers/AxiosHelpers'
import {TUser} from '../../app/types/TUser'
import set from 'lodash/set'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const API_URI = toAbsoluteApiUrl(`/${MODULES.AUTH.toLowerCase()}`);
export const LOGIN_URI = `${API_URI}/user/login`
export const REGISTER = toAbsoluteApiUrl(`/${MODULES.USERS.toLowerCase()}`);
export const normalize = (_entity:TUser) => {
  const {id, username, firstName, lastName, email, password, role } =  _entity;

  let result = {id, username, firstName, lastName, email, password};
  if (role) {
    set(result, 'role.id', role.id)
  }

  return result
}


export function login(username: string, password: string) {
  return axios.post(LOGIN_URI, { username, password });
}

// Server should return AuthModel
export function register(values: RegisterUser) {
  return axios.post<AuthModel>(REGISTER, values)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(`${API_URI}/password/forgot`, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(`${API_URI}/me`)
}

export const resetPassword = (token: string, data: any) => axios.post(`${API_URI}/password/reset/${token}`, data);
export const requestEmailVerificationLink = () => axios.post(`${API_URI}/request_email_verification`);
export const verifyEmail = (token: string, object: any) => axios.post(`${API_URI}/verify_email/${token}`, object);
// export const getUserByToken = () => axios.get(`${API_URI}/me`,{params : { meta : new AEMetadata({},{},{},['*','location'])}});
export const getAuthUserRoutes = () => axios.get(`${API_URI}/routes`);
export const update = (entity:TUser, files:any) => axios.post(`${API_URI}/profile?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const updatePassword = (entity:ProfilePasswordEdit) => axios.put(`${API_URI}/password`, entity);
