import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import clsx from 'clsx'
import {APP_LOGO_WITHOUT_TEXT, toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'
import {TBirthdayGift} from "../../../types/TBirthdayGift";
import {DetailLine} from "../../../../_custom/partials/components/DetailLine";
import {ICONS} from "../../../../_custom/partials/components/Icon";
import {Currency} from "../../../../_custom/partials/components/Currency";

type TProps = {
    entity: TBirthdayGift
    editAction?: boolean
    deleteAction?: boolean
    className?: string
}

export const EntityCard = ({
                               entity,
                               editAction,
                               deleteAction,
                               className
                           }: TProps) => {

    const {user} = useAuthState()

    const actions = useMemo(() => {
        let actions = [];
        if (deleteAction) {
            actions.push({id: 'BIRTHDAY_GIFTS.DELETE', params: {id: entity.id}})
        }
        if (editAction) {
            actions.push({id: 'BIRTHDAY_GIFTS.EDIT', params: {id: entity.id}})
        }

        return actions
    }, [entity, user, editAction, deleteAction])


    return (
        <div className={clsx(
            'card border border-2 border-gray-300 border-hover',
            className
        )}>
            <div className='card-body'>
                <div className='d-flex'>
                    <div className='symbol symbol-125px w-125px h-125px bg-light me-4'>
                        <img
                            src={
                                entity.product?.fileName ?
                                    toAbsoluteUploadUrl(`/products/${entity.product?.fileName}`) :
                                    APP_LOGO_WITHOUT_TEXT
                            }
                            alt={` `}
                        />
                    </div>
                    <div className='flex-fill'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='fs-4 fw-bolder text-dark'>
                                {entity.title}
                            </div>
                            <AERouteActions actions={actions}/>
                        </div>
                        <DetailLine
                            title='POINTS'
                            icon={ICONS.SALES}
                        >
                            {entity.points}
                        </DetailLine>
                    </div>
                </div>
            </div>
        </div>
    )
}