import React, {forwardRef} from 'react'
import {useRoutesForAppState} from "../../../api/auth";
import {useIntl} from "react-intl";
import {Dropdown} from 'react-bootstrap-v5'
import {Icon} from './Icon'
import {Link} from 'react-router-dom'

/// params example
// {
//   id: entity.id
// }

type TToggle = {
  className?: string
}
const Toggler = forwardRef(({className, ...props}:TToggle, ref) => {

  return (
    <>
      <button
        // @ts-ignore
        ref={ref}
        type="button"
        className="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3"
        {...props}
      >
        <Icon path={'/Layout/Layout-4-blocks-2.svg'} size={'2'}/>
      </button>
    </>
  )
});


type TAction = {
  id: string
}
type TActions = Array<TAction>
type TAERouteActions = {
  actions: TActions
}
export const AERouteActions = ({actions}: TAERouteActions) => {

  const {data: routes} = useRoutesForAppState();
  let _actions = actions.map(({id, ...action})=>({
    routeKey: id,
    ...action
  }))


  const availableActions = _actions.filter(action=>routes.findIndex(r=>r.routeKey === action.routeKey) !== -1)
  if (availableActions.length === 0) {
    return null
  }


  return (
    <Dropdown>
      <Dropdown.Toggle as={Toggler} />
      <Dropdown.Menu>
        {
          availableActions
            .map(action=>(
              <AERouteAction
                key={action.routeKey}
                {...action}
                className={'dropdown-item'}
              />
            ))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}


type TAERouteAction = {
  routeKey: string,
  params?: Record<any, any>
  className?: string
  hideIcon?: boolean
}
export const AERouteAction = ({hideIcon, routeKey, params = {}, className, ...props}:TAERouteAction) => {
  const {data: routes} = useRoutesForAppState();
  const {formatMessage} = useIntl();
  const route = routes.find(r=>r.routeKey === routeKey);

  if (route) {
    let path = route.path;

    Object.keys(params).forEach((paramKey)=>{
      path = path.replace(`:${paramKey}`, params[paramKey])
    })

    return (
      <Link
        to={path}
        className={className}
      >
        {
          !hideIcon && route.icon &&
          <Icon className={'pe-2'} path={route.icon}/>
        }

        {formatMessage({id: route.routeKey})}
      </Link>
    )

  }

  // console.error('Route Not Found', routeKey, routes)
  return null;

}