import React from "react";
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {ROUTES} from '../../../app/routing/Routes'

type TProps = {
  id: string //todo
}

export const RouteTitle = ({id}: TProps) => {
  const {formatMessage} = useIntl();

  let breadCrumbs:Array<PageLink> = []
  const route = ROUTES.find(route=>route.routeKey === id)
  if (route) {
    breadCrumbs = [
      {
        title: formatMessage({id: route.context.toUpperCase()}),
        path: route.path,
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]
  }



  return (
    <PageTitle breadcrumbs={breadCrumbs}>
      {formatMessage({id})}
    </PageTitle>
  );
}
