import React from 'react'
import {Field} from 'formik'
import {
  FormikBootstrapAutocomplete,
  TFormikBootstrapAutocomplete,
} from '../../_custom/partials/controls/FormikBootstrapAutocomplete'
import {useIntl} from 'react-intl'
import {Model, MODELS_KEYS, TModelKey} from '../modules/tenantPosts/components/Model'

type TProps = TFormikBootstrapAutocomplete & {}

export const TenantPostModelField:React.FC<TProps> = ({...props}) => {
  const {formatMessage} = useIntl()

  return (
    <Field
      component={FormikBootstrapAutocomplete}
      name='model'
      label='MODEL'
      options={MODELS_KEYS}
      getOptionLabel={(modelKey:TModelKey)=>{
        if (! modelKey) {
          return ''
        }
        return formatMessage({id: modelKey})
      }}
      renderOption={(modelKey:TModelKey)=>(<Model modelKey={modelKey} />)}
      {...props}
    />
  )
}