import React from "react";
import Dropzone, {DropzoneState} from 'react-dropzone'
import {FormattedMessage, useIntl} from "react-intl";
import get from "lodash/get";
import clsx from "clsx";
import {TFormikControl} from './FormikBootstrapInput'
import {toAbsoluteUploadUrl} from '../../helpers/AssetHelpers'
import {Icon} from '../components/Icon'
import {FileName} from '../components/AEFileDownload'
import {FieldFeedbackIcon} from './label/FieldFeedbackIcon'
import {FieldFeedbackLabel} from './label/FieldFeedbackLabel'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const SUPPORTED_IMAGE_EXTENSIONS = ['gif','jpg','jpeg','png']
const SUPPORTED_VIDEO_EXTENSIONS = ['mp4']
export const isImage = (fileName:string) => {
  const ext = fileName.split('.').pop()
  return ext ? SUPPORTED_IMAGE_EXTENSIONS.includes(ext) : false
}
export const isVideo = (fileName:string) => {
  const ext = fileName.split('.').pop()

  return ext ? SUPPORTED_VIDEO_EXTENSIONS.includes(ext) : false
}

type TFileCardProps = {
  file: any
  preview?: boolean
  uploadPath: string
  deleteHandler: ()=>void,
  previewStyle: {
    width: number,
    height: number
  }
}
const FileCard:React.FC<TFileCardProps> = ({file, preview, uploadPath, deleteHandler, previewStyle}) => {

  let url, fileName = '/media/avatars/blank.png';
  if (file) {
    switch (true) {
      case file instanceof File :
        url = URL.createObjectURL(file);
        fileName = file.name;
        break;
      case file instanceof Object :
        fileName = file.fileName;
        url = toAbsoluteUploadUrl(`${uploadPath}/${fileName}`);
        break;
      default:
        fileName = file;
        url = toAbsoluteUploadUrl(`${uploadPath}/${fileName}`);
    }
  } else {
    // url = toAbsoluteUrl('/media/avatars/blank.png');
  }

  if (! preview) {
    return (
      <div className='card border border-2 border-gray-300 border-hover mb-5'>
        <div className='card-body d-flex align-items-center p-2'>
          <div className='pe-3'>
            <FileName fileName={fileName}/>
          </div>
          <div
            className="btn btn-icon btn-light-danger w-25px h-25px"
            onClick={deleteHandler}
          >
            <i className="bi bi-x fs-2"/>
          </div>
        </div>

      </div>
    )
  }



  return (
    <div
      className='image-input image-input-outline m-3'
      data-kt-image-input='true'
      style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
    >
      {
        isImage(fileName) &&
        <div
          className='image-input-wrapper'
          style={{
            backgroundImage: `url(${url})`,
            ...previewStyle
          }}
        />
      }

      {
        isVideo(fileName) &&
        <video controls className='h-125px rounded'>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      }

      <span
        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
        data-kt-image-input-action="remove"
        onClick={deleteHandler}
      >
       <i className="bi bi-x fs-2"/>
      </span>
    </div>
  )
}


export type TFormikFile = React.FC<
  TFormikControl & TFileCardProps & {
  label?: string
  validation?: boolean
  multiple?: boolean
  accept?: string
  preview?: boolean
  uploadPath?: string
}>
export const FormikFile:TFormikFile = ({ field, form, label, accept, validation = true, multiple = false, preview = false, uploadPath = '', previewStyle}) => {
  const {name, value} = field
  const {touched, errors, setFieldValue} = form


  const {formatMessage} = useIntl()
  label = formatMessage({id:label})
  const feedbackError = get(errors, name)
  const feedbackTouched = get(touched, name)

  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles:Array<any>) => {

          if (multiple) {
            let files = acceptedFiles
            if (value) {
              files = [...files, ...value]
            }
            setFieldValue(name, files)
          } else {
            setFieldValue(name, acceptedFiles[0])
          }



        }}
        accept={accept}
        multiple={multiple}
        noKeyboard={true}>
        {({getRootProps, getInputProps, isDragActive}:DropzoneState) => {
          // isDragActive

          return (
            <div>
              <label className="form-label font-weight-bold">
                <span className={'pr-1'}>{label}</span>
                {validation && (
                  <FieldFeedbackIcon
                    error={feedbackError}
                    touched={feedbackTouched}
                    tooltipContent={(
                      <FieldFeedbackLabel
                        error={feedbackError}
                        touched={feedbackTouched}
                        label={label}
                      />
                    )}
                  />
                )}
              </label>

              <div className='bg-light rounded'>
                <div
                  className={clsx('form-control form-control-solid cursor-pointer ', isDragActive && 'border border-primary')}
                  {...getRootProps()}
                >
                  <div className={'d-flex'}>
                    <input {...getInputProps()} />
                    <div className="pe-3">
                      <Icon
                        path={'/Files/Upload.svg'}
                        variant={'primary'}
                        // size={'lg'}
                      />
                    </div>
                    <div className='text-muted'>
                      <FormattedMessage id={'DROPZONE.DESCRIPTION'} />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center flex-wrap'>
                  {
                    multiple ?
                      value.map((_file:any, index:number)=>{
                        return (
                          <FileCard
                            previewStyle={previewStyle}
                            file={_file}
                            key={index}
                            uploadPath={uploadPath}
                            preview={preview}
                            deleteHandler={()=>{
                              const newFiles = [...value]
                              newFiles.splice(index, 1)
                              setFieldValue(name, newFiles)
                            }}
                          />
                        )
                      }) :
                      value &&
                      <FileCard
                        previewStyle={previewStyle}
                        file={value}
                        uploadPath={uploadPath}
                        preview={preview}
                        deleteHandler={()=>{
                          setFieldValue(name, null)
                        }}
                      />
                  }
                </div>

              </div>
            </div>
          )
        }}
      </Dropzone>
    </>
  );
}
