import * as Yup from 'yup'
import {Entity, TTenantEntity} from './TEntity'
import {TCustomer} from './TCustomer'

/**
 * Types
 * */
export type TTenantGroup = Entity & TTenantEntity & {
  name: string,
  discountRate: number,
  customers: Array<TCustomer>
}
export type TNewTenantGroup = Omit<TTenantGroup, 'id'>
export type TEditTenantGroup = TTenantGroup
export type TDeleteTenantGroup = TEditTenantGroup

/**
 * Default Values
 */
export const defaultTenantGroup:TTenantGroup = {
  id: 0,
  name: '',
  discountRate: 0,
  tenant: null,
  customers: []
}
export const defaultNewTenantGroup:TNewTenantGroup = {
  name: '',
  discountRate: 0,
  customers: []
}
export const defaultEditTenantGroup:TEditTenantGroup = {
  ...defaultNewTenantGroup,
  id: 0
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewTenantGroup, any> = {
  name: Yup.string().required(),
  discountRate: Yup.number().min(1).required(),
  tenant: Yup.object().nullable(),
  customers: Yup.array(),
}
export const tenantGroupCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const tenantGroupEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

