import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Formik} from 'formik';
import {fetchTenantPostForEdit, updateTenantPost, useTenantPostForEdit} from '../../../../../api/tenantPosts';
import {Form} from './Form';
import {AEButton} from '../../../../../_custom/partials/components/buttons'
import {PageLoading, PageToolbar} from '../../../../../_metronic/layout/core'
import {RouteTitle} from '../../../../../_custom/partials/components/RouteTitle'
import {RouteComponentProps} from 'react-router'
import {tenantPostEditSchema} from '../../../../types/TTenantPost'

type TParams = {
  id: string
}
type TProps = RouteComponentProps<TParams>

export const Edit = ({ history, match: { params: { id }, }}:TProps) => {
  const dispatch = useDispatch();
  const { isLoading, data, error } = useTenantPostForEdit();

  useEffect(() => {
    dispatch(fetchTenantPostForEdit(parseInt(id)));
  }, [id, dispatch]);

  const saveBtnRef = useRef<HTMLDivElement | null>(null)
  const saveBtnRefClick = () => {
    if (saveBtnRef && saveBtnRef.current) {
      const {current} = saveBtnRef;
      current.click()
    }
  };

  return (
    <>
      <PageLoading loading={isLoading} />
      <RouteTitle id='TENANT_POSTS.EDIT'/>
      <PageToolbar>
        <div className='ps-3'>
          <AEButton
            size='sm'
            variant='light'
            onClick={history.goBack}
          >
            <i className='fa fa-arrow-left'/>
            <FormattedMessage id='BACK' />
          </AEButton>
        </div>
        <div className='ps-3'>
          <AEButton
            size='sm'
            variant='outline'
            fontWeight='bolder'
            className='btn-outline-dashed btn-outline-primary btn-active-light-primary btn-icon-primary'
            onClick={saveBtnRefClick}
            data-kt-indicator={isLoading && 'on'}
            disabled={isLoading || !data.id}
          >
            <span className='indicator-label'>
              <FormattedMessage id='SAVE' />
            </span>
            <span className='indicator-progress'>
              <FormattedMessage id='PLEASE_WAIT' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'/>
            </span>
          </AEButton>
        </div>
      </PageToolbar>
      <Formik
        enableReinitialize
        initialErrors={error}
        initialValues={data}
        validationSchema={tenantPostEditSchema}
        onSubmit={({...values})=>{
          const binaryFiles = values.files.filter((file:any)=>file instanceof File)
          values.files = values.files.filter((file:any)=>!(file instanceof File))

          dispatch(updateTenantPost(values, {files:binaryFiles}))
        }}
        render={formik=>(
          <Form
            formik={formik}
            btnRef={saveBtnRef}
          />)}
      />
    </>
  );
}

