/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useDispatch} from 'react-redux'
import {Field, Formik} from 'formik'
import * as Yup from 'yup'
import {register} from '../../../../api/auth/api'
import {Link, useHistory} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {FormikBootstrapInput} from '../../../../_custom/partials/controls/FormikBootstrapInput'
import {AEButton} from '../../../../_custom/partials/components/buttons'
import {RegisterUserDefault} from '../models/UserModel'
import {UsernameSchema} from '../../../../_custom/yup/schemas/UsernameSchema'
import {PasswordConfirmSchema, PasswordSchema} from '../../../../_custom/yup/schemas/PasswordSchema'


const schema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email(),
  username: UsernameSchema(),
  password: PasswordSchema(),
  _confirm: PasswordConfirmSchema()
});


export function Registration() {
  const loading = false; //todo redux
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          <FormattedMessage id='AUTH.REGISTER.TITLE'/>
        </h1>
        {/*<div className='text-gray-400 fw-bold fs-4'>*/}
        {/*  Already have an account?*/}
        {/*  <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>*/}
        {/*    <FormattedMessage id='AUTH.LOGIN.TITLE' values={{name: APP_NAME}}/>*/}
        {/*  </Link>*/}
        {/*</div>*/}
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/*<button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>*/}
      {/*  <img*/}
      {/*    alt='Logo'*/}
      {/*    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}*/}
      {/*    className='h-20px me-3'*/}
      {/*  />*/}
      {/*  Sign in with Google*/}
      {/*</button>*/}
      {/* end::Action */}

      {/*<div className='d-flex align-items-center mb-10'>*/}
      {/*  <div className='border-bottom border-gray-300 mw-50 w-100'/>*/}
      {/*  <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>*/}
      {/*  <div className='border-bottom border-gray-300 mw-50 w-100'/>*/}
      {/*</div>*/}
      <Formik
        validationSchema={schema}
        initialValues={RegisterUserDefault}
        onSubmit={(values, {setErrors})=>{
          register(values)
            .then((response) => {
              // console.log('created', response.data)
              // dispatch(auth.actions.login(token))
              history.push('/auth/login')
            })
            .catch(({response})=>{
              let errors:any = {}

              response.data.forEach((error: any)=> {
                errors[error.propertyPath] = {
                  descriptor: {
                    id: error.message
                  },
                  props: {
                    path: error.propertyPath
                  }
                }
              })
              setErrors(errors)

              // switch (response.status) {
              //   case 401:
              //     setFieldError('password', 'AUTH.VALIDATION.INVALID_LOGIN');
              // }
            })
        }}
      >
        {
          ({handleSubmit})=>{
            return (
              <div className='form w-100'>
                <div className={'row fv-row mb-5'}>
                  <div className={'col-6'}>
                    <Field
                      component={FormikBootstrapInput}
                      name={'firstName'}
                      label={'FIRSTNAME'}
                    />
                  </div>
                  <div className={'col-6'}>
                    <Field
                      component={FormikBootstrapInput}
                      name={'lastName'}
                      label={'LASTNAME'}
                    />
                  </div>
                </div>
                <div className={'fv-row mb-5'}>
                  <Field
                    component={FormikBootstrapInput}
                    name={'email'}
                    label={'EMAIL'}
                    type={'email'}
                  />
                </div>
                <div className={'fv-row mb-5'}>
                  <Field
                    component={FormikBootstrapInput}
                    name={'username'}
                    label={'USERNAME'}
                  />
                </div>
                <div className={'row fv-row mb-5'}>
                  <div className={'col-6'}>
                    <Field
                      component={FormikBootstrapInput}
                      name={'password'}
                      label={'PASSWORD'}
                      type={'password'}
                    />
                  </div>
                  <div className={'col-6'}>
                    <Field
                      component={FormikBootstrapInput}
                      name={'_confirm'}
                      label={'CONFIRM'}
                      type={'password'}
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <AEButton
                    size={'lg'}
                    className={'w-100 mb-5'}
                    onClick={handleSubmit}
                  >
                    {
                      !loading &&
                      <span className='indicator-label'>
                        <FormattedMessage id='REGISTER' />
                      </span>
                    }

                    {
                      loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <FormattedMessage id='PLEASE_WAIT' />
                          <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                        </span>
                      )}
                  </AEButton>

                  <Link
                    to={'/auth/login'}
                    className={'btn btn-lg btn-light w-100 mb-5'}
                  >
                    <span className='indicator-label'>
                      <FormattedMessage id='CANCEL' />
                    </span>
                  </Link>
                </div>
              </div>
            )
          }
        }
      </Formik>
    </>
  )
}
