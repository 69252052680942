import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/ar'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import {setLocale} from "yup";

import enMessages from './messages/en.json'
import frMessages from './messages/fr.json'
import arMessages from './messages/ar.json'

import enMetronicMessages from './../../_metronic/i18n/messages/en.json'
import frMetronicMessages from './../../_metronic/i18n/messages/fr.json'

const allMessages = {
  ar: arMessages,
  en: {...enMetronicMessages, ...enMessages},
  fr: {...frMetronicMessages, ...frMessages},
}


export const getIntlMessage = (descriptor = {}, props = {}) => ({descriptor, props})

setLocale({
  mixed:{
    default: (props:any)=>getIntlMessage({id:'VALIDATION.MIXED.INVALID'}, props),
    required: (props:any)=>getIntlMessage({id:'VALIDATION.MIXED.REQUIRED'}, props),
    oneOf: (props:any)=>getIntlMessage({id:'VALIDATION.MIXED.ONE_OF'}, props),
    notOneOf: (props:any)=>getIntlMessage({id:'VALIDATION.MIXED.NO_ONE_OF'}, props),
    notType: ({ path, type, value, originalValue, ...props }:any) =>getIntlMessage({id:!value ? "VALIDATION.MIXED.REQUIRED": "VALIDATION.MIXED.NOT_TYPE"}, props),
    defined: (props:any)=>getIntlMessage({id:'VALIDATION.MIXED.DEFINED'}, props),
  },

  number : {
    min: (props:any)=>getIntlMessage({id:"VALIDATION.NUMBER.MIN"}, props),
    max: (props:any)=>getIntlMessage({id:"VALIDATION.NUMBER.MAX"}, props),
    lessThan: (props:any)=>getIntlMessage({id:"VALIDATION.NUMBER.LESS_THAN"}, props),
    moreThan: (props:any)=>getIntlMessage({id:"VALIDATION.NUMBER.MORE_THAN"}, props),
    notEqual: (props:any)=>getIntlMessage({id:"VALIDATION.NUMBER.NOT_EQUAL"}, props),
    positive: (props:any)=>getIntlMessage({id:"VALIDATION.NUMBER.POSITIVE"}, props),
    negative: (props:any)=>getIntlMessage({id:"VALIDATION.NUMBER.NEGATIVE"}, props),
    integer: (props:any)=>getIntlMessage({id:"VALIDATION.NUMBER.INTEGER"}, props),
  },

  string : {
    length: (props:any)=>getIntlMessage({id:'VALIDATION.STRING.LENGTH'}, props),
    min: (props:any)=>getIntlMessage({id:'VALIDATION.STRING.MIN'}, props),
    max: (props:any)=>getIntlMessage({id:'VALIDATION.STRING.MAX'}, props),
    matches: (props:any)=>getIntlMessage({id:'VALIDATION.STRING.MATCHES'}, props),
    email: (props:any)=>getIntlMessage({id:'VALIDATION.STRING.EMAIL'}, props),
    url: (props:any)=>getIntlMessage({id:'VALIDATION.STRING.URL'}, props),
    uuid: (props:any)=>getIntlMessage({id:'VALIDATION.STRING.UUID'}, props),
    trim: (props:any)=>getIntlMessage({id:'VALIDATION.STRING.TRIM'}, props),
    lowercase: (props:any)=>getIntlMessage({id:'VALIDATION.STRING.LOWERCASE'}, props),
    uppercase: (props:any)=>getIntlMessage({id:'VALIDATION.STRING.UPPERCASE'}, props),
  },
  date : {
    min: (props:any)=>getIntlMessage({id:'VALIDATION.DATE.MIN'}, props),
    max: (props:any)=>getIntlMessage({id:'VALIDATION.DATE.MAX'}, props),
  },
  object : {
    noUnknown: (props:any)=>getIntlMessage({id:'VALIDATION.OBJECT.NO_UNKNOWN'}, props),
  },
  array : {
    min:  (props:any)=>getIntlMessage({id:'VALIDATION.ARRAY.MIN'}, props),
    max:  (props:any)=>getIntlMessage({id:'VALIDATION.ARRAY.MAX'}, props),
  }
});


const I18nProvider: FC = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
