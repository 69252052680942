import React, {Suspense, lazy, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {fetchRoutesForApp, useRoutesForAppState} from '../../api/auth'
import {useDispatch} from 'react-redux'
import {TAppRoute} from './Routes'

export function PrivateRoutes() {
  const dispatch = useDispatch()
  const {data: routes, isLoading} = useRoutesForAppState();

  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  useEffect(()=>{
    dispatch(fetchRoutesForApp())
  }, [])

  if (routes.length === 0 || isLoading) return <div> LOADING... </div>

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {
          routes
            .map((route:TAppRoute, i: number)=> {
              return (
                <Route
                  key={route.routeKey}
                  {...route}
                />
              )
            })
        }


        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
