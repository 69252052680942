import {createSlice} from "@reduxjs/toolkit";
import {shallowEqual, useSelector} from "react-redux";
import {HTTP_NOT_ACCEPTABLE} from '../_custom/helpers/AxiosHelpers'
import { TMetadata } from '../_custom/partials/components/Metadata'

type ValidationErrorType = {
  message: string
  propertyPath: string
}

type TProps = {
  name: string
  metadata?: TMetadata
  data: any
}

type TSlice = {
  name: string,
  actions: any
  reducer: any
}


export type TBaseState = {
  initialData: any
  data: any
  error: any
  isLoading: boolean
  metadata?: TMetadata
}

export const getSlice = ({name, metadata, data}:TProps):TSlice => {
  const isArray = data instanceof Array;

  const initialState: TBaseState = {
    initialData: data,
    data,
    isLoading: false,
    error: null,
    metadata
  }


  return createSlice({
    name,
    initialState,
    reducers: {
      startCall: (state) => {
        state.error = null;
        state.isLoading = true;
      },
      endCall: (state) => {
        state.isLoading = false;
      },
      catchError: (state, action) => {
        const {payload} = action
        const {response} = payload
        const data: Array<ValidationErrorType> = response.data;
        switch (response.status) {
          case HTTP_NOT_ACCEPTABLE:
            state.error = {};
            data.forEach(({message, propertyPath})=>{
              state.error[propertyPath] = {
                descriptor : {id: message},
                props: {path: propertyPath}
              }
            });
            break;
        }
      },
      reset: (state, action) => {
        state.data = data;
      },
      fetched: (state:TBaseState, action) => {
        const {entities, meta} = action.payload.data;
        state.error = {};
        state.data = entities ? entities : action.payload.data;

        switch (true) {
          case isArray:
            if (state.metadata?.pagination && meta) {
              const {total, pages, page} = meta.pagination;
              state.metadata.pagination.total = total;
              state.metadata.pagination.pages = pages;
              state.metadata.pagination.page = page;
            }
            break;
        }
      },
      setPage:(state, {payload: page}) => {
        if (state.metadata?.pagination.page) {
          state.metadata.pagination.page = page
        }
      },
      setPerPage:(state, {payload: perPage}) => {
        if (state.metadata?.pagination.perPage) {
          state.metadata.pagination.perPage = perPage
        }
      },
      setSorts:(state, {payload: sorts}) => {
        if (state.metadata?.sorts) {
          state.metadata.sorts = sorts
        }
      },
      setFilter:(state, {payload: filter}) => {
        if (state.metadata?.filter) {
          state.metadata.filter = filter
        }
      },
      setSearch:(state, {payload: search}) => {
        // if (state.metadata && state.metadata.search) {
        //   state.metadata.search = search
        // }

        // @ts-ignore
        state.metadata.search = search
      }
    }
  });
}


export const useCustomSelector = (sliceName:string): TBaseState => {
  return useSelector((selector:any)=>selector[sliceName], shallowEqual)
}
