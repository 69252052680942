import React from 'react'
import {Dropdown} from 'react-bootstrap-v5'
import {FormattedMessage} from 'react-intl'
import {
  BOOL_EQ,
  DATETIME_EQ,
  DATETIME_GT,
  DATETIME_GTE,
  DATETIME_IS_NOT_NULL,
  DATETIME_IS_NULL,
  DATETIME_LT,
  DATETIME_LTE,
  DATETIME_NEQ,
  NUMBER_EQ,
  NUMBER_GT,
  NUMBER_GTE,
  NUMBER_IS_NOT_NULL,
  NUMBER_IS_NULL,
  NUMBER_LT,
  NUMBER_LTE,
  NUMBER_NEQ,
  RELATION_EQ,
  RELATION_IS_NOT_NULL,
  RELATION_IS_NULL,
  RELATION_NEQ,
  STRING_CONTAINS,
  STRING_DOES_NOT_CONTAIN,
  STRING_DOES_NOT_END_WITH,
  STRING_DOES_NOT_START_WITH,
  STRING_ENDS_WITH,
  STRING_EQ,
  STRING_IS_NOT_NULL,
  STRING_IS_NULL,
  STRING_NEQ,
  STRING_STARTS_WITH,
  TProperties,
} from '../../Metadata'
import {AUTOCOMPLETE, BOOL, DATE, DATETIME, NUMBER, STRING, TFilterPropertyType} from '../helpers'
import {DropdownCustomToggler} from '../../dropdowns'

export const getPropertyTypeOperators = (id?:TFilterPropertyType) => {

  switch (id) {
    case STRING:
      return [
        STRING_EQ,
        STRING_NEQ,
        STRING_IS_NULL,
        STRING_IS_NOT_NULL,
        STRING_CONTAINS,
        STRING_DOES_NOT_CONTAIN,
        STRING_STARTS_WITH,
        STRING_ENDS_WITH,
        STRING_DOES_NOT_START_WITH,
        STRING_DOES_NOT_END_WITH
      ]
    case NUMBER:
      return [
        NUMBER_EQ,
        NUMBER_NEQ,
        NUMBER_IS_NULL,
        NUMBER_IS_NOT_NULL,
        NUMBER_GT,
        NUMBER_GTE,
        NUMBER_LT,
        NUMBER_LTE
      ]
    case DATE:
    case DATETIME:
      return [
        DATETIME_EQ,
        DATETIME_NEQ,
        DATETIME_IS_NULL,
        DATETIME_IS_NOT_NULL,
        DATETIME_GT,
        DATETIME_GTE,
        DATETIME_LT,
        DATETIME_LTE
      ]
    case BOOL:
      return [
        BOOL_EQ
      ]
    case AUTOCOMPLETE:
      return [
        RELATION_EQ,
        RELATION_NEQ,
        RELATION_IS_NULL,
        RELATION_IS_NOT_NULL,
      ]

  }

  console.error('Filter Id Not Found')
}

type TProps = {
  field: any
  form: any
  className?: string
  properties: TProperties
}

export const FilterProperty = ({field, form, properties}: TProps) => {
  const {name, value} = field
  const {setFieldValue} = form

  const selectedProperty = properties.find(f=>f.property === value)
  const label = selectedProperty?.label || "SELECT_PROPERTY"

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={DropdownCustomToggler}
        className={'w-100'}
      >
        <FormattedMessage id={label} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {properties.map(({property, label, type}) => (
          <Dropdown.Item
            key={property}
            active={property === value}
            onClick={()=>{
              setFieldValue(name, property)
            }}
          >
            <FormattedMessage id={label} />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
