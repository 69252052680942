import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {adsListActions, useAdsForList} from '../../../../../../api/ads'
import {Icon, ICONS} from '../../../../../../_custom/partials/components/Icon'
import {FormattedMessage} from 'react-intl'
import {DropdownFilterToggler} from '../../../../../../_custom/partials/components/dropdowns'
import {DropdownCustomMenu} from '../../../../../../_custom/partials/components/dropdowns/DropdownCustomMenu'
import {Dropdown} from 'react-bootstrap-v5'
import {AESort} from '../../../../../../_custom/partials/components/Sort/AESort'
import {PROPERTIES} from './Toolbar'

export const Sort = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false)
  const {metadata:{sorts}} = useAdsForList();
  const {setSorts} = adsListActions;

  return (
    <Dropdown
      show={show}
    >
      <Dropdown.Toggle
        as={DropdownFilterToggler}
        onClick={()=>{
          setShow(!show)
        }}
      >
        <Icon path={ICONS.SORT} />
        <span className='d-xs-none' >
          <FormattedMessage id='SORT' />
        </span>

        {
          sorts.length > 0 &&
          <span className='position-absolute top-0 start-100 translate-middle badge badge-circle badge-sm badge-primary' >
            {sorts.length}
          </span>
        }
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={DropdownCustomMenu}
        className='w-sm-400px w-300px py-0'
        // className='w-sm-100 w-md-75'
      >
        <AESort
          values={{sorts}}
          setSorts={(values:any)=>{
            dispatch(setSorts(values))
          }}
          properties={PROPERTIES.filter(({sortable})=>sortable)}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}