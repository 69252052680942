import React from "react";
import Autocomplete, {AutocompleteProps} from '@material-ui/lab/Autocomplete'
import {BootstrapInput, FormikBootstrapInput, TBootstrapInput} from './FormikBootstrapInput'
import {BootstrapCheckbox} from "./FormikBootstrapCheckbox";
import get from "lodash/get";
import {FieldAttributes} from 'formik/dist/Field'
import clsx from 'clsx'


export type TAutocomplete = typeof Autocomplete
export type TFormikBootstrapAutocomplete = TAutocomplete & FieldAttributes<any> & TBootstrapInput & {

}

export function FormikBootstrapAutocomplete({placeholderLabel, field, form, label, getOptionLabel, validation = true, size= 'md', disabled, options = [], multiple, loading = true, ...props }:TFormikBootstrapAutocomplete)
{
  const {name, value} = field
  const { touched, errors, setFieldValue, setFieldTouched } = form

  return (
    <Autocomplete
      name={name}
      fullWidth multiple={multiple}
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      // getOptionSelected={(option:any, _value:any) => {
      //   console.log(option, _value)
      //   return option.id === _value.id
      // }}
      onChange={(_,_value,reason) => {
        setFieldValue(name, reason === 'clear' ? null : _value);
      }}
      onBlur={() => setFieldTouched(name,true)}
      disabled={disabled}
      loading={loading}
      disableCloseOnSelect={multiple}
      // renderOption={(option:Record<any, any>, { selected }) => (
      //   <>
      //     {
      //       multiple &&
      //       <BootstrapCheckbox
      //         size={size}
      //         // FIXME controlled state lost after refresh option
      //         checked={selected}
      //         // checked={value.findIndex((o:any)=>getOptionLabel(o) === getOptionLabel(option)) !== -1}
      //         readOnly
      //         validation={false}
      //       />
      //     }
      //
      //     <div className={'pe-2'}>{getOptionLabel(option)}</div>
      //   </>
      // )}
      // renderGroup={(option) => (
      //   <>
      //     <div className='fw-bolder'>
      //       {option.group}
      //     </div>
      //     {option.children}
      //   </>
      // )}
      {...props}
      renderInput={({...params}) => {

        return (
          <div ref={params.InputProps.ref}>
            <BootstrapInput
              label={label}
              feedbackError={get(errors, name)}
              feedbackTouched={get(touched, name)}
              onBlur={() => {{
                setFieldTouched(name, true)
              }}}
              size={size}
              validation={validation}
              placeholderLabel={placeholderLabel}
              // {...params.inputProps}
              placeholder={multiple ? value.map(getOptionLabel).join(' - ') : ''}
              // ref={params.InputProps.ref}

              {...params.inputProps}
            />
          </div>
        )
      }}
    />
  );
}
