import {getSlice, TBaseState, useCustomSelector} from '../../helpers'
import * as API from "../api";
import {MODULES} from '../../../_custom/helpers/RoutingHelpers'
import {Dispatch} from 'redux'
import {defaultNewBlogPost, TNewBlogPost} from '../../../app/types/TBlogPost'

/**
 * Create slice
 */
const {actions, name, reducer} = getSlice({
  name: `${MODULES.BLOG_POSTS}.CREATE`,
  data: defaultNewBlogPost
})

const {startCall, endCall, catchError, reset} = actions;

/**
 * Reset state
 */
const resetDispatcher = () => (dispatch:Dispatch) => {
  dispatch(reset());
}

/**
 * Create blogPost
 * @param entity
 * @param files
 */
const createDispatcher = (entity:TNewBlogPost, files?:any) => (dispatch:Dispatch) => {
  dispatch(startCall());

  return API.create(entity, files)
    // .then(response => { dispatch(fetched(response)) })
    .catch(response => { dispatch(catchError(response)) })
    .then(response => { dispatch(endCall(response)) })
};

/**
 * Get state
 */
type TState = TBaseState & {
  data: TNewBlogPost
}
const useSelector = ():TState => useCustomSelector(name)


const _reducer = {
  [name]: reducer
}

export {
  createDispatcher,
  resetDispatcher,
  useSelector,
  _reducer
}
