import { _reducer as productCategoriesReducer} from './slices/list'
import { _reducer as productCategoriesDeleteReducer} from './slices/delete'
import { _reducer as productCategoriesCreateReducer} from './slices/create'
import { _reducer as productCategoriesEditReducer} from './slices/edit'
import { _reducer as productCategoriesForAutocompleteReducer} from './slices/autocomplete'

export const productCategoriesReducers = {
  ...productCategoriesReducer,
  ...productCategoriesDeleteReducer,
  ...productCategoriesEditReducer,
  ...productCategoriesCreateReducer,
  ...productCategoriesForAutocompleteReducer
}

export {
  fetchDispatcher as fetchProductCategoriesForList,
  _useSelector as useProductCategoriesForList,
  actions as productCategoriesListActions
} from './slices/list'

export {
  fetchDispatcher as fetchProductCategoriesForAutocomplete,
  _useSelector as useProductCategoriesForAutocomplete,
  actions as productCategoriesForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useProductCategoryForEdit,
  fetchDispatcher as fetchProductCategoryForEdit,
  updateDispatcher as updateProductCategory
} from './slices/edit'

export {
  useSelector as useProductCategoriesForCreate,
  createDispatcher as createProductCategory,
  resetDispatcher as resetProductCategoryForCreate
} from './slices/create'

export {
  useSelector as useProductCategoryForDelete,
  fetchDispatcher as fetchProductCategoryForDelete,
  deleteDispatcher as deleteProductCategory
} from './slices/delete'



