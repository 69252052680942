import React from "react";
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import {Icon} from './Icon'


export const AEButton = ({
                           type= 'button', variant = 'primary', round = false,  size = 'md',  block= false, active= false, shadow = false, fontWeight = 'bold',
                           icon = false,
                           variantHover = '', children = null,
                           className = '',
                           ...props
                         }) =>{
  // const {
  //   type='button', variant = 'primary', round = 'pill',  size,  block=false, active=false, shadow = false, fontWeight = 'normal',
  //   icon = false,
  //   variantHover, className = '', children,
  // } = props;
  className = `btn btn-${variant} fw-${fontWeight} ${className}`


  if(variantHover) className += ` btn-hover-${variantHover}`;
  if(size) className += ` btn-${size}`;
  if(block) className += ` btn-block`;
  if(icon) className += ` btn-icon`;
  if(round) className += ` btn-${round}`;
  if(active) className += ` active`;
  if(shadow) className += ` shadow`;


  // console.log(props)
  return (
    <button
      type={type}
      className={className}
      onMouseDown={props.onMouseDown}
      onClick={props.onClick}
      disabled={props.disabled}
      {...props}
    >
      {children}
    </button>
  )
}
export const AEIconButton = ({title, variant = 'light-primary', onClick, icon}) => {
  return (
    <OverlayTrigger
      overlay={(
        <Tooltip id='ae-icon-button'>
          {title}
        </Tooltip>
      )}
    >
      <AEButton
        variant={variant}
        size={'sm'}
        icon
        className={'mx-1'}
        onClick={onClick}
      >
        <Icon path={icon} size={'1x'}/>
      </AEButton>
    </OverlayTrigger>
  )
}