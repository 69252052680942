import React from 'react'
import {Field} from 'formik'
import {
  FormikBootstrapAutocomplete,
  TFormikBootstrapAutocomplete,
} from '../../_custom/partials/controls/FormikBootstrapAutocomplete'
import {Status, STATUSES_KEYS, TStatusKey} from '../modules/tenants/components/Status'
import {useIntl} from 'react-intl'

type TProps = TFormikBootstrapAutocomplete & {}

export const TenantStatusField:React.FC<TProps> = ({...props}) => {
  const {formatMessage} = useIntl()

  return (
    <Field
      component={FormikBootstrapAutocomplete}
      name='status'
      label='STATUS'
      options={STATUSES_KEYS}
      getOptionLabel={(statusKey:TStatusKey)=>{
        if (! statusKey) {
          return ''
        }
        return formatMessage({id: statusKey})
      }}
      renderOption={(statusKey:TStatusKey)=>(<Status statusKey={statusKey} />)}
      {...props}
    />
  )
}