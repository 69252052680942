import * as Yup from 'yup'
import {Entity, TTenantEntity} from './TEntity'

/**
 * Types
 * */
export type TAttKey = 'FACEBOOK' | 'WHATSAPP' | 'INSTAGRAM' | 'TWITTER' | 'YOUTUBE' | 'LINKEDIN' | 'TIKTOK' | 'TELEGRAM' | 'SIGNAL'
export type TTenantAttribute = Entity & TTenantEntity & {
  attKey: TAttKey
  attValue: string,
}
export type TNewTenantAttribute = Omit<TTenantAttribute, 'id'>
export type TEditTenantAttribute = TTenantAttribute
export type TDeleteTenantAttribute = TEditTenantAttribute

/**
 * Default Values
 */
export const defaultTenantAttribute:TTenantAttribute = {
  id: 0,
  attKey: 'FACEBOOK',
  attValue: '',
}
export const defaultNewTenantAttribute:TNewTenantAttribute = {
  attKey: 'FACEBOOK',
  attValue: '',
}
export const defaultEditTenantAttribute:TEditTenantAttribute = {
  ...defaultNewTenantAttribute,
  id: 0
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewTenantAttribute, any> = {
  attKey: Yup.string().required(),
  attValue: Yup.string().required(),
  tenant: Yup.object().nullable()
}
export const tenantAttributeCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const tenantAttributeEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

