import { _reducer as blogPostsReducer} from './slices/list'
import { _reducer as blogPostsDeleteReducer} from './slices/delete'
import { _reducer as blogPostsCreateReducer} from './slices/create'
import { _reducer as blogPostsEditReducer} from './slices/edit'
import { _reducer as blogPostsForAutocompleteReducer} from './slices/autocomplete'

export const blogPostsReducers = {
  ...blogPostsReducer,
  ...blogPostsDeleteReducer,
  ...blogPostsEditReducer,
  ...blogPostsCreateReducer,
  ...blogPostsForAutocompleteReducer
}

export {
  fetchDispatcher as fetchBlogPostsForList,
  _useSelector as useBlogPostsForList,
  actions as blogPostsListActions
} from './slices/list'

export {
  fetchDispatcher as fetchBlogPostsForAutocomplete,
  _useSelector as useBlogPostsForAutocomplete,
  actions as blogPostsForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useBlogPostForEdit,
  fetchDispatcher as fetchBlogPostForEdit,
  updateDispatcher as updateBlogPost
} from './slices/edit'

export {
  useSelector as useBlogPostsForCreate,
  createDispatcher as createBlogPost,
  resetDispatcher as resetBlogPostForCreate
} from './slices/create'

export {
  useSelector as useBlogPostForDelete,
  fetchDispatcher as fetchBlogPostForDelete,
  deleteDispatcher as deleteBlogPost
} from './slices/delete'



