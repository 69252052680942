import {TRole} from '../../../types/TRole'
import * as Yup from 'yup'
import {UsernameSchema} from '../../../../_custom/yup/schemas/UsernameSchema'
import {PasswordConfirmSchema, PasswordSchema} from '../../../../_custom/yup/schemas/PasswordSchema'
import {dev} from '../../../../_custom/helpers/env'
import {TUser} from '../../../types/TUser'

export type UserModel = TUser

export interface RegisterUser {
  username: string
  email?: string
  firstName: string
  lastName?: string,
  password: string,
  _confirm?: string
}

const RegisterUserSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string(),
  username: UsernameSchema(),
  password: PasswordSchema(),
  _confirm: PasswordConfirmSchema()
});

export const RegisterUserDefault:RegisterUser = {
  firstName: dev('tezst', ''),
  lastName: dev('test', ''),
  email: dev('test@mail.com', ''),
  username: dev('test', ''),
  password: dev('azerty', ''),
  _confirm: dev('azerty', ''),
}

export interface ProfileSettings {
  email?: string
  firstName: string
  lastName?: string
}

export const DefaultProfileSettings:ProfileSettings = {
  firstName: '',
  lastName: '',
  email: ''
}

export interface ProfilePasswordEdit {
  current: string,
  password: string
  _password_confirm: string
}

export const DefaultProfilePassword:ProfilePasswordEdit = {
  current: dev('azerty', ''),
  password: dev('azerty', ''),
  _password_confirm: dev('azerty', ''),
}