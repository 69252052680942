import React, {LegacyRef, useEffect} from 'react'
import {FormikProps} from 'formik/dist/types'
import {
  AttKeyField, AttValueField,
  EmailField, FileField, FirstNameField,
  LandlineField, LatField, LngField,
  NameField, PasswordConfirmField, PasswordField,
  PhoneField, TenantStatusField, UserNameField,
} from '../../../../controls'
import {FieldArray} from 'formik'
import {defaultNewTenantAttribute, TTenantAttribute} from '../../../../types/TTenantAttribute'
import {FormattedMessage} from 'react-intl'
import {AEButton} from '../../../../../_custom/partials/components/buttons'

type TProps = {
  formik: FormikProps<any>
  btnRef: LegacyRef<HTMLDivElement>
}

export const Form = ({formik, btnRef}:TProps) => {
  const {errors, values, handleSubmit, setFieldValue} = formik
  const attributes:Array<TTenantAttribute> = formik.values.attributes

  // useEffect(()=>{
  //   setFieldValue('users.0.email', values.email)
  // }, [values.email])

  // console.log(errors, values)

  return (
    <div className='form form-label-right'>
      <div className='row'>
        <div className='mb-3 col-md'>
          <NameField />
        </div>
      </div>
      {/*<div className='row'>*/}
      {/*  <div className='mb-3 col-md'>*/}
      {/*    <FirstNameField*/}
      {/*      name='users.0.firstName'*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className='mb-3 col-md'>*/}
      {/*    <UserNameField*/}
      {/*      name='users.0.username'*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className='row'>
        <div className='mb-3 col-md'>
          <EmailField />
        </div>
      </div>
      <div className='row'>
        <div className='mb-3 col-md'>
          <PhoneField />
        </div>
      </div>
      {/*<div className='row'>*/}
      {/*  <div className='mb-3 col-md'>*/}
      {/*    <PasswordField*/}
      {/*      name='users.0.password'*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className='mb-3 col-md'>*/}
      {/*    <PasswordConfirmField*/}
      {/*      name='users.0._confirm'*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <FieldArray
        name='attributes'
        render={({push, remove}) => {
      
          return (
            <>
              <div className=''>
                <div className='d-flex justify-content-between py-5'>
                  <h3 className='fw-bolder'>
                    <FormattedMessage id='ATTRIBUTES' />
                  </h3>
                  <AEButton
                    size='sm'
                    variant='light-primary'
                    onClick={()=>{
                      push(defaultNewTenantAttribute)
                    }}
                  >
                    <FormattedMessage id='ADD'/>
                  </AEButton>
                </div>
                <div className=''>
                  {
                    attributes.map((attribute:TTenantAttribute, index:number)=>{
                      return (
                        <div key={index}>
                          <div className='row align-items-end'>
                            <div className='mb-3 col-md'>
                              <AttKeyField
                                size='sm'
                                name={`attributes.${index}.attKey`}
                              />
                            </div>
                            <div className='mb-3 col-auto'>
                              <AEButton
                                size='sm'
                                variant='light-danger'
                                onClick={()=>{
                                  remove(index)
                                }}
                              >
                                <FormattedMessage id='REMOVE'/>
                              </AEButton>
                            </div>
      
                          </div>
                          <div className=''>
                            <div className='mb-3 col-auto'>
                              <AttValueField
                                size='sm'
                                name={`attributes.${index}.attValue`}
                              />
                            </div>
                          </div>
                          {
                            index+1 < attributes.length &&
                            <div className='separator separator-dashed mb-4'/>
                          }
      
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </>
          )
        }}
      />





      <div
        className={'d-none'}
        ref={btnRef}
        onClick={()=>{
          handleSubmit()
        }}
      />
    </div>
  );
}

