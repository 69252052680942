import React, {LegacyRef} from 'react'
import {FormikProps} from 'formik/dist/types'
import {Form as CreateForm} from '../create/Form'

type TProps = {
  formik: FormikProps<any>
  btnRef: LegacyRef<HTMLDivElement>
}

export const Form = ({formik, btnRef}:TProps) => {
  return (
    <CreateForm formik={formik} btnRef={btnRef} />
  )
}

