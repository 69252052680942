import React from 'react'
import {FormattedMessage} from 'react-intl'
import clsx from 'clsx'

export type TStatusKey = 'PENDING' | 'APPROVED'
type TBadge = {
  variant: string
}
type TProps = {
  statusKey: TStatusKey
}

const STATUSES:Record<TStatusKey, TBadge> = {
  PENDING: {
    variant: 'warning'
  },
  APPROVED: {
    variant: 'light-success'
  }
}
export const STATUSES_KEYS = Object.keys(STATUSES)
export const getStatusVariant = (statusKey:TStatusKey) => STATUSES[statusKey].variant

export const Status:React.FC<TProps> = ({statusKey}) => {
  return (
    <span className={clsx(
      'badge',
      `badge-${getStatusVariant(statusKey)}`
    )}>
      <FormattedMessage id={statusKey}/>
    </span>
  )
}