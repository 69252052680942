import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {ASC, SortDirection} from './controls/SortDirection'
import {Field, FieldArray} from 'formik'
import {FilterProperty} from '../Filter/controls/FilterProperty'
import {AEButton} from '../buttons'
import {Icon, ICONS} from '../Icon'
import {FormikProps} from 'formik/dist/types'
import {SortType, TProperties, TSorts} from '../Metadata'

type TProps = {
  setSorts: (values:TSorts)=>void
  properties: TProperties
  formik: FormikProps<any>
}


export const Form = ({properties, setSorts, formik} :TProps) => {
  const {values, setValues, setFieldValue, handleReset} = formik
  useEffect(() => {
    setSorts(values.sorts)
  }, [values])



  return (
    <>
      <div className='d-flex justify-content-between px-4 py-4'>
        <div className='fs-4 text-dark fw-bolder d-flex align-self-center'>
          <FormattedMessage id={'SORTS'} />
        </div>
        <div className='d-flex justify-content-end'>
          <AEButton
            className={'me-2'}
            variant={'light'}
            size={'sm'}
            onClick={handleReset}
          >
            <Icon path={ICONS.RESET} size={'lg'} />
            <FormattedMessage id={'RESET'}/>
          </AEButton>

          <AEButton
            variant={'light-primary'}
            size={'sm'}
            onClick={() => {
              const {property} = properties[0]
              setFieldValue(
                `sorts.${values.sorts.length}`,
                {
                  direction: ASC,
                  property: property,
                }
              )
            }}
          >
            <Icon path={ICONS.PLUS} size={'lg'} />
            <FormattedMessage id={'ADD_SORT'}/>
          </AEButton>


        </div>
      </div>

      <div className='separator border-gray-200'/>

      <FieldArray
        name={'sorts'}
      >
        {(helpers) => {

          return values.sorts.map((sort:SortType, index:number) => {
            return (
              <div key={index} className={'row align-items-center w-100 my-3 mx-0'}>
                <div className={'d-flex justify-content-center align-items-center col-2 text-center'}>
                  <FormattedMessage id={index === 0 ? 'SORT_BY' : 'THEN'} />
                </div>
                <div className={'col-5'}>
                  <Field
                    component={FilterProperty}
                    name={`sorts.${index}.property`}
                    properties={properties}
                  />
                </div>
                <div className={'col-5 d-flex align-items-center'}>
                  <div className='flex-grow-1'>
                    <Field
                      component={SortDirection}
                      size={'sm'}
                      placeholderLabel
                      validation={false}
                      name={`sorts.${index}.direction`}
                    />
                  </div>
                  <div className={'ps-3'}>
                    <Icon
                      path={ICONS.DELETE}
                      size={'2'}
                      className='cursor-pointer'
                      variant={'danger'}
                      onClick={()=>{
                        const newSort = values.sorts.filter((sort:SortType, sIndex:number)=>sIndex !== index)
                        setFieldValue('sorts', newSort)
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          })
        }}
      </FieldArray>

    </>
  )

  // return (
  //   <>
  //     <div className='d-flex pb-2'>
  //
  //       <AEIconButton
  //         title={<FormattedMessage id={'ADD_SORT'}/>}
  //         icon={ICONS.PLUS}
  //         onClick={() => {
  //           const {property} = properties[0]
  //           setFieldValue(
  //             `${values.length}`,
  //             {
  //               direction: ASC,
  //               property: property,
  //             }
  //           )
  //           setOpen(true)
  //         }}
  //       />
  //
  //       <AEIconButton
  //         variant={'light-warning'}
  //         title={<FormattedMessage id={'RESET'}/>}
  //         icon={ICONS.RESET}
  //         onClick={()=>{
  //           handleReset()
  //           setOpen(true)
  //         }}
  //       />
  //
  //       <AEIconButton
  //         variant={'light-dark'}
  //         title={<FormattedMessage id={'TOGGLE'}/>}
  //         icon={`/Navigation/${open ? 'Angle-double-up' : 'Minus'}.svg`}
  //         onClick={()=>{
  //           setOpen(!open)
  //         }}
  //       />
  //
  //     </div>
  //
  //     <div className={clsx({'d-none': !open})}>
  //       {
  //         values.map((sort, index) => {
  //           return (
  //             <Card key={index} className='card-border'>
  //               <Card.Body>
  //                 <div className={'row align-items-center'}>
  //                   <div className={'col-2 text-center'}>
  //                     <div className="text-dark-50 font-weight-bold pe-1">
  //                       <FormattedMessage id={index === 0 ? 'SORT_BY' : 'THEN'} />
  //                     </div>
  //                   </div>
  //                   <div className={'col-5'}>
  //                     <Field
  //                       component={FilterProperty}
  //                       name={`${index}.property`}
  //                       properties={properties}
  //                     />
  //                   </div>
  //                   <div className={'col-4'}>
  //                     <Field
  //                       name={`${index}.direction`}
  //                     />
  //                   </div>
  //
  //                   <div className={'col-1'}>
  //                     <AEIconButton
  //                       variant={'light-danger'}
  //                       title={<FormattedMessage id={'DELETE'}/>}
  //                       icon={ICONS.DELETE}
  //                       onClick={()=>{
  //                         const newSort = values.filter((sort, sIndex)=>sIndex !== index)
  //                         setValues(newSort)
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //               </Card.Body>
  //             </Card>
  //
  //           )
  //         })
  //       }
  //     </div>
  //   </>
  // )
}