import React, {useState} from "react";
import {Formik} from "formik";
import {Form} from "./Form";
import {TProperties, TSorts} from '../Metadata'

type TProps = {
  values: {sorts: TSorts}
  setSorts: (values:TSorts)=>void
  properties: TProperties
}


export const AESort = ({values, setSorts, properties}:TProps) => {
  const [initialValues] = useState(values)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      {
        formik=>(
          <Form
            formik={formik}
            setSorts={setSorts}
            properties={properties}
          />
        )
      }
    </Formik>
  )
}