import {getSlice, TBaseState} from '../../helpers'
import * as API from "../api";
import {MODULES} from '../../../_custom/helpers/RoutingHelpers'
import {Metadata, TMetadata} from '../../../_custom/partials/components/Metadata'
import {Dispatch} from 'redux'
import {TCustomer} from '../../../app/types/TCustomer'
import {shallowEqual, useSelector} from 'react-redux'

/**
 * Configure Metadata
 */
let metadata = new Metadata();
// metadata.pagination.perPage = 5

/**
 * Create slice
 */
const {actions, name, reducer} = getSlice({
  name: `${MODULES.CUSTOMERS}.LIST`,
  data: [],
  metadata: metadata.toJson()
})

const {startCall, endCall, fetched, catchError} = actions;

/**
 * Fetch customer
 *
 * @param metadata
 */
const fetchDispatcher = (metadata: TMetadata) => (dispatch: Dispatch) => {
  dispatch(startCall());

  return API
    .all(metadata)
    .then(response => {
      dispatch(fetched(response));

      return response;
    })
    .catch(response => {
      dispatch(catchError(response));

      return response;
    })
    .then(response=>{ dispatch(endCall(response)) })

};

/**
 * Get State
 */
type TState = {
  data: Array<TCustomer>
  metadata: TMetadata
} & Omit<TBaseState, 'data'>

const _useSelector = ():TState => {
  return useSelector((selector:any)=>selector[name], shallowEqual)
}

const _reducer = {
  [name]: reducer
}

export {
  fetchDispatcher,
  _useSelector,
  _reducer,
  actions
}