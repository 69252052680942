import React from "react";
import {usePagination} from "@material-ui/lab";
import clsx from 'clsx'
import {FormattedMessage} from 'react-intl'

export type TPaginator = {
  page: number
  pages: number
  setPage: (page:number)=>void
}

export function Paginator({page, pages, setPage}:TPaginator) {
  const { items } = usePagination({
    count: pages,
    // defaultPage: 5,
    page
  });
  // console.log(page)

  return (
    <ul className='pagination pagination-sm'>
      {items.map(({ ...item }, index) => {
        let text;

        switch (item.type) {
          case 'start-ellipsis':
          case 'end-ellipsis':
            text = '...';
            break;
          case 'page':
            text = item.page;
            break;
          case 'next':
            text = <FormattedMessage id='NEXT'/>
            break;
          case 'previous':
            text = <FormattedMessage id='PREVIOUS'/>;
            break;
          default:
            text = item.type
            break;
        }

        return (
          <li
            key={index}
            className={clsx(
              'page-item',
              item.type === 'previous' && 'previous',
              item.type === 'next' && 'next',
              item.disabled && 'disabled',
              item.selected && 'active'
            )}
            onClick={()=>{
              if (item.page && !item.disabled) {
                setPage(item.page)
              }
            }}
          >
            <a className="page-link">
              {text}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
