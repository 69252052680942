import React from 'react'
import {Search} from './Search'
import {Sort} from './Sort'
import {Filter} from './Filter'
import {PageToolbar} from '../../../../../../_metronic/layout/core'
import {AERouteAction} from '../../../../../../_custom/partials/components/AERouteActions'
import {TProperty} from '../../../../../../_custom/partials/components/Metadata'
import {NUMBER, STRING} from '../../../../../../_custom/partials/components/Filter/helpers'
import {TProductCategory} from '../../../../../types/TProductCategory'

type TProductCategoryProperty = {
  property: keyof TProductCategory
} & Omit<TProperty, 'property'>

type TProductCategoryProperties = Array<TProductCategoryProperty>

export const PROPERTIES: TProductCategoryProperties = [
  {
    property: 'id',
    type: NUMBER,
    label: 'ID',
    filterable: false,
    sortable: false,
  },
  {
    property: 'name',
    type: STRING,
    label: 'NAME',
    filterable: true,
    sortable: true,
  }
]

export const Toolbar = () => {

  return (
    <PageToolbar>
      <div className='ms-4'>
        <Search />
      </div>
      <div className='ms-4'>
        <Sort />
      </div>
      <div className='ms-4'>
        <Filter />
      </div>
      <div className='ms-4'>
        <AERouteAction
          routeKey='PRODUCT_CATEGORIES.NEW'
          className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm btn-icon-primary fw-boldest text-truncate'
        />
      </div>
    </PageToolbar>
  )
}
