import React from 'react'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const LatField = ()=>(
  <Field
    component={FormikBootstrapInput}
    name='lat'
    label='LAT'
    type='number'
  />
)