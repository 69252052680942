/* eslint-disable no-restricted-imports */
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {deleteCustomer, fetchCustomerForDelete, useCustomerForDelete} from '../../../../../api/customers';
import {EntityCard} from '../../components/EntityCard';
import {AEButton} from '../../../../../_custom/partials/components/buttons'
import {RouteTitle} from '../../../../../_custom/partials/components/RouteTitle'
import {PageLoading, PageToolbar} from '../../../../../_metronic/layout/core'

type TProps = {
  history: any
  match: any
}

export function Delete({history, match}:TProps) {
  const dispatch = useDispatch();
  const { isLoading, data } = useCustomerForDelete()

  const id = match.params.id;


  useEffect(() => {
    dispatch(fetchCustomerForDelete(id));
  }, [id, dispatch]);

  const deleteEntity = () => {
    dispatch(deleteCustomer(id))
  };

  return (
    <>
      <PageLoading loading={isLoading} />
      <RouteTitle id={'CUSTOMERS.DELETE'} />
      <PageToolbar>
        <div className={'ps-3'}>
          <AEButton
            size={'sm'}
            variant={'light'}
            onClick={history.goBack}
            className={'mr-3'}
          >
            <FormattedMessage id={'BACK'} />
          </AEButton>
        </div>
        <div className={'ps-3'}>
          <AEButton
            size={'sm'}
            variant={'outline'}
            fontWeight={'bolder'}
            className='btn-outline-dashed btn-outline-danger btn-active-light-danger btn-icon-danger'
            onClick={deleteEntity}
          >
            <FormattedMessage id={'CUSTOMERS.DELETE'} />
          </AEButton>
        </div>
      </PageToolbar>
      {
        ! isLoading && data.id &&
        <EntityCard
            entity={data}
          className='card-border border-0'
        />
      }
    </>
  );
}

