import { _reducer as productsReducer} from './slices/list'
import { _reducer as productsDeleteReducer} from './slices/delete'
import { _reducer as productsCreateReducer} from './slices/create'
import { _reducer as productsEditReducer} from './slices/edit'
import { _reducer as productsForAutocompleteReducer} from './slices/autocomplete'

export const productsReducers = {
  ...productsReducer,
  ...productsDeleteReducer,
  ...productsEditReducer,
  ...productsCreateReducer,
  ...productsForAutocompleteReducer
}

export {
  fetchDispatcher as fetchProductsForList,
  _useSelector as useProductsForList,
  actions as productsListActions
} from './slices/list'

export {
  fetchDispatcher as fetchProductsForAutocomplete,
  _useSelector as useProductsForAutocomplete,
  actions as productsForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useProductForEdit,
  fetchDispatcher as fetchProductForEdit,
  updateDispatcher as updateProduct
} from './slices/edit'

export {
  useSelector as useProductsForCreate,
  createDispatcher as createProduct,
  resetDispatcher as resetProductForCreate
} from './slices/create'

export {
  useSelector as useProductForDelete,
  fetchDispatcher as fetchProductForDelete,
  deleteDispatcher as deleteProduct
} from './slices/delete'



