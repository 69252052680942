import React from 'react'
import {FormattedMessage} from 'react-intl'
import clsx from 'clsx'
import {Icon} from '../../../../_custom/partials/components/Icon'

export type TModelKey = 'DEFAULT' | 'SALE'
type TBadge = {
  variant: string
}
type TProps = {
  modelKey: TModelKey
}

const MODELS:Record<TModelKey, TBadge> = {
  DEFAULT: {
    variant: 'primary'
  },
  SALE: {
    variant: 'info'
  }
}
export const MODELS_KEYS = Object.keys(MODELS)
export const getModelVariant = (modelKey:TModelKey) => MODELS[modelKey].variant

export const Model:React.FC<TProps> = ({modelKey}) => {

  const variant = getModelVariant(modelKey)

  return (
    <div className={`badge border-dashed bg-light-${variant} fs-5 fw-bolder text-dark d-flex align-items-center p-2`}>
      <span className={`px-2 text-${variant}`}>
        <FormattedMessage id={modelKey}/>
      </span>
    </div>
  )
}