import React from 'react';
import {Field} from 'formik'
import {useDispatch} from 'react-redux'
import {fetchRolesForAutocomplete, useRolesForAutocomplete} from '../../api/roles'
import {
  FormikBootstrapAutocomplete,
  TFormikBootstrapAutocomplete,
} from '../../_custom/partials/controls/FormikBootstrapAutocomplete'
import {TRole} from '../types/TRole'
import {STRING_CONTAINS} from '../../_custom/partials/components/Metadata'
import {TRoute} from '../types/TRoute'
import {useAuthState} from '../../api/auth'

type TProps = TFormikBootstrapAutocomplete & {
  useId: boolean
}

export const RoleField = ({useId, ...props}:TProps) => {
  const dispatch = useDispatch();
  const {user} = useAuthState();
  const {metadata, data, isLoading} = useRolesForAutocomplete()

  const HandleSearch = (search:string) => {
    let md: typeof metadata = {
      ...metadata,
      filter: {
        ...metadata.filter,
        filters:  [
          {
            property: 'name',
            operator: STRING_CONTAINS,
            value: search
          }
        ]
      }
    }
    if (user && user.role && user.role.priority) {
      md.filter.filters.push({
        property: 'priority',
        operator: 'NUMBER.GTE',
        value: user.role.priority
      })
    }


    dispatch(
      fetchRolesForAutocomplete(md)
    )
  }

  if (useId) {
    props.options = data.map(role=>role.id);
    props.getOptionLabel = (id:number)=>{
      const _role = data.find(role=>role.id === id)
      if (_role) {
        return _role.name;
      }
      return 'NOT_FOUND'
    }
  } else {
    props.options = data;
    props.getOptionLabel = (role:TRole)=>role.name;
    props.getOptionSelected = (option:TRoute, value:TRoute) => option.id === value.id
  }

  return (
    <Field
      component={FormikBootstrapAutocomplete}
      name='role'
      label='ROLE'
      onOpen={()=>{
        HandleSearch('')
      }}
      onInputChange={(event:any, search:string) => {
        HandleSearch(search)
      }}
      loading={isLoading}
      {...props}
    />
  )
}