import { _reducer as routesReducer} from './slices/list'
import { _reducer as routesDeleteReducer} from './slices/delete'
import { _reducer as routesCreateReducer} from './slices/create'
import { _reducer as routesEditReducer} from './slices/edit'
import { _reducer as routesForAutocompleteReducer} from './slices/autocomplete'

export const routesReducers = {
  ...routesReducer,
  ...routesDeleteReducer,
  ...routesEditReducer,
  ...routesCreateReducer,
  ...routesForAutocompleteReducer
}

export {
  fetchDispatcher as fetchRoutesForList,
  _useSelector as useRoutesForList,
  actions as routesListActions
} from './slices/list'

export {
  fetchDispatcher as fetchRoutesForAutocomplete,
  _useSelector as useRoutesForAutocomplete,
  actions as routesForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useRouteForEdit,
  fetchDispatcher as fetchRouteForEdit,
  updateDispatcher as updateRoute
} from './slices/edit'

export {
  useSelector as useRoutesForCreate,
  createDispatcher as createRoute,
  resetDispatcher as resetRouteForCreate
} from './slices/create'

export {
  useSelector as useRouteForDelete,
  fetchDispatcher as fetchRouteForDelete,
  deleteDispatcher as deleteRoute
} from './slices/delete'



