import React from 'react'
import {Field} from 'formik'
import {FormikFile} from '../../_custom/partials/controls/FormikFile'

export const FileField = ({...props})=>(
  <Field
    component={FormikFile}
    name='fileName'
    label='FILE'
    {...props}
  />
)