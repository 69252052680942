import React from 'react'
import {FormikBootstrapInput, TFormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const PasswordConfirmField:React.FC<TFormikBootstrapInput | any> = ({...props})=>(
  <Field
    component={FormikBootstrapInput}
    name='_confirm'
    label='PASSWORD_CONFIRM'
    type='password'
    {...props}
  />
)
