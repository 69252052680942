import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux';
import {Formik} from 'formik';
import {registerTenant, resetTenantForRegister, useTenantsForRegister} from '../../../../../api/tenants'
import {RouteComponentProps} from 'react-router'
import {tenantRegisterSchema} from '../../../../types/TTenant'
import {Form} from './Form'
import {FormattedMessage} from 'react-intl'
import {AEButton} from '../../../../../_custom/partials/components/buttons'
import {Link} from 'react-router-dom'
type TProps = RouteComponentProps

export const TenantRegistration = ({ history }:TProps) => {
  const dispatch = useDispatch();
  const { isLoading, data, error } = useTenantsForRegister();

  const saveBtnRef = useRef<HTMLDivElement | null>(null)
  const saveBtnRefClick = () => {
    if (saveBtnRef && saveBtnRef.current) {
      const {current} = saveBtnRef;
      current.click()
    }
  };

  useEffect(()=>{
    dispatch(resetTenantForRegister())
  }, [])


  return (
    <>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          <FormattedMessage id='AUTH.TENANT.REGISTER.TITLE'/>
        </h1>
        <div className='text-gray-400 fw-bold fs-6'>
          <FormattedMessage id='AUTH.TENANT.REGISTER.SUBTITLE'/>
        </div>

      </div>
      <Formik
        enableReinitialize
        initialErrors={error}
        initialValues={data}
        validationSchema={tenantRegisterSchema}
        onSubmit={({fileName, coverFileName, ...values})=>{
          dispatch(registerTenant(values, {fileName, coverFileName}))
        }}
        render={formik=>(
          <Form
            formik={formik}
            btnRef={saveBtnRef}
          />)}
      />

      <div className='pt-10'>
        <AEButton
          size={'lg'}
          className={'w-100 mb-5'}
          onClick={saveBtnRefClick}
        >
          {
            !isLoading &&
            <span className='indicator-label'>
              <FormattedMessage id='REGISTER' />
            </span>
          }

          {
            isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                <FormattedMessage id='PLEASE_WAIT' />
                <span className='spinner-border spinner-border-sm align-middle ms-2'/>
              </span>
            )}
        </AEButton>

        <Link
          to={'/auth/login'}
          className={'btn btn-lg btn-light w-100 mb-5'}
        >
          <span className='indicator-label'>
            <FormattedMessage id='BACK' />
          </span>
        </Link>
      </div>
    </>
  );
}

