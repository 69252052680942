import * as Yup from 'yup'
import {Entity, TTenantEntity} from './TEntity'

/**
 * Types
 * */
export type TBlogCategory = Entity & TTenantEntity & {
  name: string,
}
export type TNewBlogCategory = Omit<TBlogCategory, 'id'>
export type TEditBlogCategory = TBlogCategory
export type TDeleteBlogCategory = TEditBlogCategory

/**
 * Default Values
 */
export const defaultBlogCategory:TBlogCategory = {
  id: 0,
  name: '',
  tenant: null
}
export const defaultNewBlogCategory:TNewBlogCategory = {
  name: ''
}
export const defaultEditBlogCategory:TEditBlogCategory = {
  ...defaultNewBlogCategory,
  id: 0
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewBlogCategory, any> = {
  name: Yup.string().required(),
  tenant: Yup.object().nullable(),
}
export const blogCategoryCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const blogCategoryEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

