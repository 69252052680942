import React from 'react'
import {Field} from 'formik'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'

export const StartAtField:React.FC<any> = ({...props}) => (
  <Field
    component={FormikBootstrapInput}
    name='startAt'
    label='START_AT'
    type='datetime-local'
    {...props}
  />
)