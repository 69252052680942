import React from 'react'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'
import {Field} from 'formik'

export const PhoneField = ()=>(
  <Field
    component={FormikBootstrapInput}
    name='phone'
    label='PHONE'
    type='tel'
  />
)