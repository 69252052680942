import React, {useEffect} from 'react'
import {Field} from 'formik'
import {useDispatch} from 'react-redux'
import {fetchRoutesForAutocomplete, useRoutesForAutocomplete} from '../../api/routes'
import {
  FormikBootstrapAutocomplete,
  TFormikBootstrapAutocomplete,
} from '../../_custom/partials/controls/FormikBootstrapAutocomplete'
import {TRoute} from '../types/TRoute'
import {useIntl} from 'react-intl'
import {getRoute} from '../routing/Routes'

type TProps = TFormikBootstrapAutocomplete & {
  useId: boolean
}

export const RouteField = ({useId, ...props}:TProps) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const {metadata, data, isLoading} = useRoutesForAutocomplete()

  useEffect(()=>{
    dispatch(
      fetchRoutesForAutocomplete(metadata)
    )
  },[])

  if (useId) {
    props.options = data.map(route=>route.id);
    props.getOptionLabel = (id:number)=>{
      const _route = data.find(route=>route.id === id)
      if (_route) {
        return formatMessage({id: _route.routeKey});
      }
      return 'NOT_FOUND'
    }
  } else {
    props.options = data/*.sort((o1:any, o2:any) => {
      console.log(o1)
      const _route1 = getRoute(o1.routeKey);
      const _route2 = getRoute(o2.routeKey);
      if (_route1 && _route2) {
        return -_route2.context.localeCompare(_route1.context)
      }

      return 0
    });*/
    props.getOptionLabel = (route:TRoute)=>formatMessage({id: route.routeKey});
    props.getOptionSelected = (option:TRoute, value:TRoute) => option.id === value.id
  }

  return (
    <Field
      component={FormikBootstrapAutocomplete}
      name='route'
      label='ROUTE'
      loading={isLoading}
      groupBy={(route:TRoute) => {
        const _route = getRoute(route.routeKey);
        if (_route) {
          return formatMessage({id: _route.context})
        }

        return null
      }}
      {...props}
    />
  )
}