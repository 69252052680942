import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {ICONS} from '../../../../_custom/partials/components/Icon'
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import {DetailLine} from '../../../../_custom/partials/components/DetailLine'
import clsx from 'clsx'
import {TTenant} from '../../../types/TTenant'
import { APP_LOGO_WITHOUT_TEXT, toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'
import {Status} from './Status'
import {Attribute} from './Attribute'

type TProps = {
  entity: TTenant
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:TProps) => {

  const { user } = useAuthState()

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'TENANTS.DELETE', params: {id: entity.id}})
    }
    if (editAction) {
      actions.push({id: 'TENANTS.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, user, editAction, deleteAction])


  return (
    <div className={clsx(
      'card border border-2 border-gray-300 border-hover',
      className
    )}>
      <div className='card-header flex-nowrap border-0'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
            <img
              src={
                entity.fileName ?
                  toAbsoluteUploadUrl(`/tenants/${entity.fileName}`) :
                  APP_LOGO_WITHOUT_TEXT
              }
              alt={` `}
            />
          </div>
        </div>
        <div className="card-toolbar">
          <div className='me-3'>
            <Status statusKey={entity.status}/>
          </div>
          <AERouteActions actions={actions} />
        </div>
      </div>
      <div className='card-body d-flex flex-column py-0'>
        <div className='fs-3 fw-bolder text-dark mt-1 mb-7'>
          {entity.name}
        </div>
        <div>
          {
            entity.email &&
            <DetailLine
              title={'EMAIL'}
              icon={ICONS.EMAIL}
            >
              {entity.email}
            </DetailLine>
          }
          {
            entity.phone &&
            <DetailLine
              title={'PHONE'}
              icon={ICONS.PHONE}
            >
              {entity.phone}
            </DetailLine>
          }
          {
            entity.landline &&
            <DetailLine
              title={'LANDLINE'}
              icon={ICONS.LANDLINE}
            >
              {entity.landline}
            </DetailLine>
          }
        </div>
      </div>
      <div className='card-footer d-flex align-items-center flex-wrap fs-6 border-0'>
        <div className={'m-1'}>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${entity.lat},${entity.lng}`}
            target='_blank'
            className='btn btn-icon btn-sm btn-light'
          >
            <i className='fs-4 fas fa-map-marker-alt'/>
          </a>
        </div>
        {
          entity.attributes?.map(attribute=>(
            <div key={attribute.id} className={'m-1'} title={attribute.attKey}>
              <Attribute
                attKey={attribute.attKey}
                attValue={attribute.attValue}
              />
            </div>
          ))
        }
      </div>
    </div>
  )
}