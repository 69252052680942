import { _reducer as authEditProfileReducer} from './slices/editProfile'
import { _reducer as authEditPasswordReducer} from './state/editPassword'
import { _reducer as routesForApp} from './state/RoutesForApp'

export const authReducers = {
  ...authEditProfileReducer,
  ...authEditPasswordReducer,
  ...routesForApp
}

export {
  useSelector as useProfileEditState,
  fetchDispatcher as fetchProfileForEdit,
  updateDispatcher as updateProfile
} from './slices/editProfile'

export {
  useSelector as usePasswordEditState,
  resetDispatcher as resetPasswordForEdit,
  saveDispatcher as savePassword
} from './state/editPassword'

export {
  _useSelector as useAuthState
} from './authRedux'

export {
  fetchDispatcher as fetchRoutesForApp,
  _useSelector as useRoutesForAppState
} from './state/RoutesForApp'