import axios from "axios";
import {getFormData} from '../../_custom/helpers/AxiosHelpers'
import {toAbsoluteApiUrl} from '../../_custom/helpers/AssetHelpers'
import {TMetadata} from '../../_custom/partials/components/Metadata'
import {TEditUser, TNewUser} from '../../app/types/TUser'
import {MODULES} from '../../_custom/helpers/RoutingHelpers'

export const API_URI = toAbsoluteApiUrl(`/${MODULES.USERS.toLowerCase()}`);

/**
 * Filter all users
 * */
export const all = (metadata:TMetadata) => {
  const {filter, join, sorts, pagination: {page, perPage}, search} = metadata
  const params = {filter, join, sorts, pagination: {page, perPage}, search}

  return axios.get(API_URI,{params});
}

/**
 * Find user by Id
 * */
export const find = (id: number) => axios.get(`${API_URI}/${id}`);

/**
 * Delete user by Id
 * */
export const destroy = (id: number) => axios.delete(`${API_URI}/${id}`);

/**
 * create user
 * */
export const create = (entity:TNewUser, files:any) => {
  let object:any = entity

  if (object.role) {
    object.role = {id: object.role.id}
  }
  if (object.tenant) {
    object.tenant = {id: object.tenant.id}
  }

  return axios.post(API_URI, getFormData(object, files));
}

/**
 * Update user
 * */
export const update = (entity:TEditUser, files:any) => {
  let object:any = entity

  if (object.role) {
    object.role = {id: object.role.id}
  }
  if (object.tenant) {
    object.tenant = {id: object.tenant.id}
  }

  return axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(entity, files));
}


