import * as Yup from 'yup'
import {Entity, TFileEntity, TTenantEntity} from './TEntity'

/**
 * Types
 * */
export type TProductCategory = Entity & TTenantEntity & TFileEntity &  {
  name: string,
}
export type TNewProductCategory = Omit<TProductCategory, 'id'>
export type TEditProductCategory = TProductCategory
export type TDeleteProductCategory = TEditProductCategory

/**
 * Default Values
 */
export const defaultProductCategory:TProductCategory = {
  id: 0,
  name: '',
  tenant: null
}
export const defaultNewProductCategory:TNewProductCategory = {
  name: '',
  // tenant: null
}
export const defaultEditProductCategory:TEditProductCategory = {
  ...defaultNewProductCategory,
  id: 0
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewProductCategory, any> = {
  fileName: Yup.string(),
  name: Yup.string().required(),
  tenant: Yup.object().nullable(),
}
export const productCategoryCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const productCategoryEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

