import { _reducer as customersReducer} from './slices/list'
import { _reducer as customersDeleteReducer} from './slices/delete'
import { _reducer as customersCreateReducer} from './slices/create'
import { _reducer as customersEditReducer} from './slices/edit'
import { _reducer as customersForAutocompleteReducer} from './slices/autocomplete'

export const customersReducers = {
  ...customersReducer,
  ...customersDeleteReducer,
  ...customersEditReducer,
  ...customersCreateReducer,
  ...customersForAutocompleteReducer
}

export {
  fetchDispatcher as fetchCustomersForList,
  _useSelector as useCustomersForList,
  actions as customersListActions
} from './slices/list'

export {
  fetchDispatcher as fetchCustomersForAutocomplete,
  _useSelector as useCustomersForAutocomplete,
  actions as customersForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useCustomerForEdit,
  fetchDispatcher as fetchCustomerForEdit,
  updateDispatcher as updateCustomer
} from './slices/edit'

export {
  useSelector as useCustomersForCreate,
  createDispatcher as createCustomer,
  resetDispatcher as resetCustomerForCreate
} from './slices/create'

export {
  useSelector as useCustomerForDelete,
  fetchDispatcher as fetchCustomerForDelete,
  deleteDispatcher as deleteCustomer
} from './slices/delete'



