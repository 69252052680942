import React, {useState} from 'react'
import {AEFilter} from '../../../../../../_custom/partials/components/Filter/AEFilter'
import {useDispatch} from 'react-redux'
import {usersListActions, useUsersForList} from '../../../../../../api/users'
import {Icon} from '../../../../../../_custom/partials/components/Icon'
import {FormattedMessage} from 'react-intl'
import {DropdownFilterToggler} from '../../../../../../_custom/partials/components/dropdowns'
import {DropdownCustomMenu} from '../../../../../../_custom/partials/components/dropdowns/DropdownCustomMenu'
import {Dropdown} from 'react-bootstrap-v5'
import {PROPERTIES} from './Toolbar'
import {TUser} from '../../../../../types/TUser'
import {useAuthState} from '../../../../../../api/auth'
import {isGranted} from '../../../../../../_custom/partials/components/Granted'

export const Filter = () => {
  const dispatch = useDispatch();
  const {user} = useAuthState();
  const [show, setShow] = useState(false)
  const {metadata:{filter}} = useUsersForList();
  const {setFilter} = usersListActions;

  return (
    <Dropdown
      show={show}
    >
      <Dropdown.Toggle
        as={DropdownFilterToggler}
        onClick={()=>{
          setShow(!show)
        }}
      >
        <Icon path={'/Text/Filter.svg'} />
        <FormattedMessage id={'FILTER'} />
        {
          filter.filters.length > 0 &&
          <span className='position-absolute top-0 start-100 translate-middle badge badge-circle badge-sm badge-primary' >
            {filter.filters.length}
          </span>
        }
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={DropdownCustomMenu}
        className='w-lg-600px w-md-500px w-sm-500px w-400px py-0'
      >
        <AEFilter
          values={filter}
          setFilter={(values:any)=>{
            dispatch(setFilter(values))
          }}
          properties={
            PROPERTIES
              .filter(({filterable})=>filterable)
              .filter(({property})=> {
                if (property === 'tenant') {
                  return user && isGranted(user, ['ROLE_SUPER_ADMIN']);
                }
                return true
              })
          }
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}