import React, {LegacyRef} from 'react'
import {FormikProps} from 'formik/dist/types'
import {
  AttKeyField, AttValueField,
  EmailField, FileField,
  LandlineField, LatField, LngField, MapCoordinatesPickerField,
  NameField,
  PhoneField, TenantStatusField,
} from '../../../../controls'
import {FieldArray} from 'formik'
import {defaultNewTenantAttribute, TTenantAttribute} from '../../../../types/TTenantAttribute'
import {FormattedMessage} from 'react-intl'
import {AEButton} from '../../../../../_custom/partials/components/buttons'

type TProps = {
  formik: FormikProps<any>
  btnRef: LegacyRef<HTMLDivElement>
}

export const Form = ({formik, btnRef}:TProps) => {
  const {handleSubmit} = formik
  const attributes:Array<TTenantAttribute> = formik.values.attributes

  return (
    <div className='form form-label-right'>
      <div className='card mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='mb-3 col-md'>
              <FileField
                label='LOGO'
                preview
                uploadPath='/tenants'
              />
            </div>
            <div className='mb-3 col-md'>
              <FileField
                name='coverFileName'
                label='COVER'
                preview
                uploadPath='/tenants'
                previewStyle={{
                  width: 200
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md'>
              <NameField />
            </div>
            <div className='mb-3 col-md'>
              <EmailField />
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md'>
              <PhoneField />
            </div>
            <div className='mb-3 col-md'>
              <LandlineField />
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md'>
              <LatField />
            </div>
            <div className='mb-3 col-md'>
              <LngField />
            </div>
            {/*FIXME: https://github.com/google-map-react/google-map-react/pull/1049*/}
            <div className={'mb-3 col-12'}>
              <MapCoordinatesPickerField />
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md'>
              <TenantStatusField />
            </div>
          </div>
        </div>
      </div>


      <FieldArray
        name='attributes'
        render={({push, remove}) => {

          return (
            <>
              <div className='card mb-5'>
                <div className='card-header flex-nowrap border-0'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder'>
                      <FormattedMessage id='ATTRIBUTES' />
                    </h3>
                  </div>
                  <div className="card-toolbar">
                    <AEButton
                      size='sm'
                      variant='light-primary'
                      onClick={()=>{
                        push(defaultNewTenantAttribute)
                      }}
                    >
                      <FormattedMessage id='ADD'/>
                    </AEButton>
                  </div>
                </div>
                <div className='card-body pt-0'>
                  {
                    attributes.map((attribute:TTenantAttribute, index:number)=>{
                        return (
                          <div key={index}>
                            <div className='row align-items-end'>
                              <div className='mb-3 col-auto'>
                                <AttKeyField
                                  size='sm'
                                  name={`attributes.${index}.attKey`}
                                />
                              </div>
                              <div className='mb-3 col-md'>
                                <AttValueField
                                  size='sm'
                                  name={`attributes.${index}.attValue`}
                                />
                              </div>
                              <div className='mb-3 col-auto'>
                                <AEButton
                                  size='sm'
                                  variant='light-danger'
                                  onClick={()=>{
                                    remove(index)
                                  }}
                                >
                                  <FormattedMessage id='REMOVE'/>
                                </AEButton>
                              </div>
                            </div>
                            {
                              index+1 < attributes.length &&
                              <div className='separator separator-dashed mb-4'/>
                            }

                          </div>
                        )
                      })
                  }
                </div>
              </div>
            </>
          )
        }}
      />





      <div
        className={'d-none'}
        ref={btnRef}
        onClick={()=>{
          handleSubmit()
        }}
      />
    </div>
  );
}

