import * as Yup from 'yup'
import {Entity, TFileEntity, TPostEntity, TTenantEntity, TTimestampEntity} from './TEntity'
import {TBlogPostReaction} from './TBlogPostReaction'
import {TBlogCategory} from './TBlogCategory'

/**
 * Types
 * */
export type TBlogPost = Entity & TTenantEntity & TFileEntity & TPostEntity & TTimestampEntity & {
  reactions: Array<TBlogPostReaction>
  categories: Array<TBlogCategory>
}
export type TNewBlogPost = Omit<TBlogPost, 'id' | 'reactions' | 'createdAt' | 'updatedAt'>
export type TEditBlogPost = Omit<TBlogPost, 'reactions' | 'createdAt' | 'updatedAt'>
export type TDeleteBlogPost = TEditBlogPost

/**
 * Default Values
 */
export const defaultBlogPost:TBlogPost = {
  id: 0,
  title: '',
  content: null,
  fileName: '',
  tenant: null,
  reactions: [],
  categories: [],
}
export const defaultNewBlogPost:TNewBlogPost = {
  title: '',
  content: null,
  fileName: '',
  tenant: null,
  categories: [],
}
export const defaultEditBlogPost:TEditBlogPost = {
  ...defaultNewBlogPost,
  id: 0
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewBlogPost, any> = {
  title: Yup.string().required(),
  content: Yup.array().nullable(),
  fileName: Yup.string(),
  tenant: Yup.object().nullable(),
  categories: Yup.array()
}
export const blogPostCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const blogPostEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

