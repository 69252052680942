import React from 'react'

export const STRING_EQ = 'STRING.EQ';
export const STRING_NEQ = 'STRING.NEQ';
export const STRING_IS_NULL = 'STRING.IS_NULL';
export const STRING_IS_NOT_NULL = 'STRING.IS_NOT_NULL';
export const STRING_IN = 'STRING.IN';
export const STRING_NOT_IN = 'STRING.NOT_IN';
export const STRING_CONTAINS = 'STRING.CONTAINS';
export const STRING_DOES_NOT_CONTAIN = 'STRING.DOES_NOT_CONTAIN';
export const STRING_STARTS_WITH = 'STRING.STARTS_WITH';
export const STRING_ENDS_WITH = 'STRING.ENDS_WITH';
export const STRING_DOES_NOT_START_WITH = 'STRING.DOES_NOT_START_WITH';
export const STRING_DOES_NOT_END_WITH = 'STRING.DOES_NOT_END_WITH';
export const NUMBER_EQ = 'NUMBER.EQ';
export const NUMBER_NEQ = 'NUMBER.NEQ';
export const NUMBER_IS_NULL = 'NUMBER.IS_NULL';
export const NUMBER_IS_NOT_NULL = 'NUMBER.IS_NOT_NULL';
export const NUMBER_GT = 'NUMBER.GT'; //
export const NUMBER_GTE = 'NUMBER.GTE';
export const NUMBER_LT = 'NUMBER.LT';
export const NUMBER_LTE = 'NUMBER.LTE';
export const NUMBER_IN = 'NUMBER.IN';
export const NUMBER_NOT_IN = 'NUMBER.NOT_IN';
export const DATETIME_EQ = 'DATETIME.EQ';
export const DATETIME_NEQ = 'DATETIME.NEQ';
export const DATETIME_IS_NULL = 'DATETIME.IS_NULL';
export const DATETIME_IS_NOT_NULL = 'DATETIME.IS_NOT_NULL';
export const DATETIME_GT = 'DATETIME.GT'; //
export const DATETIME_GTE = 'DATETIME.GTE';
export const DATETIME_LT = 'DATETIME.LT';
export const DATETIME_LTE = 'DATETIME.LTE';
export const DATETIME_IN = 'DATETIME.IN';
export const DATETIME_NOT_IN = 'DATETIME.NOT_IN';
export const BOOL_EQ = 'BOOL.EQ';
export const RELATION_EQ = 'RELATION.EQ';
export const RELATION_NEQ = 'RELATION.NEQ';
export const RELATION_IS_NULL = 'RELATION.IS_NULL';
export const RELATION_IS_NOT_NULL = 'RELATION.IS_NOT_NULL';
export const EMPTY_OPERATORS = [
  STRING_IS_NULL,
  STRING_IS_NOT_NULL,
  NUMBER_IS_NULL,
  NUMBER_IS_NOT_NULL,
  DATETIME_IS_NULL,
  DATETIME_IS_NOT_NULL,
  RELATION_IS_NULL,
  RELATION_IS_NOT_NULL
]


const OPERATORS = [
  STRING_EQ,
  STRING_NEQ,
  STRING_IS_NULL,
  STRING_IS_NOT_NULL,
  STRING_IN,
  STRING_NOT_IN,
  STRING_CONTAINS,
  STRING_DOES_NOT_CONTAIN,
  STRING_STARTS_WITH,
  STRING_ENDS_WITH,
  STRING_DOES_NOT_START_WITH,
  STRING_DOES_NOT_END_WITH,
  NUMBER_EQ,
  NUMBER_NEQ,
  NUMBER_IS_NULL,
  NUMBER_IS_NOT_NULL,
  NUMBER_GT,
  NUMBER_GTE,
  NUMBER_LT,
  NUMBER_LTE,
  NUMBER_IN,
  NUMBER_NOT_IN,
  DATETIME_EQ,
  DATETIME_NEQ,
  DATETIME_IS_NULL,
  DATETIME_IS_NOT_NULL,
  DATETIME_GT,
  DATETIME_GTE,
  DATETIME_LT,
  DATETIME_LTE,
  DATETIME_IN,
  DATETIME_NOT_IN,
  BOOL_EQ,
  RELATION_EQ,
  RELATION_NEQ,
  RELATION_IS_NULL,
  RELATION_IS_NOT_NULL
];

export const COND_AND = 'AND'
export const COND_OR = 'OR'
export const WHERE_CONDITIONS = [COND_AND, COND_OR]

export type PaginationType = {
  page: number,
  perPage: number,
  pages: number,
  total: number,
}

export type SortType = {
  property: string
  direction: 'ASC' | 'DESC'
}
export type TSorts = Array<SortType>

type JoinType = {
  table: string
  alias?: string
  join?: Array<JoinType>
}


export type TFilterPropertyOperator = typeof OPERATORS[number]
export type PropertyFilterType = {
  property: string
  operator: TFilterPropertyOperator
  value?: boolean | string | number | Array<string|number>
}

export type TFilterWhereCondition = typeof WHERE_CONDITIONS[number]
export type CompoundFilterType = {
  operator: TFilterWhereCondition
  filters: Array<PropertyFilterType|CompoundFilterType>
}

export type TMetadata = {
  pagination: PaginationType
  sorts: TSorts
  search: string
  filter: CompoundFilterType
  join: Array<JoinType>
}


export class Metadata {
  pagination:PaginationType = {page: 1, pages: 0, perPage: 10, total: 0}
  sorts: TSorts = []
  search:string = ''
  filter: CompoundFilterType = {operator: 'AND', filters: []}
  join:Array<JoinType> = []

  toJson(): TMetadata {
    return JSON.parse(JSON.stringify(this));
  }
}

export type TPropertyType = 'STRING' | 'NUMBER' | 'DATE' | 'DATETIME' | 'BOOL' | 'RELATION'
export type TProperty = {
  property: string
  type: TPropertyType
  label: string
  filterable: boolean
  sortable: boolean
  Field?: React.FC<any>
}
export type TProperties = Array<TProperty>