import React from "react";

export const getUsername = ({username}) => {
  return `${username}`
}

export const Username = ({user, className = '', ...props})=>{

  return (
    <span className={`fw-bold text-muted text-hover-primary fs-7 ${className}`} {...props}>
      @{getUsername(user)}
    </span>
  )
}