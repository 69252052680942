import React from 'react'
import {toAbsoluteUploadUrl} from '../../../../_custom/helpers/AssetHelpers'
import {isImage, isVideo} from '../../../../_custom/partials/controls/FormikFile'


type TPostFile = React.FC<{
  fileName: string
}>
export const PostFile:TPostFile = ({fileName, ...props}) => {
  const src = toAbsoluteUploadUrl(`/tenant_post_files/${fileName}`)

  if (isImage(fileName)) {
    return (
      <img src={src} alt="" className='h-125px rounded'/>
    )
  }

  if (isVideo(fileName)) {
    return (
      <video controls className='h-125px rounded'>
        <source src={src} type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
    )
  }

  return <></>
}