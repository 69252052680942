import React from 'react'
import {Field} from 'formik'
import {FormikMapCoordinatesPicker} from '../../_custom/partials/controls/FormikMapCoordinatesPicker'

export const MapCoordinatesPickerField = ()=>(
  <Field
    component={FormikMapCoordinatesPicker}
    // name='lng'
    // label='LNG'
    // type='number'
  />
)