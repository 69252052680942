/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState, ReactElement, ReactNode} from 'react'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageToolbar?: ReactNode
  setPageToolbar: (_node: ReactNode) => void
  pageLoading?: boolean
  setPageLoading: (loading: boolean) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setPageToolbar: (_node: ReactNode) => {},
  setPageLoading: (_loading: boolean) => {},
})

const PageDataProvider: React.FC = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageToolbar, setPageToolbar] = useState<ReactNode>('')
  const [pageLoading, setPageLoading] = useState(false)
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const value: PageDataContextModel = {
    pageTitle, setPageTitle,
    pageDescription, setPageDescription,
    pageToolbar, setPageToolbar,
    pageBreadcrumbs, setPageBreadcrumbs,
    pageLoading, setPageLoading
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
}

const PageTitle: FC<Props> = ({children, description, breadcrumbs}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return <></>
}

const PageDescription: React.FC = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

const PageToolbar: React.FC = ({children}) => {
  const {setPageToolbar} = usePageData()
  useEffect(() => {
    if (children) {
      setPageToolbar(children)
    }
    return () => {
      setPageToolbar('')
    }
  }, [children])
  return <></>
}

type TPageLoading = {
  loading: boolean
}
const PageLoading = ({loading}:TPageLoading) => {
  const {setPageLoading} = usePageData()
  useEffect(() => {
    setPageLoading(loading)
  }, [loading])
  return <></>
}

export {PageDescription, PageTitle, PageToolbar, PageLoading, PageDataProvider, usePageData}
