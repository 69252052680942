import {
  BOOL_EQ,
  DATETIME_GTE,
  NUMBER_EQ,
  RELATION_IS_NOT_NULL,
  STRING_CONTAINS,
} from '../Metadata'

export const STRING = 'STRING'
export const NUMBER = 'NUMBER'
export const BOOL = 'BOOL'
export const DATETIME = 'DATETIME'
export const DATE = 'DATE'
export const AUTOCOMPLETE = 'RELATION'

export const TYPES = [STRING, NUMBER, BOOL, DATE, DATETIME, AUTOCOMPLETE]
export type TFilterPropertyType = typeof TYPES[number]

export const getDefaultOperatorByDataType = (id?:TFilterPropertyType) => {

  switch (id) {
    case STRING:
      return STRING_CONTAINS
    case NUMBER:
      return NUMBER_EQ
    case DATE:
    case DATETIME:
      return DATETIME_GTE
    case BOOL:
      return BOOL_EQ
    case AUTOCOMPLETE:
      return RELATION_IS_NOT_NULL
  }

  console.error('Filter Id Not Found')
}

export const getDefaultFilterValue = (id?:TFilterPropertyType) => {

  switch (id) {
    case AUTOCOMPLETE:
    case DATE:
    case DATETIME:
      return null
    case STRING:
      return ''
    case BOOL:
      return true
    case NUMBER:
      return 0
  }

  console.error('Filter Id Not Found')

}

export const getDefaultFilter = (id?: TFilterPropertyType) => {
  return {
    // property: '',
    operator: getDefaultOperatorByDataType(id),
    value: getDefaultFilterValue(id)
  }
}