import { _reducer as rolesReducer} from './slices/list'
import { _reducer as rolesDeleteReducer} from './slices/delete'
import { _reducer as rolesCreateReducer} from './slices/create'
import { _reducer as rolesEditReducer} from './slices/edit'
import { _reducer as rolesForAutocompleteReducer} from './slices/autocomplete'

export const rolesReducers = {
  ...rolesReducer,
  ...rolesDeleteReducer,
  ...rolesEditReducer,
  ...rolesCreateReducer,
  ...rolesForAutocompleteReducer
}

export {
  fetchDispatcher as fetchRolesForList,
  _useSelector as useRolesForList,
  actions as rolesListActions
} from './slices/list'

export {
  fetchDispatcher as fetchRolesForAutocomplete,
  _useSelector as useRolesForAutocomplete,
  actions as rolesForAutocompleteActions
} from './slices/autocomplete'

export {
  useSelector as useRoleForEdit,
  fetchDispatcher as fetchRoleForEdit,
  updateDispatcher as updateRole
} from './slices/edit'

export {
  useSelector as useRolesForCreate,
  createDispatcher as createRole,
  resetDispatcher as resetRoleForCreate
} from './slices/create'

export {
  useSelector as useRoleForDelete,
  fetchDispatcher as fetchRoleForDelete,
  deleteDispatcher as deleteRole
} from './slices/delete'



