import React, {LegacyRef, useEffect} from 'react'
import {FormikProps} from 'formik/dist/types'
import {BlogCategoryField, ContentField, FileField, TitleField} from '../../../../controls'

type TProps = {
  formik: FormikProps<any>
  btnRef: LegacyRef<HTMLDivElement>
}

export const Form = ({formik, btnRef}:TProps) => {
  const {values, handleSubmit} = formik

  useEffect(()=>{
    console.log(values.content)
  }, [values.content])

  return (
    <div className='form form-label-right'>
      <div className='card mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='mb-3 col-md'>
              <FileField
                label='COVER'
                preview
                uploadPath='/blog_posts'
                previewStyle={{
                  width: 200
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md'>
              <TitleField />
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md'>
              <BlogCategoryField
                name='categories'
                label='CATEGORIES'
                multiple
              />
            </div>
          </div>
        </div>
      </div>
      <ContentField />
      <div
        className='d-none'
        ref={btnRef}
        onClick={()=>{
          handleSubmit()
        }}
      />
    </div>
  );
}

