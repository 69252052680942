import {TRoute} from './TRoute'
import * as Yup from 'yup'
import {Entity, TPriorityEntity} from './TEntity'

export type TRoleKey = 'ROLE_SUPER_ADMIN' | 'ROLE_TENANT_OWNER' | 'ROLE_PARTNER' | string
export type TRole = Entity & TPriorityEntity & {
  id: number
  roleKey: string
  name: string
  routes: Array<TRoute>
}

export type TNewRole = Omit<TRole, 'id'>
export type TEditRole = TRole
export type TDeleteRole = TEditRole

export const defaultRole:TRole = {
  id: 0,
  name: '',
  priority: 0,
  roleKey: '',
  routes: []
}
export const defaultNewRole:TNewRole = {
  name: '',
  priority: 0,
  roleKey: '',
  routes: []
}
export const defaultEditRole:TEditRole = {
  id: 0,
  name: '',
  priority: 0,
  roleKey: '',
  routes: []
}

export const editValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  priority: Yup.number()/*.min(authUser.role.priority+1)*/,
  routes: Yup.array()
})
export const createValidationSchema = editValidationSchema
