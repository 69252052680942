import React from 'react'
import {Search} from './Search'
import {Sort} from './Sort'
import {Filter} from './Filter'
import {PageToolbar} from '../../../../../../_metronic/layout/core'
import {AERouteAction} from '../../../../../../_custom/partials/components/AERouteActions'
import {TProperty} from '../../../../../../_custom/partials/components/Metadata'
import {
  AUTOCOMPLETE, DATE,
  DATETIME,
  NUMBER,
  STRING,
} from '../../../../../../_custom/partials/components/Filter/helpers'
import {TCustomer} from '../../../../../types/TCustomer'
import {TenantStatusField} from '../../../../../controls'
import {BirthDateField} from '../../../../../controls/BirthDateField'

type TCustomerProperty = {
  property: keyof TCustomer
} & Omit<TProperty, 'property'>

type TCustomerProperties = Array<TCustomerProperty>

export const PROPERTIES: TCustomerProperties = [
  {
    property: 'id',
    type: NUMBER,
    label: 'ID',
    filterable: false,
    sortable: false,
  },
  {
    property: 'username',
    type: STRING,
    label: 'USERNAME',
    filterable: true,
    sortable: true,
  },
  {
    property: 'firstName',
    type: STRING,
    label: 'FIRSTNAME',
    filterable: true,
    sortable: true,
  },
  {
    property: 'lastName',
    type: STRING,
    label: 'LASTNAME',
    filterable: true,
    sortable: true,
  },
  {
    property: 'email',
    type: STRING,
    label: 'EMAIL',
    filterable: true,
    sortable: true,
  },
  {
    property: 'createdAt',
    type: DATETIME,
    label: 'CREATION_DATE',
    filterable: true,
    sortable: true,
  },
  {
    property: 'birthDate',
    type: DATE,
    label: 'BIRTH_DATE',
    filterable: true,
    sortable: true,
  },
  {
    property: 'status',
    type: AUTOCOMPLETE,
    label: 'STATUS',
    filterable: true,
    sortable: false,
    Field: (props) => {
      return (
        <TenantStatusField
          size='sm'
          placeholderLabel
          validation={false}
          {...props}
        />
      )
    }
  }
]

export const Toolbar = () => {

  return (
    <PageToolbar>
      <div className='ms-4'>
        <Search />
      </div>
      <div className='ms-4'>
        <Sort />
      </div>
      <div className='ms-4'>
        <Filter />
      </div>
      <div className='ms-4'>
        <AERouteAction
          routeKey='CUSTOMERS.NEW'
          className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm btn-icon-primary fw-boldest text-truncate'
        />
      </div>
    </PageToolbar>
  )
}
