import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Dropdown} from 'react-bootstrap-v5'
import {DropdownCustomToggler} from '../../dropdowns'
import {WHERE_CONDITIONS} from '../../Metadata'


type TProps = {
  field: any
  form: any
  className?: string
}

export const FilterWhereOperator = ({field, form, className}:TProps) => {
  const {name, value} = field
  const {setFieldValue} = form

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle as={DropdownCustomToggler}>
        <FormattedMessage id={value} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {WHERE_CONDITIONS.map((id, oIndex) => (
          <Dropdown.Item
            key={id}
            active={id === value}
            onClick={() => {
              setFieldValue(name, id);
            }}
          >
            <FormattedMessage id={id} />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}