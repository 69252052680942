import React from "react";
import {Username} from './Username'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import {toAbsoluteUploadUrl} from '../../helpers/AssetHelpers'
import {toAbsoluteUrl} from '../../../_metronic/helpers'



export const AvatarImg = ({fileName, ...props}) => {
  const src = fileName ?
    toAbsoluteUploadUrl(`/users/${fileName}`) :
    toAbsoluteUrl(`/media/avatars/blank.png`)

  return <img src={src} alt={` `} {...props}/>
}

export const Avatar = ({user, className = '', size}) => {
  const {fileName} = user

  return (
    <OverlayTrigger
      placement={"top"}
      overlay={(
        <Tooltip id={`tooltip_entity_card_proposal_user`}>
          <Username user={user} />
        </Tooltip>
      )}
    >
      <div className={`symbol symbol-${size} ${className} `}>
        <AvatarImg fileName={fileName}/>
        {/*<i className="symbol-badge bg-primary"/>*/}
      </div>
    </OverlayTrigger>
  )
}