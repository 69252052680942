import {Icon, ICONS} from './Icon'
import {FormattedMessage, useIntl} from 'react-intl'
import React from 'react'

type Props = {
  title?: string
  children: any
  icon?: string
  iconVariant?: string
}

export const DetailLine = ({title, children, icon, iconVariant = 'primary'}:Props) => {
  const {formatMessage} = useIntl()
  return (
    <div
      className='d-flex align-items-center flex-wrap fs-6'
      title={ title && formatMessage({id: title})}
    >
      <div className='d-flex align-items-center py-2'>
        <span className={'me-3'}>
          <Icon
            size={'1'}
            className={'flex-shrink-0 mr-2'}
            path={icon ? icon : ICONS.MINUS}
            variant={iconVariant}
          />
        </span>
        <div className='d-flex flex-wrap'>
          <div className='me-2'>
            {children}
          </div>
          {
            title &&
            <div className='text-muted'>
              <FormattedMessage id={title}/>
            </div>
          }
        </div>
      </div>
    </div>
  )
}