import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {TModuleKey} from '../../helpers/RoutingHelpers'

export const ICONS:Record<TModuleKey | string, string> = {
  USERS:'/Communication/Shield-user.svg',
  ROUTES: '/Communication/Shield-thunder.svg',
  ROLES: '/General/Shield-protected.svg',
  CATEGORIES: '/Layout/Layout-top-panel-5.svg',
  SALES: '/Shopping/Sale2.svg',
  PRODUCTS: '/Food/Coffee1.svg',
  ATTRIBUTES: '/Home/Commode2.svg',
  ADS: '/Interface/Image.svg',
  BLOG_CATEGORIES: '/Text/Bullet-list.svg',
  BLOG_POSTS: '/Files/File.svg',
  CUSTOMERS: '/Interface/User.svg',
  PRODUCT_CATEGORIES: '/Text/Bullet-list.svg',
  TENANTS: '/Interface/Bank.svg',
  TENANT_GROUPS: '/Home/Building.svg',
  TENANT_POSTS: '/Files/File.svg',
  BIRTHDAY_GIFTS: '/Shopping/Gift.svg',

  CUSTOMERS_CREATE: '/Communication/Add-user.svg',
  USERS_DELETE:'/Communication/Delete-user.svg',
  ROLES_DELETE:'/General/Shield-disabled.svg',
  POSTS_CREATE:'/Files/File-Plus.svg',
  POSTS_DELETE:'/Files/Deleted-file.svg',

  LIST:'/Layout/Layout-grid.svg',
  PLUS_CIRCLE:'/Code/Plus.svg',
  PLUS_SQUARE:'/Interface/Plus-Square.svg',
  PLUS:'/Navigation/Plus.svg',
  EDIT:'/Design/Edit.svg',
  SAVE:'/Code/Done-circle.svg',
  DELETE_SQUARE:'/Interface/Close-Square.svg',
  DELETE_CIRCLE:'/Code/Error-circle.svg',
  DELETE:'/Navigation/Close.svg',
  MAP_MARKER:'/Map/Marker1.svg',
  MAP_MARKER2:'/Map/Marker2.svg',
  AREA:'/Home/Globe.svg',
  EMAIL: '/Communication/Mail.svg',
  HEARTH: '/General/Heart.svg',
  DOTS:'/Interface/Options-Square.svg',
  DETAIL:'/General/Other2.svg',
  HOME:'/Home/Home.svg',
  GENDER:'/Clothes/Tie.svg',
  MALE:'/Clothes/Tie.svg',
  FEMALE:'/Clothes/Dress.svg',
  CALENDAR:'/Interface/Calendar.svg',
  KEY:'/Design/Select.svg',
  SEARCH: '/General/Search.svg',
  RESET: '/General/Update.svg',
  CHECK: '/Navigation/Check.svg',
  MINUS: '/Navigation/Minus.svg',
  SORT: '/Shopping/Sort1.svg',
  PHONE: '/Devices/iPhone-X.svg',
  LANDLINE: '/Interface/Phone.svg',
  MONEY: '/Shopping/Money.svg',
  PRICE: '/Shopping/Price2.svg',
  DISCOUNT_RATE: '/Shopping/Sale1.svg',


}

type IconProps = {
  path: string,
  variant?: string,
  size?: string,
  className?: string
  onClick?: () => void
  style?: Record<any, any>
}

export const Icon:React.FC<IconProps> = ({ path, variant, size = 'base' , className, ...props}) =>{
  let classes = [className, "svg-icon", "menu-icon"];
  if(variant) classes.push(`svg-icon-${variant}`)
  if(size) classes.push(`svg-icon-${size}`)
  return (
    <span className={classes.join(' ')} {...props}>
      <SVG src={toAbsoluteUrl(`/media/icons/duotone/${path}`)} style={{pointerEvents: 'none' }}/>
    </span>
  )
}