import React, {useMemo} from 'react'
import {useAuthState} from '../../../../api/auth';
import {ICONS} from '../../../../_custom/partials/components/Icon'
import {Avatar} from '../../../../_custom/partials/components/Avatar'
import {AERouteActions} from '../../../../_custom/partials/components/AERouteActions'
import {Moment} from '../../../../_custom/partials/components/Moment'
import {getFullName} from '../../../../_custom/partials/components/FullName'
import {DetailLine} from '../../../../_custom/partials/components/DetailLine'
import clsx from 'clsx'
import {TUser} from '../../../types/TUser'
import {Granted} from '../../../../_custom/partials/components/Granted'

type Props = {
  entity: TUser
  editAction?: boolean
  deleteAction?: boolean
  className?: string
}

export const EntityCard = ({
  entity,
  editAction,
  deleteAction,
  className
}:Props) => {

  const { user } = useAuthState()

  const actions = useMemo(()=>{
    let actions = [];
    if (deleteAction) {
      actions.push({id: 'USERS.DELETE', params: {id: entity.id}})
    }
    if (editAction && user && user.role && entity.role && user.role.priority < entity.role.priority) {
      actions.push({id: 'USERS.EDIT', params: {id: entity.id}})
    }

    return actions
  }, [entity, user, editAction, deleteAction])

  return (
    <div className={clsx(
      'card border border-2 border-gray-300 border-hover',
      className
    )}>
      <div className='card-header flex-nowrap border-0'>
        <div className='card-title m-0'>
          <Avatar user={entity} size='45px' className='w-45px bg-light me-5'/>
          <div className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
            {getFullName(entity)}
          </div>
        </div>

        <div className='card-toolbar m-0'>
          <AERouteActions actions={actions} />
        </div>
      </div>

      <div className='card-body d-flex flex-column pt-0'>
        <div>
          {
            entity.email &&
            <DetailLine
              title='EMAIL'
              icon={ICONS.EMAIL}
            >
              {entity.email}
            </DetailLine>
          }

          {
            entity.role &&
            <DetailLine
              title='ROLE'
              icon={ICONS.ROLES}
            >
              {entity.role.name}
            </DetailLine>
          }

          {
            entity.tenant &&
            <Granted roles={['ROLE_SUPER_ADMIN']}>
              <DetailLine
                title='TENANT'
                icon={ICONS.TENANTS}
              >
                {entity.tenant.name}
              </DetailLine>
            </Granted>
          }

          <DetailLine
            title='CREATION_DATE'
            icon={ICONS.CALENDAR}
          >
            <Moment date={entity.createdAt} format='LL' />
          </DetailLine>
        </div>
      </div>
    </div>
  )
}