import * as Yup from 'yup'
import {Entity, TTenantEntity, TTimeRangeEntity} from './TEntity'
import {TProduct} from './TProduct'

/**
 * Types
 * */
export type TSale = Entity & TTenantEntity & TTimeRangeEntity & {
  name?: string
  discountRate: number
  products: Array<TProduct>
  _active?: boolean
}
export type TNewSale = Omit<TSale, 'id' | '_active'>
export type TEditSale = Omit<TSale, '_active'>
export type TDeleteSale = TEditSale

/**
 * Default Values
 */
export const defaultSale:TSale = {
  id: 0,
  name: '',
  discountRate: 0,
  tenant: null,
  products: [],
  startAt: '',
  endAt: '',
  _active: false
}
export const defaultNewSale:TNewSale = {
  name: '',
  discountRate: 0,
  products: [],
  startAt: '',
  endAt: '',
}
export const defaultEditSale:TEditSale = {
  ...defaultNewSale,
  id: 0
}

/**
 * Validation Schemas
 */
const defaultSchemaShape:Record<keyof TNewSale, any> = {
  name: Yup.string().required(),
  discountRate: Yup.number().min(1),
  tenant: Yup.object().nullable(),
  products: Yup.array().min(1),
  startAt: Yup.string(),
  endAt: Yup.string(),
}
export const saleCreateSchema = Yup.object().shape({
  ...defaultSchemaShape
})
export const saleEditSchema = Yup.object().shape({
  ...defaultSchemaShape,
  id: Yup.number(),
})

