import React from 'react'
import {Field} from 'formik'
import {FormikBootstrapInput} from '../../_custom/partials/controls/FormikBootstrapInput'

export const BirthDateField:React.FC<any> = ({...props}) => (
  <Field
    component={FormikBootstrapInput}
    name='birthDate'
    label='BIRTH_DATE'
    type='date'
    {...props}
  />
)