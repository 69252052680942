import React, {LegacyRef} from 'react'
import {FormikProps} from 'formik/dist/types'
import {FileField, PriorityField, TenantField, TitleField} from '../../../../controls'
import {Granted} from '../../../../../_custom/partials/components/Granted'

type TProps = {
  formik: FormikProps<any>
  btnRef: LegacyRef<HTMLDivElement>
}

export const Form = ({formik, btnRef}:TProps) => {
  const {handleSubmit} = formik

  return (
    <div className='form form-label-right'>
      <div className='card mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='mb-3 col-md'>
              <FileField
                label='COVER'
                preview
                uploadPath='/ads'
                previewStyle={{
                  width: 200
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md'>
              <TitleField />
            </div>
            <div className='mb-3 col-md'>
              <PriorityField />
            </div>
            <Granted roles={['ROLE_SUPER_ADMIN']}>
              <div className={'mb-3 col-md'}>
                <TenantField />
              </div>
            </Granted>
          </div>
        </div>
      </div>

      <div
        className='d-none'
        ref={btnRef}
        onClick={()=>{
          handleSubmit()
        }}
      />
    </div>
  );
}

