import React, {LegacyRef} from 'react'
import {FormikProps} from 'formik/dist/types'
import {FileField, EmailField, FirstNameField, LastNameField} from '../../../../controls'

type TProps = {
  formik: FormikProps<any>
  btnRef: LegacyRef<HTMLDivElement>
}

export const Form = ({formik, btnRef}:TProps) => {
  const {handleSubmit} = formik

  return (
    <div className='form form-label-right'>
      <div className='row'>
        <div className={'mb-3 col-md-6'}>
          <FileField
            label={'AVATAR'}
            preview
            uploadPath={'/users'}
          />
        </div>
      </div>
      <div className='row'>
        <div className={'mb-3 col-md'}>
          <FirstNameField />
        </div>
        <div className={'mb-3 col-md'}>
          <LastNameField />
        </div>
      </div>
      <div className='row'>
        <div className={'mb-3 col-md'}>
          <EmailField />
        </div>
      </div>

      <div
        className={'d-none'}
        ref={btnRef}
        onClick={()=>{
          handleSubmit()
        }}
      />
    </div>
  );
}

