import React from 'react'
import {Search} from './Search'
import {Sort} from './Sort'
import {Filter} from './Filter'
import {PageToolbar} from '../../../../../../_metronic/layout/core'
import {AERouteAction} from '../../../../../../_custom/partials/components/AERouteActions'
import {TProperty} from '../../../../../../_custom/partials/components/Metadata'
import {NUMBER, STRING} from '../../../../../../_custom/partials/components/Filter/helpers'
import {TBirthdayGift} from '../../../../../types/TBirthdayGift'

type TBirthdayGiftProperty = {
  property: keyof TBirthdayGift
} & Omit<TProperty, 'property'>

type TBirthdayGiftProperties = Array<TBirthdayGiftProperty>

export const PROPERTIES: TBirthdayGiftProperties = [
  {
    property: 'id',
    type: NUMBER,
    label: 'ID',
    filterable: false,
    sortable: false,
  },
  {
    property: 'title',
    type: STRING,
    label: 'TITLE',
    filterable: true,
    sortable: true,
  }
]

export const Toolbar = () => {

  return (
    <PageToolbar>
      <div className='ms-4'>
        <Search />
      </div>
      <div className='ms-4'>
        <Sort />
      </div>
      <div className='ms-4'>
        <Filter />
      </div>
      <div className='ms-4'>
        <AERouteAction
          routeKey='BIRTHDAY_GIFTS.NEW'
          className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm btn-icon-primary fw-boldest text-truncate'
        />
      </div>
    </PageToolbar>
  )
}
