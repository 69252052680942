import React, {useState} from "react";
import {Formik} from "formik";
import {Form} from "./Form";
import {CompoundFilterType, TProperties} from '../Metadata'

type TProps = {
  values: CompoundFilterType
  setFilter: (values:any)=>void
  properties: TProperties
}

export const AEFilter = ({values, setFilter, properties}:TProps) => {
  const [initialValues] = useState(values)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      {formik=>(
        <Form
          formik={formik}
          setFilter={setFilter}
          properties={properties}
        />
      )}
    </Formik>
  )
}