import React from "react";
import {useIntl} from "react-intl";
import {BootstrapInput} from '../../controls/FormikBootstrapInput'

type TProps = {
  value: string,
  setSearch: (value:string)=>void
}

export const AESearch = ({value, setSearch}:TProps) => {
  const {formatMessage} = useIntl();

  return (
    <div className={`d-flex align-items-center`}>
      <BootstrapInput
        size={'sm'}
        placeholder={formatMessage({id:'SEARCH'})}
        onChange={e=>{
          setSearch(e.target.value)
        }}
        value={value}
        className='min-w-75px'
      />
    </div>
  )
}